import React, { useContext, useEffect, useState } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import DonationService from "../../../services/donation.service"
import { filteredData, PATHS, formatNumber, requestToLogin } from '../../../bv'
import { SessionContext } from "../../../App"
import Pagination from "../cards/Pagination"
import Loader from "../cards/Loader"
import { AnimatePresence } from "framer-motion"
import { framerLogger } from "../../../stateLogger"
import useModal from "../../../hooks/useModal"
import Modal from "../cards/Modal"
import AddDonationModal from "./addDonationModal"
import "./stripe.css"
import DayInput from '../cards/DayInput'
import { TimeFrame } from '../reporting/Reporting'
import { RESPONSES } from '../../../http-common'


const STATUS = [
  {
    id: 2,
    value: "NOT_APPROVED",
    name: "NON APPROUVE",
    options: ['CANCELED', 'IN_PROGRESS']
  },
  {
    id: 3,
    value: "APPROVED",
    name: "APPROUVE",
    options: ['APPROVED', 'RECEIVED']
  }
]

export default function DonationList({ showNotification }) {
  const { path } = useRouteMatch()
  const { user } = useContext(SessionContext)
  const history = useHistory()
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })

  const [filterText, setFilterText] = useState(params.search ?? "")
  const [showModal, setShowModal] = useState(path === PATHS.addDonation)

  const [showArchived, setShowArchived] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [confirmationType, setConfirmationType] = useState(null)
  const [donations, setDonations] = useState(JSON.parse(localStorage.getItem("donations")) || [])
  const [selectedDonation, setSelectedDonation] = useState(null)
  const [activeDonation, setActiveDonation] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [activePage, setActivePage] = useState(1)
  const [perPage, setPerPage] = useState(5)
  const [sortBy, setSortBy] = useState("-createdAt")
  
  const [selectedStatus, setSelectedStatus] = useState(STATUS.find(item => item.value === params.status))
  
  const { modalOpen, close, open } = useModal()
  const [modalType /* setModalType */] = useState("dropIn")
  
  const [startDate, setStartDate] = useState(new Date(2020, 0, 1))
  const [endDate, setEndDate] = useState(new Date())

  let filteredDonations
  if (donations)
    filteredDonations = filteredData(filterText, donations, [
      "transactionMethod",
      "donorName",
      "amount",
      "status",
      "accountNumber",
      "date",
    ])
  
  if (selectedStatus) {
    filteredDonations = filteredDonations.filter(item => selectedStatus.options.includes(item.status))
  }
  
  filteredDonations = filteredDonations.filter(item => startDate <= new Date(item.createdAt) && new Date(item.createdAt) <= endDate)

  const askForConfirmation = (donation, type) => {
    setActiveDonation(donation)
    setConfirmationType(type)
    setShowConfirmationModal(true)
  }
  
  const deleteDonation = (donation) => {
    setIsSubmitting(true)
    DonationService.deleteOne(donation._id)
      .then((response) => {
        const index = donations.findIndex(item => item._id === donation._id)
         if (index > 0)
           setDonations((prevState) => [...prevState.splice(index, 1)])
        
        showNotification("success", response.data?.message ?? "Donation supprimée avec succès")
      })
      .catch((e) => {
        showNotification("error", e.response?.data?.message ?? RESPONSES[500].message)
      })
      .finally(() => setIsSubmitting(false))
  }

  const confirmUpdate = (donation) => {
    const status =
      confirmationType === "reject"
        ? "CANCELED"
        : confirmationType === "accept"
        ? "APPROVED"
        : null
    setIsSubmitting(true)
    DonationService.updateOne(donation._id, { ...donation, status })
      .then((response) => {
        setShowConfirmationModal(false)
        showNotification("success", response.data?.message ?? "Mise a jour effectuée")
      })
      .catch((e) => {
        showNotification("error", e.response?.data?.message ?? RESPONSES[500].message)
      })
      .finally(() => setIsSubmitting(false))
  }
  
  const showDonationDetails = (donation) => {
    setSelectedDonation(donation)
    open()
  }

  useEffect(() => {
    setPerPage(5)
    setIsSubmitting(true)
    DonationService.getAll(sortBy)
      .then((response) => {
        setDonations(prevState => {
          return response.data.map(item => {
            const temp = {...item, donorName: `${item.donor?.firstName} ${item.donor?.lastName}`}
            // console.log(JSON.stringify(temp) === JSON.stringify(prevState))
            return temp
          })
        })
        // localStorage.setItem("donations", JSON.stringify(response.data))
        setIsSubmitting(false)
      })
      .catch((e) => {
        console.log(e)
        if (e.response.status === 401) requestToLogin(history.location.pathname)
      })
  }, [showModal, showConfirmationModal, sortBy])
  
   useEffect(() => {
     console.log("filterText", filterText)
    history.replace({
      pathname: history.location.pathname,
      search: `?search=${filterText}`
    })
  }, [filterText])

  return (
    <div className="page">
      {isSubmitting && <Loader full={true} />}
      <div className="page-content">
        <div className="patient-list-container">
          { showModal ? <AddDonationModal setShowModal={setShowModal} showNotification={showNotification} /> : "" }

          <div className="page-heading">
            <h2 className="page-heading__title">
              { user?.role === "donor" ? "Mes donations" : "Liste des donations" }
            </h2>
          </div>

          <div className="panel">
            <div className="patient-filters">
              <div className="patient-filters__row">
                <div
                  className="patient-filters__mobile patient-filters__toggle"
                >
                  <button onClick={() => setShowFilter(!showFilter)} type="button" className="btn btn--with-icon-only">
                    <span className="btn__icon btn__icon--filters btn__icon--only" />
                  </button>
                  
                  <button
                    type="button"
                    className="btn btn--blue btn--with-icon-only"
                    style={{margin: 0}}
                    onClick={ () => history.push(user.role === "donor" ? PATHS.makeDonation : PATHS.addDonation) }
                  >
                    <span className="btn__icon btn__icon--plus-white btn__icon--only" />
                  </button>
                </div>
                
                <div className="patient-filters__desktop">
                  <div className="form-group form-group--auto">
                    <input
                      onChange={(event) => setFilterText(event.target.value)}
                      type="text" value={filterText}
                      className="input input--search form-control"
                      placeholder="Tapez un montant, un status ou une methode de transaction d'une donation"
                    />
                  </div>
                  
                  <button className="btn" onClick={() => setShowFilter(!showFilter)}>
                    {showFilter ? "Masquer " : "Afficher"} le filtre
                  </button>
                  
                  <button
                    type="button"
                    className="btn btn--blue"
                    onClick={ () => history.push(user.role === "donor" ? PATHS.makeDonation : PATHS.addDonation) }
                  >
                    {user.role === "donor" ? "Faire un don" : "Ajouter une donation"}
                  </button>
                </div>
              </div>

              {showFilter ? (
                <>
                  <div className="patient-filters__row patient-filters__inputs">
                    <div className="form-group form-group--auto patient-filters__mobile ">
                      <input
                        onChange={(event) => setFilterText(event.target.value)}
                        type="text"
                        value={filterText}
                        className="input input--search form-control"
                        placeholder="Tapez nom, prénoms, téléphone du donateur"
                      />
                    </div>
                    
                    <div className="form-group form-group--2 form-group--equal">
                      {STATUS.map((item, i) => (
                        <button
                          key={i}
                          type="button"
                          onClick={ () => selectedStatus && selectedStatus.id === item.id ? setSelectedStatus(undefined) : setSelectedStatus(item) }
                          className={`btn btn--double btn--border ${
                            selectedStatus && selectedStatus.id === item.id
                              ? "btn--lightblue"
                              : ""
                          }`}
                        >
                          { item.name }
                        </button>
                      ))}
                    </div>
                    
                  </div>
                  <div className="patient-filters__row patient-filters__is-archived">
                    <span
                      className="checkbox"
                      onClick={() => setShowArchived(!showArchived)}
                    >
                      <input
                        className="checkbox__input"
                        type="checkbox"
                        checked={showArchived}
                      />
                      <label className="checkbox__label ">
                        Afficher archivés
                      </label>
                    </span>
                  </div>
                </>
              ) : null}
            </div>

            <div className="table-wrapper" style={{ minHeight: "510px" }}>
              <div className="d-flex time__range__filter">
                <TimeFrame>
                  <button
                    style={{alignSelf: 'center', marginRight: '1rem'}}
                    type="button" className="btn btn--with-icon-only"
                    onClick={() => {
                      setStartDate(new Date(2020, 0, 1))
                      setEndDate(new Date())
                    }}
                  >
                    <span className="btn__icon btn__icon--close-red btn__icon--only" />
                    <span className="tooltip btn__tooltip">Effacer le filtre</span>
                  </button>
                  
                  <div className="from">
                    <span>De: &nbsp;</span>
                    <DayInput
                      selectedDay={startDate}
                      setSelectedDay={ (date) => setStartDate(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)) }
                    />
                  </div>

                  <div className="to">
                    <span style={{textTransform: 'capitalize'}}>à: &nbsp;</span>
                    <DayInput selectedDay={endDate} setSelectedDay={ (date) => setEndDate(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)) } />
                  </div>
                </TimeFrame>
  
                <span className="pagination__indicator">
                  { ((activePage - 1) * perPage) + 1 } -
                  { activePage*perPage > filteredDonations.length ? filteredDonations.length : activePage*perPage } sur {' '}
                  { filteredDonations.length }
                </span>
              </div>
              
              <table className="table table--basic">
                <thead className="table__head">
                  <tr className="table__row  table__row--head ">
                    <th
                      onClick={() =>
                        setSortBy((prevState) =>
                          prevState === "transactionMethod"
                            ? "-transactionMethod"
                            : "transactionMethod"
                        )
                      }
                      className={`table__cell table__cell--head table__cell--basic ${
                        sortBy === "transactionMethod"
                          ? "table__cell--arrow"
                          : sortBy === "-transactionMethod"
                          ? "table__cell--arrow table__cell--arrow-up"
                          : ""
                      }`}
                    >
                      <span className="table__cell-inner">
                        Methode de Transaction
                      </span>
                      <span className="table__cell-arrow"/>
                    </th>
  
                    <th
                      onClick={() =>
                        setSortBy((prevState) =>
                          prevState === "transactionMethod"
                            ? "-transactionMethod"
                            : "transactionMethod"
                        )
                      }
                      className={`table__cell table__cell--head table__cell--basic ${
                        sortBy === "transactionMethod"
                          ? "table__cell--arrow"
                          : sortBy === "-transactionMethod"
                            ? "table__cell--arrow table__cell--arrow-up"
                            : ""
                      }`}
                    >
                      <span className="table__cell-inner">
                        Donateur
                      </span>
                      <span className="table__cell-arrow" />
                    </th>
  
                    <th
                      onClick={() =>
                        setSortBy((prevState) =>
                          prevState === "amount"
                            ? "-amount"
                            : "amount"
                        )
                      }
                      className={`table__cell table__cell--head table__cell--basic ${
                        sortBy === "amount"
                          ? "table__cell--arrow"
                          : sortBy === "-amount"
                          ? "table__cell--arrow table__cell--arrow-up"
                          : ""
                      }`}
                    >
                      <span className="table__cell-inner">Montant (XOF)</span>
                      <span className="table__cell-arrow" />
                    </th>
  
                    <th
                      onClick={() =>
                        setSortBy((prevState) =>
                          prevState === "status"
                            ? "-status"
                            : "status"
                        )
                      }
                      className={`table__cell table__cell--head table__cell--basic ${
                        sortBy === "status"
                          ? "table__cell--arrow"
                          : sortBy === "-status"
                          ? "table__cell--arrow table__cell--arrow-up"
                          : ""
                      }`}
                    >
                      <span className="table__cell-inner">Status</span>
                      <span className="table__cell-arrow" />
                    </th>
  
                    <th
                      onClick={() =>
                        setSortBy((prevState) =>
                          prevState === "donations.accountNumber"
                            ? "-accountNumber"
                            : "accountNumber"
                        )
                      }
                      className={`table__cell table__cell--head table__cell--basic ${
                        sortBy === "accountNumber"
                          ? "table__cell--arrow"
                          : sortBy === "-accountNumber"
                          ? "table__cell--arrow table__cell--arrow-up"
                          : ""
                      }`}
                    >
                      <span className="table__cell-inner">
                        Numero de compte
                      </span>
                      <span className="table__cell-arrow" />
                    </th>
  
                    <th
                      onClick={() =>
                        setSortBy((prevState) =>
                          prevState === "date"
                            ? "-date"
                            : "date"
                        )
                      }
                      className={`table__cell table__cell--head table__cell--basic ${
                        sortBy === "date"
                          ? "table__cell--arrow"
                          : sortBy === "-date"
                          ? "table__cell--arrow table__cell--arrow-up"
                          : ""
                      }`}
                    >
                      <span className="table__cell-inner">
                        Date de la transaction
                      </span>
                      <span className="table__cell-arrow" />
                    </th>
  
                    <th
                      onClick={() =>
                        setSortBy((prevState) =>
                          prevState === "createdAt"
                            ? "-createdAt"
                            : "createdAt"
                        )
                      }
                      className={`table__cell table__cell--head table__cell--basic ${
                        sortBy === "createdAt"
                          ? "table__cell--arrow"
                          : sortBy === "-createdAt"
                          ? "table__cell--arrow table__cell--arrow-up"
                          : ""
                      }`}
                    >
                      <span className="table__cell-inner">
                        Date d&apos;enrégistrement
                      </span>
                      <span className="table__cell-arrow" />
                    </th>
  
                    <th className="table__cell table__cell--head table__cell--basic ">
                      <span className="table__cell-inner" />
                      <span className="table__cell-arrow" />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {filteredDonations ? (
                    filteredDonations.length ? (
                      filteredDonations
                        .slice((activePage - 1) * perPage, activePage * perPage)
                        .map((donation, i) => (
                          <tr
                            key={i} className="table__row table__row--basic"
                            style={{ backgroundColor: donation.isArchived ? "antiquewhite" : "", }}
                            onClick={ user?.role === 'admin' ? () => showDonationDetails(donation) : undefined }
                          >
                            <td className="table__cell table__cell--clickable table__cell--mobile table__cell--bold">
                              <span className="table__cell-inner">
                                <span className={`${
                                    ["visa", "paypal", "masterCard", "VISAMTG", "VISAM"].includes(donation.transactionMethod)
                                      ? "visaImage"
                                      : ["espece", "cagnote"].includes(donation.transactionMethod)
                                      ? "dollar_coins"
                                      : ["flooz", "tmoney", "FLOOZ", "FLOOZTG", "TMONEYTG"].includes(donation.transactionMethod)
                                      ? "phone_money"
                                      : ["western", "ria", "wari", "moneyGram",].includes(donation.transactionMethod)
                                      ? "transfert_international" : ""
                                  } employees__userimage`}/>
                                <span style={{ textTransform: "capitalize" }}>
                                  {donation.transactionMethod}
                                </span>
                              </span>
                            </td>

                            <td className="table__cell table__cell--clickable table__cell--mobile table__cell--bold">
                              <span className="table__cell-inner">
                                <span style={{
                                  textTransform: 'capitalize',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '163px',
                                  overflow: 'hidden'
                                }} title={donation.donor ? donation.donor.firstName + ' ' + donation.donor.lastName : ""}>
                                  { donation.donor ? donation.donor.firstName + ' ' + donation.donor.lastName : "" }
                                </span>
                              </span>
                            </td>
                            
                            <td className="table__cell table__cell--clickable table__cell--mobile table__cell--bold">
                              <span className="table__cell-inner">
                                <span style={{ textTransform: "capitalize" }}>
                                  {formatNumber(donation.amount)}
                                </span>
                              </span>
                            </td>

                            <td className="table__cell table__cell--mobile">
                              <span className="table__cell-inner">
                                <button
                                  style={{ cursor: "auto" }}
                                  type="button"
                                  className={`btn btn--${
                                    donation.status === "RECEIVED"
                                      ? "valid"
                                      : donation.status === "CANCELED"
                                      ? "inactif"
                                      : donation.status === "IN_PROGRESS"
                                      ? "inprogress"
                                      : donation.status === "APPROVED"
                                      ? "valid"
                                      : ""
                                  } btn--with-icon patient-list-container__table-btn`}
                                >
                                  <span
                                    className={`btn__icon btn__icon--${
                                      donation.status === "RECEIVED"
                                        ? "valid"
                                        : donation.status === "CANCELED"
                                        ? "inactif"
                                        : donation.status === "IN_PROGRESS"
                                        ? "inprogress"
                                        : donation.status === "APPROVED"
                                        ? "valid"
                                        : ""
                                    }`}
                                  />
                                  {donation.status === "RECEIVED"
                                    ? "Reçue"
                                    : donation.status === "CANCELED"
                                    ? "Rejetée"
                                    : donation.status === "IN_PROGRESS"
                                    ? "En attente"
                                    : donation.status === "APPROVED"
                                    ? "Validée"
                                    : ""}
                                </button>
                              </span>
                            </td>

                            <td className="table__cell table__cell--one-line table__cell--mobile ">
                              <span className="table__cell-inner">
                                {donation.accountNumber
                                  ? donation.accountNumber
                                  : "Néant"}
                              </span>
                            </td>

                            <td className="table__cell table__cell--mobile ">
                              <span className="table__cell-inner">
                                <span className="table__cellspan table__cellspan--bold">
                                  { (new Date(donation.date)).toLocaleDateString('fr-FR', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) }
                                </span>
                              </span>
                            </td>

                            <td className="table__cell table__cell--mobile ">
                              <span className="table__cell-inner">
                                <span className="table__cellspan table__cellspan--bold">
                                  { (new Date(donation.createdAt)).toLocaleString('fr-FR', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }) }
                                </span>
                                <span
                                  className="table__cellspan table__cellspan--light"
                                  style={{
                                    textTransform: 'capitalize',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '150px',
                                    overflow: 'hidden'
                                  }}
                                  title={donation.createdBy === user._id
                                    ? "Moi"
                                    : donation.createdBy
                                      ? donation.createdBy.firstName + ' ' + donation.createdBy.lastName
                                      : ''}
                                >
                                  Créé par :{" "}
                                  {donation.createdBy === user._id
                                    ? "Moi"
                                    : donation.createdBy
                                      ? donation.createdBy.firstName + ' ' + donation.createdBy.lastName
                                      : ''}
                                </span>
                              </span>
                            </td>

                            <td className="table__cell table__cell--mobile table__cell--align-right ">
                              <span className="table__cell-inner" style={{flexWrap: 'nowrap'}}>
                                {
                                  user.role === "admin" && ["IN_PROGRESS", "CANCELED"].includes(donation.status) && (
                                    <>
                                      { donation.status === "IN_PROGRESS" && (
                                        <>
                                          <button
                                            type="button"
                                            className="btn btn--with-icon-only"
                                            onClick={(event) => {
                                              event.stopPropagation()
                                              askForConfirmation(donation, 'reject')
                                            }}
                                          >
                                            <span className="btn__icon btn__icon--close-red btn__icon--only" />
                                            <span className="tooltip btn__tooltip">
                                            Rejeter
                                          </span>
                                          </button>
  
                                          <button
                                            type="button"
                                            className="btn btn--with-icon-only"
                                            style={{ marginLeft: "1rem" }}
                                            onClick={(event) => {
                                              event.stopPropagation()
                                              askForConfirmation(donation, 'accept')
                                            }}
                                          >
                                            <span className="btn__icon btn__icon--checkmark-blue btn__icon--only" />
                                            <span className="tooltip btn__tooltip">
                                        Accepter
                                      </span>
                                          </button>
                                        </>
                                      ) }
  
                                      { donation.status === "CANCELED" && (
                                        <button
                                          type="button"
                                          className="btn btn--with-icon-only"
                                          onClick={(event) => {
                                            event.stopPropagation()
                                            deleteDonation(donation)
                                          }}
                                        >
                                          <span className="btn__icon btn__icon--delete btn__icon--only" />
                                          <span className="tooltip btn__tooltip">
                                            Supprimer
                                          </span>
                                        </button>
                                      ) }
                                    </>
                                  )
                                }
                              </span>
                            </td>

                            <div className="patient-list-container__cell-header--mobile">
                              <div className="patient-list-container__cell-wrapper">
                                <td className="table__cell table__cell--mobile ">
                                  <span className="table__cell-inner" style={{textTransform: 'uppercase'}}>{donation.transactionMethod}</span>
                                  {/* <span className="table__cell-inner">{ donation.donor ? donation.donor.firstName + ' ' + donation.donor.lastName : "" }</span> */}
                                </td>
                                <td className="table__cell table__cell--mobile table__cell--without-tablet ">
                                  <span className="table__cell-inner">
                                    {/* { (new Date(donation.createdAt)).toLocaleString('fr-FR', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }) } &nbsp;:&nbsp; */}
                                    { (new Date(donation.date)).toLocaleDateString('fr-FR', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) }
                                  </span>
                                </td>
                              </div>
                              {formatNumber(donation.amount)}
                            </div>
                          </tr>
                        ))
                    ) : (
                      <tr className="table__row  table__row--basic">
                        <td
                          className="table__cell table__cell--basic "
                          colSpan="7"
                        >
                          <span className="table__cell-inner">
                            <div className="empty-state-with-action">
                              <span className="empty-state-with-action__img" />
                              <p className="empty-state-with-action__text">
                                Aucune donation enrégistrée.
                              </p>
                              <button type="button" className="btn btn--blue" onClick={
                                user.role === "donor"
                                  ? () => history.push(PATHS.makeDonation)
                                  : () => history.push(PATHS.addDonation)
                              }>
                                {user.role === "donor"
                                  ? "Faire un don"
                                  : "Ajouter une donation"}
                              </button>
                            </div>
                          </span>
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr className="table__row  table__row--basic">
                      <div>
                        <Loader full />
                      </div>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {
            showConfirmationModal && activeDonation ? (
              <div className="fullpage">
                <div className="modal modal--">
                  <div className="panel modal__content">
                    <div className="modal__header">
                      <h2 className="modal__title">
                        Confirmation de &nbsp;
                        <span style={{ textTransform: "uppercase" }}>
                          {activeDonation.transactionMethod}{" "}{activeDonation.amount} XOF
                        </span>
                      </h2>
                      <button
                        onClick={() => setShowConfirmationModal(false)}
                        type="button"
                        className="btn  btn--with-icon-only  btn--with-icon-close"
                      >
                        <span className="btn__icon btn__icon--close btn__icon--only" />
                      </button>
                    </div>
          
                    <div className="modal__content--inner">
                      <div className="modal__main">
                        <h3>
                          {confirmationType === "reject"
                            ? "Confirmez vous le rejet de cette donation ?"
                            : confirmationType === "accept"
                              ? "Voulez vous vraiment valider cette donation"
                              : ""}
                        </h3>
                        <p>
                          Notez que toute fois vous pourrez revenir sur votre
                          décision.
                        </p>
                      </div>
                    </div>
          
                    <div className="modal__footer">
                      <button
                        type="button"
                        className={`btn btn--${
                          confirmationType === "reject"
                            ? "red"
                            : confirmationType === "accept"
                            ? "blue"
                            : ""
                        }`}
                        onClick={() => confirmUpdate(activeDonation)}
                      >
                        {confirmationType === "reject"
                          ? "Rejeter"
                          : confirmationType === "accept"
                            ? "Oui, j'accepte"
                            : ""}
                      </button>
                      <button
                        type="button"
                        className="btn btn--border"
                        onClick={() => setShowConfirmationModal(false)}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : ""
          }

          {filteredDonations && filteredDonations.length && (
            <div className="buttons-container buttons-container--end">
              <Pagination
                length={filteredDonations.length}
                perPage={perPage}
                activePage={activePage}
                setActivePage={setActivePage}
              />
            </div>
          )}
        </div>

        <ModalContainer>
          {modalOpen && selectedDonation && (
            <Modal
              // modalOpen={modalOpen}
              text={modalType}
              type={modalType}
              handleClose={close}
              selectedDonation={selectedDonation}
              showNotification={showNotification}
              askForConfirmation={askForConfirmation}
            />
          )}
        </ModalContainer>
      </div>
    </div>
  )
}

export const ModalContainer = ({ children, label }) => (
  <AnimatePresence
    initial={false}
    exitBeforeEnter={true}
    onExitComplete={() => framerLogger(label)}
  >
    { children }
  </AnimatePresence>
)
