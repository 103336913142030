import React, { useState } from "react"

export default function Pagination({
  perPage,
  length,
  activePage,
  setActivePage,
}) {
  const [paginationInputText, setPaginationInputText] = useState("1")

  const pageCount = Math.ceil(length / perPage)

  const paginationInputChangeHandler = (value) => {
    value = parseInt(value)
    if (value >= 1 && value <= pageCount) setActivePage(value)
    else setPaginationInputText(activePage)
  }

  const nextPage = () => {
    if (activePage + 1 <= pageCount) {
      setPaginationInputText(activePage + 1)
      setActivePage((prevState) => prevState + 1)
    }
  }

  const previousPage = () => {
    if (activePage - 1 >= 1) {
      setPaginationInputText(activePage - 1)
      setActivePage((prevState) => prevState - 1)
    }
  }

  return (
    <div className="pagination">
      <button
        onClick={previousPage}
        type="button"
        className="btn  btn--with-icon-only"
      >
        <span className="btn__icon btn__icon--prev btn__icon--only" />
      </button>
      <div className="pagination__pages">
        <span className="pagination__pages-item">page</span>
        <span className="pagination__pages-item">
          <input
            onChange={(event) => setPaginationInputText(event.target.value)}
            onKeyPress={(event) =>
              !event.altKey &&
              !event.ctrlKey &&
              event.key === "Enter" &&
              event.code === "Enter" &&
              paginationInputChangeHandler(event.target.value)
            }
            className="pagination__current-page"
            type="number"
            value={paginationInputText}
          />
        </span>
        <span className="pagination__pages-item">sur</span>
        <span className="pagination__pages-item">{pageCount}</span>
      </div>
      <button
        onClick={nextPage}
        type="button"
        className="btn  btn--with-icon-only"
      >
        <span className="btn__icon btn__icon--next btn__icon--only" />
      </button>
    </div>
  )
}
