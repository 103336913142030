import http from "../http-common"

class AuthService {
  signin(data) {
    return http.post("/signin", data)
  }

  signup(data) {
    return http.post(`/signup`, data)
  }
  
  recoverPassword(data) {
    return http.post(`/recover-password`, data)
  }
  
  verifyRecoverPasswordKey(data) {
    return http.post(`/verify-recover-password-key`, data)
  }
}

export default new AuthService()
