import React, { useContext, useEffect, useState } from "react"
import { Link, useRouteMatch } from "react-router-dom"
import AuthService from "../../services/auth.service"
import UserService from "../../services/user.service"
import PromiseService from "../../services/promise.service"
import { AMOUNTS, PATHS, SECRET_CODE_LENGTH } from "../../bv"
import Welcome from "../components/cards/Welcome"
import { SessionContext } from "../../App"
import Loader from "../components/cards/Loader"
import PasswordInput from "../components/cards/PasswordInput"

export default function SignUp({ showNotification }) {
  const { path, params } = useRouteMatch()
  const [isInvitation /* setIsInvitation */] = useState(
    path === PATHS.acceptInvite
  )
  const [invitedUser, setInvitedUser] = useState(null)

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [telephone, setTelephone] = useState("")
  const [callingCode, setCallingCode] = useState("+228")
  const [password, setPassword] = useState("")
  const [rPassword, setRPassword] = useState("")
  const [selectedStep, setSelectedStep] = useState(1)
  const [secretCode, setSecretCode] = useState(["", "", "", "", "", ""])

  const [isSubmitting, setIsSubmitting] = useState(path === PATHS.acceptInvite)
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(true)

  const [showOtherAmount, setShowOtherAmount] = useState(false)
  const [showCountriesDropdown, setShowCountriesDropdown] = useState(false)
  const [otherAmountValue, setOtherAmountValue] = useState("")
  const [selectedAmount, setSelectedAmount] = useState({})

  const [showModal, setShowModal] = useState(isInvitation)
  const [validationCode, setValidationCode] = useState("")

  const { countries, setCurrentPath } = useContext(SessionContext)

  setCurrentPath(path)

  const submitHandler = () => {
    if (selectedStep === 1) {
      if (
        firstName &&
        lastName &&
        callingCode &&
        telephone &&
        password &&
        rPassword
      ) {
        if (password === rPassword && password.length >= 6) {
          if (!isInvitation) {
            const data = {
              firstName,
              lastName,
              email,
              telephone: { callingCode, value: telephone },
              password
            }
            setIsSubmitting(true)
            AuthService.signup(data)
              .then(response => {
                if (response.status === 201) {
                  setIsSubmitting(false)
                  localStorage.setItem("token", JSON.stringify(response.data))

                  UserService.getMe()
                    .then(response => {
                      localStorage.setItem(
                        "user",
                        JSON.stringify(response.data)
                      )
                    })
                    .catch(e => {
                      setIsSubmitting(false)
                      const message = e.response.statusCode === 500
                        ? "Une erreur s'est produite du côté du serveur"
                        : e.response?.data?.message ?? "Erreur de réseau"
                      showNotification("error", message)
                    })

                  setSelectedStep(2)
                  setIsVerificationCodeSent(true)
                  setTimeout(() => setIsVerificationCodeSent(false), 1000 * 90)
                }
              })
              .catch(e => {
                setIsSubmitting(false)
                showNotification(
                  "error",
                  e.response?.data?.message ?? "Error"
                )
              })
          }
          else if (invitedUser) {
            const data = { firstName, lastName, password, email }
            setIsSubmitting(true)
            UserService.updateMyProfil(data)
              .then(response => {
                if (response.status === 200) {
                  setIsSubmitting(false)
                  setSelectedStep(3)
                }
              })
              .catch(e => {
                setIsSubmitting(false)
                showNotification(
                  "error",
                  e?.response?.data?.message ?? "Error"
                )
              })
          }
        }
        else {
          showNotification("error", "Mots de passe trop courts ou non conformes!")
        }
      }
      else {
        showNotification("error", "Veuillez renseignez tous les champs obligatoires!")
      }
    }
    else if (selectedStep === 3) {
      const amount = selectedAmount.value || otherAmountValue
      
      if (amount) {
        let user = localStorage.getItem("user")
        if (user) user = JSON.parse(user).data

        setIsSubmitting(true)
        if (!user.promise) {
          PromiseService.createOne({
            donor: user._id,
            amount: amount,
            date: Date.now()
          })
            .then(() => {
              setIsSubmitting(false)
              setSelectedStep(4)
            })
            .catch(e => {
              setIsSubmitting(false)
              showNotification("error", e.message ?? "Error")
            })
        }
        else {
          PromiseService.updateOne(user.promise._id, { amount: amount })
            .then(() => {
              setIsSubmitting(false)
              setSelectedStep(4)
            })
            .catch(e => {
              setIsSubmitting(false)
              showNotification("error", e.message ?? "Error")
            })
        }
      }
      else {
        showNotification('error', 'Veuillez choisir un promesse')
      }
    }
    else if (selectedStep === 4) {
      location.replace(PATHS.home)
    }
  }

  const acceptInvite = () => {
    if (isInvitation && params.uuid) {
      UserService.acceptInvite(params.uuid, { secret: validationCode })
        .then(response => {
          if (response.status === 200) {
            setIsSubmitting(false)
            console.log(response.data)
            localStorage.setItem(
              "user",
              JSON.stringify({ data: response.data.user })
            )
            localStorage.setItem("token", JSON.stringify(response.data.token))

            setInvitedUser(response.data.user)
            setFirstName(response.data.user.firstName)
            setLastName(response.data.user.lastName)
            setEmail(response.data.user.email)
            setTelephone(response.data.user.telephone.value)
            setCallingCode(response.data.user.telephone.callingCode)
          }
          setShowModal(false)
        })
        .catch(e => {
          const message = e.response.statusCode === 500
            ? "Une erreur s'est produite du côté du serveur"
            : e.response?.data?.message ?? "Erreur de réseau"
          showNotification("error", message)
        })
    }
  }

  const handleSMSCodeCaseChange = (e, index) => {
    const arr = [...secretCode]
    const text = e.target.value
    console.log(text)
    const newValue = text[text.length - 1]
    arr[index] = newValue
    setSecretCode(arr)
    if (!newValue) {
      if (index > 0) e.target.previousElementSibling.focus()
    } else {
      if (index < 5) e.target.nextElementSibling.focus()
    }

    const code = arr.join("")
    if (code.length === SECRET_CODE_LENGTH) {
      UserService.verifyNumber({ verificationCode: code })
        .then(() => {
          showNotification("success", "Bravo!")
          setTimeout(() => {
            setSelectedStep(3)
          }, 1000)
        })
        .catch(e => {
          console.log(e)
          showNotification("error", "Code secret erroné")
        })
    }
  }

  const toggleOtherAmount = () => {
    setShowOtherAmount(!showOtherAmount)
    setSelectedAmount({})
  }

  const resendCode = () => {
    setIsSubmitting(true)
    UserService.resendVerificationCode({})
      .then(() => {
        setIsSubmitting(false)
        showNotification("system", "Code de vérification renvoyé avec succès")
        setIsVerificationCodeSent(true)
        setTimeout(() => setIsVerificationCodeSent(false), 1000 * 90)
      })
      .catch(e => {
        setIsSubmitting(false)
        showNotification(
          "error",
          e.message
            ? e.message
            : "Erreur lors de l'envoi du code. Veuillez ressayer plus tard"
        )
        console.log(e)
      })
  }

  const toggleCountriesDropdown = () => {
    setShowCountriesDropdown(!showCountriesDropdown)
  }

  useEffect(() => {}, [])

  return (
    <div className="app">
      { showModal ? (
        <div className="fullpage">
          <div className="modal modal--">
            <div className="panel modal__content">
              <div className="modal__header ">
                <h2 className="modal__title">Vérification</h2>
              </div>

              <div className="modal__content--inner">
                <div className="modal__main">
                  <form className="form patient-form">
                    <div className="form-group form-group--1">
                      <div className="select-form-label">
                        <label className="form-group__label">Code secret</label>
                      </div>
                      <input
                        type="text"
                        className="input input-- form-control"
                        value={validationCode}
                        onChange={event =>
                          setValidationCode(event.target.value)
                        }
                      />
                    </div>
                  </form>
                </div>
                <div className="modal__footer">
                  <button
                    type="button"
                    className="btn btn--blue"
                    onClick={acceptInvite}
                  >
                    Accepter l&apos;invitation
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : ""}
      
      <div className="auth">
        <Welcome />

        <div className="auth__content">
          {isSubmitting ? <Loader full /> : ""}

          <div className="auth-form">
            <div className="auth-form__content">
              <div className="auth-form__mobile-logo" />
              <div className="steps">
                <div className="steps__container">
                  <div
                    className={`steps__step ${
                      selectedStep === 1 ? "steps__step--selected" : ""
                    }`}
                  />
                  <div
                    className={`steps__step ${
                      selectedStep === 2 ? "steps__step--selected" : ""
                    }`}
                  />
                  <div
                    className={`steps__step ${
                      selectedStep === 3 ? "steps__step--selected" : ""
                    }`}
                  />
                  <div
                    className={`steps__step ${
                      selectedStep === 4 ? "steps__step--selected" : ""
                    }`}
                  />
                </div>

                <p className="steps__indicator">
                  <span className="text-capitalize">étape</span>
                  <span className="steps__indicator--dark">
                    {" "}
                    {selectedStep}
                  </span>{" "}
                  sur 4
                </p>

                <div className="steps__content">
                  {selectedStep === 1 ? (
                    <div className="step-layout">
                      <div className="step-layout__title">
                        <h3 className="step-layout__header">Inscription</h3>
                        <p className="step-layout__desc text-center">
                          Veuillez rentrer le informations correspondants à votre identité.
                        </p>
                      </div>

                      <div className="step-layout__content">
                        <div>
                          <form className="register-form">
                            <div className="form-group form-group--1 form-group--side ">
                              <div className="select-form-label">
                                <label className="form-group__label">Nom</label>
                              </div>
                              <input
                                type="text"
                                className="input input--side form-control"
                                placeholder="Entrez votre nom s'il vous plait"
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                              />
                            </div>

                            <div className="form-group form-group--1 form-group--side ">
                              <div className="select-form-label">
                                <label className="form-group__label">
                                  Prénoms
                                </label>
                              </div>
                              <input
                                type="text"
                                className="input input-- form-control "
                                placeholder="Veuillez entrer vos prénoms"
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                              />
                            </div>

                            <div className='form-group form-group--1 form-group--side '>
                              <div className='select-form-label'>
                                <label className='form-group__label'>Adresse e-mail</label>
                              </div>
                              <input
                                type='email' className='input input-- form-control '
                                placeholder='Entrez votre Email'
                                value={ email }
                                onChange={ (e) => setEmail(e.target.value) }
                              />
                            </div>

                            {!isInvitation ? (
                              <div className="form-group form-group--1 form-group--side">
                                <div className="select-form-label">
                                  <label className="form-group__label">
                                    Téléphone
                                  </label>
                                </div>
                                <div className="form-group form-group--1 register-form__dialing">
                                  <div
                                    className="select-container select-container--simple "
                                    tabIndex="-1"
                                  >
                                    <input
                                      type="text"
                                      readOnly={true}
                                      onClick={toggleCountriesDropdown}
                                      autoComplete="off__1629591878510"
                                      className="input input--dropdown user-select-none"
                                      placeholder=""
                                      value={callingCode}
                                    />
                                    <div
                                      style={{
                                        display: showCountriesDropdown
                                          ? "block"
                                          : "none"
                                      }}
                                      className="dropdown dropdown--simple "
                                    >
                                      <ul className="dropdown-list select-list ">
                                        {countries.map((country, i) => (
                                          <li
                                            onClick={() => {
                                              setCallingCode(
                                                "+" + country.callingCodes[0]
                                              )
                                              toggleCountriesDropdown()
                                            }}
                                            key={i}
                                            style={{ width: "100px" }}
                                            className="dropdown-list__item "
                                          >
                                            {country.alpha2Code} +{" "}
                                            {country.callingCodes[0]}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group form-group--2 register-form__phone ">
                                  <input
                                    type="number"
                                    className="input input-- form-control"
                                    placeholder="Entrez le numéro de téléphone"
                                    autoComplete="off"
                                    value={telephone}
                                    onChange={e =>
                                      setTelephone(e.target.value.trim())
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="form-group form-group--1 form-group--password form-group--side ">
                              <div className="select-form-label">
                                <label className="form-group__label">
                                  Mot de passe
                                </label>
                              </div>
                              <PasswordInput
                                value={password}
                                setValue={setPassword}
                                submitHandler={submitHandler}
                              />
                            </div>

                            <div className="form-group form-group--1 form-group--password form-group--side ">
                              <div className="select-form-label">
                                <label className="form-group__label">
                                  Répéter le mot de passe
                                </label>
                              </div>
                              <PasswordInput
                                value={rPassword}
                                setValue={setRPassword}
                                submitHandler={submitHandler}
                              />
                            </div>
                          </form>

                          {/* <span className='checkbox checkbox--undefined  undefined'>
                          <input className='checkbox__input' type='checkbox' />
                          <label className='checkbox__label '>
                            <div>
                              Akceptuję
                              <a
                                href='https://app.finezjo.pl/api/files/static/ogolne_zasady.pdf'
                                target='_blank' rel='noreferrer'>Ogólne Zasady Użytkowania</a> oraz
                              <a
                                href='https://app.finezjo.pl/api/files/static/polityka_prywatnosci.pdf'
                                target='_blank' rel='noreferrer'>Politykę Prywatności
                            </a>
                            </div>
                            </label>
                        </span> */}

                          <p className="step-validation" />
                        </div>
                      </div>
                    </div>
                  ) : selectedStep === 2 ? (
                    <div className="step-layout">
                      <div className="step-layout__title">
                        <h3 className="step-layout__header">
                          Entrez le code de vérification
                        </h3>
                        <p className="step-layout__desc">
                          Nous avons envoyé un code de vérification sur le
                          numéro que vous avez fourni.
                        </p>
                      </div>
                      <div className="step-layout__content">
                        <div className="sms-code-wrapper">
                          <form>
                            <input
                              className="input input--rectangle sms-code-wrapper__input"
                              tabIndex="0"
                              type="text"
                              autoComplete="off"
                              value={secretCode[0]}
                              onChange={e => handleSMSCodeCaseChange(e, 0)}
                              onFocus={event =>
                                event.target.setSelectionRange(
                                  0,
                                  event.target.value.length
                                )
                              }
                            />
                            <input
                              className="input input--rectangle sms-code-wrapper__input"
                              tabIndex="1"
                              type="text"
                              autoComplete="off"
                              value={secretCode[1]}
                              onChange={e => handleSMSCodeCaseChange(e, 1)}
                              onFocus={event =>
                                event.target.setSelectionRange(
                                  0,
                                  event.target.value.length
                                )
                              }
                            />
                            <input
                              className="input input--rectangle sms-code-wrapper__input"
                              tabIndex="2"
                              type="text"
                              autoComplete="off"
                              value={secretCode[2]}
                              onChange={e => handleSMSCodeCaseChange(e, 2)}
                              onFocus={event =>
                                event.target.setSelectionRange(
                                  0,
                                  event.target.value.length
                                )
                              }
                            />
                            <input
                              className="input input--rectangle sms-code-wrapper__input"
                              tabIndex="3"
                              type="text"
                              autoComplete="off"
                              value={secretCode[3]}
                              onChange={e => handleSMSCodeCaseChange(e, 3)}
                              onFocus={event =>
                                event.target.setSelectionRange(
                                  0,
                                  event.target.value.length
                                )
                              }
                            />
                            <input
                              className="input input--rectangle sms-code-wrapper__input"
                              tabIndex="4"
                              type="text"
                              autoComplete="off"
                              value={secretCode[4]}
                              onChange={e => handleSMSCodeCaseChange(e, 4)}
                              onFocus={event =>
                                event.target.setSelectionRange(
                                  0,
                                  event.target.value.length
                                )
                              }
                            />
                            <input
                              className="input input--rectangle sms-code-wrapper__input"
                              tabIndex="5"
                              type="text"
                              autoComplete="off"
                              value={secretCode[5]}
                              onChange={e => handleSMSCodeCaseChange(e, 5)}
                              onFocus={event =>
                                event.target.setSelectionRange(
                                  0,
                                  event.target.value.length
                                )
                              }
                            />
                          </form>
                          <p className="sms-code-wrapper__desc">
                            Vous n&apos;avez pas reçu le code ?&nbsp;
                            <span
                              style={
                                isVerificationCodeSent
                                  ? { color: "#a1a1b7", cursor: "no-drop" }
                                  : {}
                              }
                              className="sms-code-wrapper__link"
                              onClick={
                                isVerificationCodeSent ? undefined : resendCode
                              }
                            >
                              envoyer à nouveau
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : selectedStep === 3 ? (
                    <div className="step-layout">
                      <div className="step-layout__title">
                        <h3 className="step-layout__header">
                          Selectionnez le montant de votre promesse
                        </h3>
                        {/* <p className="step-layout__desc">Vous pouvez définir un autre montant</p> */}
                      </div>

                      <div className="step-layout__content">
                        <div className="d-flex">
                          {AMOUNTS.map((amount, i) => (
                            <div
                              key={i}
                              style={{ width: "unset" }}
                              className="account-selector margin-x-10 hover-border-0aceda"
                              onClick={() => {
                                setSelectedAmount(amount)
                                setShowOtherAmount(false)
                              }}
                            >
                              <span className="checkbox checkbox--bold checkbox--472fc6">
                                <input
                                  value={selectedAmount.value === amount.value}
                                  checked={
                                    selectedAmount.value === amount.value
                                  }
                                  className="checkbox__input"
                                  type="checkbox"
                                />
                                <label className="checkbox__label ">
                                  {amount.text} F CFA
                                </label>
                              </span>
                            </div>
                          ))}
                        </div>

                        <div>
                          <div className="form-group inactive">
                            <button
                              type="button"
                              className="btn btn--iconside btn--with-icon"
                              onClick={() => toggleOtherAmount()}
                            >
                              <span
                                className={`btn__icon btn__icon--${
                                  showOtherAmount ? "minus" : "plus"
                                }`}
                              />
                              <font>Définir un autre montant</font>
                            </button>
                          </div>

                          {showOtherAmount ? (
                            <div
                              className="d-flex"
                              style={{ maxWidth: "350px", margin: "auto" }}
                            >
                              <div
                                className="account-selector margin-x-10 border-black"
                                style={{ cursor: "default" }}
                              >
                                <span className="checkbox checkbox--bold checkbox--472fc6">
                                  <input
                                    value={true}
                                    checked={true}
                                    className="checkbox__input"
                                    type="checkbox"
                                  />
                                  <label className="checkbox__label" />
                                  <input
                                    id="custom-amount-input"
                                    type="number"
                                    className="input input--side form-control"
                                    placeholder="ex: 5000"
                                    value={otherAmountValue}
                                    onChange={event =>
                                      setOtherAmountValue(event.target.value)
                                    }
                                  />
                                </span>
                              </div>
                            </div>
                          ) : ''}
                        </div>
                      </div>
                    </div>
                  ) : selectedStep === 4 ? (
                    <div className="step-layout">
                      <div className="step-layout__title">
                        <h3 className="step-layout__header">
                          Félicitation - Vous faites désormains partis des
                          vaillants batisseurs !
                        </h3>
                        <p className="step-layout__desc text-center">
                          <span className="">
                            Vous pouvez utiliser cette plateforme pour faire vos
                            dons !
                          </span>
                        </p>
                      </div>
                      <div className="step-layout__content">
                        <div className="data-confirm">
                          <div className="register-success-icon" />
                          <p className="data-confirm__desc text-center">
                            Vous pouvez egalement suivre l&apos;évolution
                            <span className="data-confirm--bold">
                              {" "}
                              de vos dons.
                            </span>
                          </p>
                          <p className="data-confirm__desc text-center">
                            Il vous est aussi possible permis d&apos;enregistrer
                            un don fait en espèces.
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : ""}
                </div>
              </div>
            </div>

            <div className="auth-form__footer">
              <p className="footer-bottom-redirect">
                {selectedStep === 1 ? (
                  <>
                    
                    Avez-vous déjà un compte ?&nbsp;
                    <Link to={PATHS.signin}>
                      <span className="footer-bottom-redirect__link">
                        Connexion
                      </span>
                    </Link>
                  </>
                ) : ""}
              </p>
              <div className="footer-buttons-wrapper">
                { selectedStep === 1 ? (
                  <button type="button" className="btn btn--border btn--inline cancel-button">
                    Annuler
                  </button>
                ) : "" }
                
                { selectedStep !== 2 ? (
                  <button
                    onClick={submitHandler}
                    type="button" disabled={false}
                    className="btn btn--blue confirm-button"
                  >
                    { selectedStep === 3
                      ? "Valider"
                      : selectedStep === 4
                        ? "Commencer"
                        : "Suivant" }
                  </button>
                ) : ""}
              </div>
              <p className="mobile-desc">© Vaillants Battisseurs + 2021</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
