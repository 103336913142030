import http from "../http-common"
import { getOptions } from "../bv"

class UserService {
  getMe() {
    return http.get("/api/user", getOptions())
  }
  
  getMyDonations() {
    return http.get("/api/user/donations", getOptions())
  }
  
  getDonations(id) {
    return http.get(`/api/user/${id}/donations`, getOptions())
  }

  getReceptors(sortBy) {
    return http.get(`/api/receptor?sortBy=${ sortBy }`, getOptions())
  }

  getDonors(sortBy) {
    return http.get(`/api/donor?sortBy=${ sortBy }`, getOptions())
  }

  getOneDonor(id) {
    return http.get(`api/donor/${id}`, getOptions())
  }

  getOneReceptor(id) {
    return http.get(`api/receptor/${id}`, getOptions())
  }

  updateDonor(id, data) {
    return http.put(`api/donor/${id}`, data, getOptions())
  }

  updateMyProfil(data) {
    return http.put(`api/user`, data, getOptions())
  }
  
  updatePassword(data) {
    return http.put(`api/user/password`, data, getOptions())
  }
  
  updatePhone(data) {
    return http.put(`api/user/change-phone`, data, getOptions())
  }
  
  confirmPhoneUpdate(data) {
    return http.post(`api/user/confirm-change-phone`, data, getOptions())
  }

  updateReceptor(id, data) {
    return http.put(`api/receptor/${id}`, data, getOptions())
  }

  // get One Promise by Id
  getOnePromise() {
    return http.get("/api/promise/:id", getOptions())
  }

  create(data) {
    return http.post("/api/user", data, getOptions())
  }

  verifyNumber(data) {
    return http.post("/api/user/verify", data, getOptions())
  }
  
  resendVerificationCode(data) {
    return http.post("/api/user/resend-code", data, getOptions())
  }
  
  sendInvitation(id) {
    return http.get(`/api/user/${id}/sendInvitation`, getOptions())
  }

  archiveOne(id) {
    return http.post(`/api/user/${id}/archive`, {}, getOptions())
  }
  
  promoteOne(id) {
    return http.post(`/api/donor/${id}/promote`, {}, getOptions())
  }

  restoreOne(id) {
    return http.post(`/api/user/${id}/restore`, {}, getOptions())
  }
  
  setPromise(id, data) {
    return http.put(`/api/user/${id}/promise`, data, getOptions())
  }
  
  acceptInvite(uuid, data) {
    return http.post(`/accept-invitation/${uuid}`, data)
  }
  
  getCountries() {
    return http.get(`/countries`)
  }
  
  resetPassword(data) {
    return http.post(`api/user/reset-password`, data, getOptions())
  }
  
  getStats() {
    return http.get(`api/stats`, getOptions())
  }
}

export default new UserService()
