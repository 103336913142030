import React, { useEffect } from 'react'
import { motion } from "framer-motion"
import { remove } from '../../../arr-utils'


const notificationVariants = {
  initial: {
    opacity: 0,
    y: 50,
    scale: 0.2,
    transition: { duration: 0.1 }
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1
  },
  exit: {
    opacity: 0,
    scale: 0.2,
    transition: { ease: "easeOut", duration: 0.15 }
  },
  hover: { scale: 1.01, transition: { duration: 0.1 } }
}

export default function Notification({ notifications, setNotifications, notification }) {
  const { text, style } = notification
  
  const handleClose = () => setNotifications(remove(notifications, notification))
  
  const styleType = () => {
    switch (style) {
      case "success":
        return { background: "linear-gradient(15deg, #6adb00, #04e800)" }
      case "error":
        return { background: "linear-gradient(15deg, #ff596d, #d72c2c)" }
      case "warning":
        return { background: "linear-gradient(15deg, #ffac37, #ff9238)" }
      case "light":
        return { background: "linear-gradient(15deg, #e7e7e7, #f4f4f4)" }
      default:
        return { background: "linear-gradient(15deg, #202121, #292a2d)" }
    }
  }
  
  useEffect(() => {
    setTimeout(() => handleClose(), 1000 * 10)
  }, [])
  
//   const closeOnDrag = (event, info) => {
//     console.log(info)
//     if (info.velocity.x > 0) {
//       handleClose();
//     }
//   }
  
  return (
    <motion.div
      className="status-bar-component"
      // positionTransition
      drag
      // dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
      // dragElastic={0.9}
      // dragTransition={{ bounceStiffness: 300, bounceDamping: 10 }}
      // onDragEnd={closeOnDrag}
      style={styleType()} // Change the style based on style selection
      variants={notificationVariants} // Defined animation states
      whileHover="hover" // Animation on hover gesture
      initial="initial" // Starting animation
      animate="animate" // Values to animate to
      exit="exit" // Target to animate to when removed from the tree
    >
      <div className={`message-container message-container--${ style }`}>
        <div className="message-container__wrapper">
          <span className={`message-container__icon message-container__icon-${ style }`} />
          <p className="message-container__message">{ text }</p>
        </div>
        <span
          onClick={ handleClose }
          className="message-container__close"
        />
      </div>
    </motion.div>
  )
}
