import http from "../http-common"
import { getOptions } from "../bv"

class PromiseService {
  createOne(data) {
    return http.post("/api/promise", data, getOptions())
  }

  getAll() {
    return http.get("/api/promise", getOptions())
  }

  sumAll() {
    return http.get("/api/promise/total", getOptions())
  }

  getOne(id) {
    return http.get(`/api/promise/${id}`, getOptions())
  }

  updateOne(id, data) {
    return http.put(`/api/promise/${id}`, data, getOptions())
  }
}

export default new PromiseService()
