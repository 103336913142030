import http from "../http-common"
import { getOptions } from "../bv"

class DonationService {
  createOne(data) {
    return http.post("/api/donation", data, getOptions())
  }

  getAll(sortBy) {
    return http.get(`/api/donation?sortBy=${sortBy}&details=1`, getOptions())
  }
  
  sumAll() {
    return http.get("/api/donation/total", getOptions())
  }

  getInReview() {
    return http.get(`/api/donation/in_review`, getOptions())
  }

  updateOne(id, data) {
    return http.put(`/api/donation/${id}`, data, getOptions())
  }
  
  deleteOne(id) {
    return http.delete(`/api/donation/${id}`, getOptions())
  }
}

export default new DonationService()
