import React from "react"
import { Pie, Line, Bar } from "react-chartjs-2"
import { formatNumber } from "../../../bv"

export function Charts(donations, promises) {
  const data = {
    labels: ["Mobile", "", "Espece", "Carte banquaire", "Cagnote"],
    datasets: [
      {
        label: "Population (millions)",
        backgroundColor: [
          "#6d71f9",
          "#CFF1FF",
          "#CE99FE",
          "#A07DFA",
          "#8f5990",
          "#eeeeee",

          "#69D0F3",
          "#90DFFC",
          "#CFF1FF",
          "#CE99FE",
          "#A07DFA",
          "#8366F4",

          "#360167",
          "#6B0772",
          "#af1281",
          "#CF268A",
          "#E65C9C",
          "#FB8CAB",

          "#eeeeee",
          "#ae9191",
          "#8f5990",
          "#5c2e4c",
          "#36163a"
        ],
        data: [2478, 5267, 734, 784, 433]
      }
    ]
  }
  return (
    <Pie
      data={data}
      // width={"25rem"}
      // height={"25rem"}
      options={{
        title: {
          display: true,
          text: "Predicted world population (millions) in 2050"
        }
      }}
    />
  )
}

export function BarCharts({ data_ }) {
  const labels = []
  const values = []
  data_.sort(compare)
  data_.forEach(elt => {
    labels.push(formatNumber(elt._id))
    values.push(formatNumber(elt.count))
  })

  const data = {
    // labels: AMOUNTS.map(amount => amount.text),
    labels: labels,
    datasets: [
      {
        label: "Nombre de Donateurs par type de promesse",
        data: values,
        backgroundColor: [
          "rgba(105,208,243,0.55)",
          "rgba(144,223,252,0.55)",
          "rgba(207,241,255,0.55)",
          "rgba(206,153,254,0.54)",
          "rgba(160,125,250,0.51)",
          "rgba(131,102,244,0.5)",
          "rgba(54,1,103,0.52)",
          "rgba(107,7,114,0.49)",
          "rgba(175,18,129,0.5)",
          "rgba(207,38,138,0.51)",
          "rgba(230,92,156,0.51)",
          "rgba(251,140,171,0.51)"
        ],
        borderColor: [
          "#69D0F3",
          "#90DFFC",
          "#CFF1FF",
          "#CE99FE",
          "#A07DFA",
          "#8366F4",
          "#360167",
          "#6B0772",
          "#af1281",
          "#CF268A",
          "#E65C9C",
          "#FB8CAB"
        ],
        borderWidth: 1
      }
    ]
  }

  const options = {
    // scaleShowVerticalLines: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
            display: false
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
            display: false
          },
          ticks: {
            beginAtZero: true
          }
        }
      ]
    },
    animation: {
      onComplete: () => {
        // const chartInstance = this.chart
        // const ctx = chartInstance.ctx
        // console.log(chartInstance)
        // const height = chartInstance.controller.boxes[0].bottom
        // ctx.textAlign = "center"
        // Chart.helpers.each(this.data.datasets.forEach( (dataset, i) => {
        //   const meta = chartInstance.controller.getDatasetMeta(i)
        //   Chart.helpers.each(meta.data.forEach(function (bar, index) {
        //     ctx.fillText(dataset.data[index], bar._model.x, height - ((height - bar._model.y) / 2) - 10);
        //   }),this)
        // }),this)
      }
    }
  }

  return <Bar id="bar-promesse" data={data} options={options} />
}

export function LineCharts(donations, promises) {
  /*  const lineData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        fillColor: "rgba(172,194,132,0.4)",
        strokeColor: "#ACC26D",
        pointColor: "#fff",
        pointStrokeColor: "#9DB86D",
        data: [203, 156, 99, 251, 305, 247],
      },
    ],
  } */
  const data = {
    labels: ["Janvier", "Fevrier", " Mars", "Avril", "Mai", "Juin", "Juillet"],
    datasets: [
      {
        data: [86, 114, 106, 106, 107, 111, 133],
        label: "Espece",
        borderColor: "rgb(62,149,205)",
        backgroundColor: "rgb(62,149,205,0.1)",
        fill: true,
        lineTension: 0.4
      },
      {
        data: [70, 90, 44, 60, 83, 90, 100],
        label: "Cagnote",
        borderColor: "rgb(60,186,159)",
        backgroundColor: "rgb(60,186,159,0.1)",
        fill: true,
        lineTension: 0.4
      },
      {
        data: [10, 21, 60, 44, 17, 21, 17],
        label: "Carte Bancaire",
        borderColor: "rgb(255,165,0)",
        backgroundColor: "rgb(255,165,0,0.1)",
        fill: true,
        lineTension: 0.4
      },
      {
        data: [6, 3, 2, 2, 7, 0, 16],
        label: "Flooz",
        borderColor: "rgb(196,88,80)",
        backgroundColor: "rgb(196,88,80,0.1)",
        fill: true,
        lineTension: 0.4
      }
    ]
  }
  return <Line data={data} />
}


export function BarChart2({ data_ }) {
  const labels = []
  const values = []
  data_.sort(compare)
  
  const monthRange = [100000, 250000, 500000, 1000000, 5000000, 10000000, 50000000]
  monthRange.forEach((amount, index) => {
    const temp = {_id: amount, count: 0}
    temp.count = data_
      .filter(_ => _._id <= amount && _._id > (index === 0 ? 0 : monthRange[index - 1]))
      .reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)
    
    labels.push('<=' + formatNumber(temp._id))
    values.push(formatNumber(Math.round(temp.count * 100 / 446)))
  })
  
  const data = {
    // labels: AMOUNTS.map(amount => amount.text),
    labels: labels,
    datasets: [
      {
        label: "Pourcentage des promesses par interval",
        data: values,
        backgroundColor: [
          "rgba(105,208,243,0.55)",
          "rgba(144,223,252,0.55)",
          "rgba(207,241,255,0.55)",
          "rgba(206,153,254,0.54)",
          "rgba(160,125,250,0.51)",
          "rgba(131,102,244,0.5)",
          "rgba(54,1,103,0.52)",
          "rgba(107,7,114,0.49)",
          "rgba(175,18,129,0.5)",
          "rgba(207,38,138,0.51)",
          "rgba(230,92,156,0.51)",
          "rgba(251,140,171,0.51)"
        ],
        borderColor: [
          "#69D0F3",
          "#90DFFC",
          "#CFF1FF",
          "#CE99FE",
          "#A07DFA",
          "#8366F4",
          "#360167",
          "#6B0772",
          "#af1281",
          "#CF268A",
          "#E65C9C",
          "#FB8CAB"
        ],
        borderWidth: 1
      }
    ]
  }
  
  const options = {
    // scaleShowVerticalLines: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
            display: false
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
            display: false
          },
          ticks: {
            beginAtZero: true
          }
        }
      ]
    },
    animation: {
      onComplete: () => {
        // const chartInstance = this.chart
        // const ctx = chartInstance.ctx
        // console.log(chartInstance)
        // const height = chartInstance.controller.boxes[0].bottom
        // ctx.textAlign = "center"
        // Chart.helpers.each(this.data.datasets.forEach( (dataset, i) => {
        //   const meta = chartInstance.controller.getDatasetMeta(i)
        //   Chart.helpers.each(meta.data.forEach(function (bar, index) {
        //     ctx.fillText(dataset.data[index], bar._model.x, height - ((height - bar._model.y) / 2) - 10);
        //   }),this)
        // }),this)
      }
    }
  }
  
  return <Bar id="bar-promesse" data={data} options={options} />
}

function compare(a, b) {
  if (a._id > b._id) return -1
  if (a._id < b._id) return 1
  return 0
}
