// import stringSimilarity from "string-similarity"

export const SECRET_CODE_LENGTH = 6

export const PATHS = {
  signin: "/signin",
  signup: "/signup",
  recoverPassword: "/recover-password",

  home: "/",
  dashboard: "/dashboard",

  reporting: "/reporting",

  donationList: "/donations",
  addDonation: "/donation/new",

  makeDonation: "/makeDonation",

  receptorList: "/receptors",
  receptorProfil: "/receptor/:id",
  updateReceptor: "/receptor/:id",
  addReceptor: "/receptors",

  donorList: "/donors",
  updateDonor: "/donor/:id",
  donorProfil: "/donor/:id",
  addDonor: "/donors",

  profil: "/profil",
  acceptInvite: "/accept-invite/:uuid",
}

// export const PAYMENT_MODE = [
//   {
//     id: 1,
//     name: "Mobile Money",
//   },
//   {
//     id: 2,
//     name: "Carte de crédit",
//   }
// ]

export const PAYMENT_MODE = [
  { text: "flooz", value: "flooz" },
  { text: "tmoney", value: "tmoney" },
  { text: "visa", value: "visa" },
  { text: "masterCard", value: "masterCard" },
  { text: "paypal", value: "paypal" },
  { text: "cagnote", value: "cagnote" },
  { text: "espece", value: "espece" },
  { text: "western", value: "western" },
  { text: "ria", value: "ria" },
  { text: "wari", value: "wari" },
  { text: "moneyGram", value: "moneyGram" },
  { text: "Cheque", value: "cheque" },
  { text: "virement", value: "virement" },
  { text: "autre", value: "autre" },
]

export const AMOUNTS = [
  { text: "50 000 000", value: 50000000 },
  { text: "30 000 000", value: 30000000 },
  { text: "20 000 000", value: 20000000 },
  { text: "10 000 000", value: 10000000 },
  { text: "5 000 000", value: 5000000 },
  { text: "3 000 000", value: 3000000 },
  { text: "1 000 000", value: 1000000 },
  { text: "500 000", value: 500000 },
]

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getOptions() {
  let authData = localStorage.getItem("token")
  if (authData) {
    authData = JSON.parse(authData)
    const token = `${capitalizeFirstLetter(authData.token_type)} ${
      authData.token
    }`
    return {
      headers: {
        Authorization: token,
      },
    }
  }
  return {}
}

export async function requestErrorHandler(
  error,
  setSubmitting,
  history,
  setHTTPError
) {
  if (error.response) {
    if (error.response.status === 401) {
      // swal("Informations de connection incorrectes", "Essayez de vous reconnecter", "error")
      //   .then(() => window.location.replace(`${ PATHS.login }?redirect_url=${ history.location.pathname }`));
    }
  } else if (error.request) {
    console.log(error.request)
    // const { user, HTTPError, setHTTPError } = useContext(SessionContext);
    // await swal("500 Erreur Serveur", "Une erreur est survenue sur le serveur. Veuillez contacter l'admin", "error");
  } else {
    console.log("Error", error)
  }
  if (setSubmitting) setSubmitting(false)
}

/* function compare(a, b) {
  if (a.rating > b.rating) return -1
  if (a.rating < b.rating) return 1
  return 0
} */

export function compareCountryCode(a, b) {
  if (parseInt(a.callingCodes[0]) > parseInt(b.callingCodes[0])) return -1
  if (parseInt(a.callingCodes[0]) < parseInt(b.callingCodes[0])) return 1
  return 0
}

export function filteredData(filterText, arr, keys) {
  return arr.filter((item) => `${item._id ? item._id : item.id} ${keys.map((k) => (item[k] ? item[k].toString().toLowerCase() : "")).join(" ")}`.includes(filterText.toLowerCase()))
  
  /* const targetStrings = arr.map((item) => `${item._id ? item._id : item.id} ${keys.map((k) => (item[k] ? item[k].toString().toLowerCase() : "")).join(" ")}`)
  const ratings = stringSimilarity.findBestMatch(filterText.toLowerCase(), targetStrings.length ? targetStrings : [""])
  return !targetStrings.length
    ? arr
    : ratings.ratings
        .sort(compare)
        .filter((r) => r.rating >= 0)
        .map((rating) => arr.find((item) => item._id.toString() === rating.target.split(" ")[0])) */
}

export function formatNumber(number) {
  // return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
  return (
    number
      .toString()
      // .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
  )
}

export function requestToLogin(redirect) {
  window.location.replace(`${ PATHS.signin }?redirect_url=${ redirect }`)
}
