import React, { useEffect, useState } from "react"
import DayPicker from "react-day-picker"

export default function DayInput({ selectedDay, setSelectedDay }) {
  const [showPicker, setShowPicker] = useState(false)

  const dayClickHandler = (day) => {
    setSelectedDay(day)
    setShowPicker(false)
  }

  useEffect(() => {}, [selectedDay])

  return (
    <div className="date-picker-component date-picker-component--with-icon">
      <div className="DayPickerInput">
        <input
          placeholder="DD.MM.YYYY"
          readOnly={true}
          className="input date-picker-component__input"
          autoComplete="off"
          onKeyDown={(e) =>
            e.type === "keydown" &&
            !e.altKey &&
            e.code === "Escape" &&
            e.key === "Escape" &&
            setShowPicker(false)
          }
          value={`${
            selectedDay.getDate().toString().length < 2
              ? `0${selectedDay.getDate()}`
              : selectedDay.getDate()
          }.${
            (selectedDay.getMonth() + 1).toString().length < 2
              ? `0${selectedDay.getMonth() + 1}`
              : selectedDay.getMonth() + 1
          }.${selectedDay.getFullYear()}`}
          onFocus={() => setShowPicker(true)}
          onBlur={(event) => {
            !event.target.parentElement
              .querySelector(".DayPickerInput-OverlayWrapper")
              .contains(event.relatedTarget) && setShowPicker(false)
          }}
          onClick={() => setShowPicker(true)}
        />
        {showPicker ? (
          <DayPicker
            className="DayPickerInput-OverlayWrapper"
            onDayClick={dayClickHandler}
            selectedDays={selectedDay}
          />
        ) : null}
      </div>
    </div>
  )
}
