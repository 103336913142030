import http from "../http-common"
import { getOptions } from "../bv"

class PaymentService {
  init(data) {
    return http.post("/api/payment", data, getOptions())
  }
}

export default new PaymentService()
