import React, { useContext, useEffect, useState } from "react"
import { useHistory, Link } from "react-router-dom"
import styled from "styled-components"
import DonationService from "../../services/donation.service"
import UserService from "../../services/user.service"
import { SessionContext } from "../../App"
import { formatNumber, PATHS } from "../../bv"
import dateFormat from "dateformat"
import Loader from "../components/cards/Loader"


const ProgressBar = styled.h1`
    background: unset;
    padding: unset;
    &:hover + .btn__tooltip, &:hover + .btn__tooltip:after,
    &:active + .btn__tooltip, &:active + .btn__tooltip:after {
      opacity: 1;
      visibility: visible;
    };
    & ~ .tooltip {
      position: relative;
      top: -90px;
    }
    & .progressbar__dot {
      left: ${ props => props.progression }%;
      width: 2rem;
      height: 2rem;
      border: 6px solid #fff;
    }
    & .progressbar__content {
      width: ${ props => props.progression }%;
    }
  `

const CustomTooltip = styled.span`
    right: -25px;
    &:after {
      left: 71%;
    }
  `

export default function Dashboard() {
  const [donations, setDonations] = useState(null)
  const [totalPromises, setTotalPromises] = useState(0)
  const [totalDonations, setTotalDonations] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { user } = useContext(SessionContext)
  const promise = user ? user.promise : null

  const history = useHistory()

  const sumTotal = list => {
    let sum = 0
    list.forEach(item => {
      sum += Number(item.amount)
    })
    return sum
  }

  const userPromiseAmount = promise ? promise.amount : 0
  const userTotalDonations = donations ? sumTotal(donations) : 0
  const objectif = 1700000000
  const percentage = (objectif, totalDonations) => parseInt((totalDonations * 100) / objectif)
  const donorDonationPercentage = percentage(userPromiseAmount, totalDonations)
  const donationToValidate = donations && donations.length ? donations.filter(donation => donation.status === "IN_PROGRESS") : []
  const totalPercentage = percentage(objectif, totalDonations)
  console.log(donorDonationPercentage, userTotalDonations)

  useEffect(() => {
    setIsSubmitting(true)
    DonationService.getInReview()
      .then(response => {
        setIsSubmitting(false)
        if (response.status === 200) {
          setDonations(response.data)
        }
      })
      .catch(e => {
        console.log(e)
        setIsSubmitting(false)
      })
    
    UserService.getStats()
      .then(response => {
        setTotalPromises(response.data.global.promise)
        setTotalDonations(user.role === 'donor' ? response.data.personal.donation : response.data.global.donation)
      })
      .catch(e => {
        console.log(e)
        setIsSubmitting(false)
      })
  }, [])
  
  const percentageStyle = {
    fontSize: '1.4rem',
    fontWeight: '500',
    color: '#7e7e90'
  }
  
  const promiseToDisplay = user && user.role === "donor"
    ? promise
      ? promise.amount
      : 0
    : totalPromises || 0

  return (
    <div className="page" style={{ padding: "0.2rem 1rem" }}>
      { isSubmitting && <Loader full={true} /> }
      
      {
        donations ? (
          <div className="page-content">
            <div className="page-content__row">
              <div className="page-heading" style={{ marginBottom: "1.8rem" }}>
                <h2 className="page-heading__title">
                  Bienvenu(e){" "}
                  <span className="text-capitalize">
                    {user && user.lastName + " " + user.firstName}
                  </span>
                </h2>
              </div>
            </div>
            
            <section className="dashboard">
              <div className="dashboard__wrapper">
                <div className="panel onboarding">
                  <div className="onboarding__header">
                    <h4 className="onboarding__header-title">
                      {user && user.role === "donor" ? "Votre progression (donation)" : "Progression globale des donations"}
                    </h4>
                    <div className="progressbar">
                      <div style={{ maxWidth: 'unset' }} className="progressbar__line">
                        <div className="progressbar__content" style={{ width: user && user.role === "donor" ? `${donorDonationPercentage}%` : `${totalPercentage}%` }}>
                          <span className="progressbar__dot" style={{ left: user && user.role === "donor" ? `${donorDonationPercentage}%` : `${totalPercentage}%` }} />
                        </div>
                      </div>
                      <p className="progressbar__value">{ user && user.role === "donor" ? `${donorDonationPercentage}%` : `${totalPercentage}%` }</p>
                    </div>
                  </div>
                  
                  <div className="onboarding__steps">
                    <div className="onboarding__steps-container onboarding__steps-container--admin">
                      <div className="onboarding__steps-text-wrapper">
                        <p className="onboarding__steps-title">
                          Vous êtes un vaillant batisseur{" "}
                        </p>
                        <p className="onboarding__steps-description">
                          Que vos mains se fortifient, et soyez de vaillants batisseurs ...
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  <br />
                  
                  <div className="onboarding__steps">
                    <div className="onboarding__steps-container onboarding__steps-container--admin">
                      <span className="table__cell-inner">
                        <button
                          type="button"
                          className="btn btn--lightblue patient-list-container__table-btn"
                          onClick={() => history.push(PATHS.makeDonation)}
                        >
                          Faire une donation
                        </button>
                      </span>
                      
                      {
                        user && user.role !== "donor" ? (
                          <div className="onboarding__steps-btn-wrapper">
                            <Link className="onboarding__steps-nav-link" to={PATHS.addDonation}>
                              Enrégister une donation
                            </Link>
                          </div>
                        ) : ""
                      }
                    </div>
                  </div>
                </div>
                
                <section className="statistics">
                  <div className="panel statistics__panel" style={{ height: '18.4rem' }}>
                    <div className="statistics__panel-header">
                      <p className="statistics__title">
                        {user && user.role === "donor" ? "Promesse" : "Total des promesses"}
                      </p>
                    </div>
                    
                    <div className="statistics__select">
                      <div className="active-filter-switch">
                        <div className="active-filter-switch__dropdown">XOF</div>
                      </div>
                    </div>
                    
                    <p className="statistics__number ">
                      { formatNumber(promiseToDisplay) }
                    </p>
                    
                    <ProgressBar className="progressbar" progression={ Math.floor(promiseToDisplay * 100 / objectif) }>
                      <div style={{ maxWidth: 'unset', background: '#AAB2BD', height: '.7rem', marginRight: 'unset' }} className="progressbar__line">
                        <div className="progressbar__content">
                          <span className="progressbar__dot"/>
                        </div>
                      </div>
                    </ProgressBar>
                    <span className="tooltip btn__tooltip">{ Math.floor(promiseToDisplay * 100 / objectif) }% de l&apos;estimation globale</span>
                  </div>
                  
                  <div className="panel statistics__panel" style={{ height: '18.4rem' }}>
                    <div className="statistics__panel-header">
                      <p className="statistics__title">
                        {user && user.role === "donor" ? "Total de mes donations" : "Total des donations"}{" "}
                      </p>
                    </div>
                    <div className="statistics__select">
                      <div className="active-filter-switch">
                        <div className="active-filter-switch__dropdown">XOF</div>
                      </div>
                    </div>
                    <p className="statistics__number ">
                      {formatNumber(totalDonations)}
                      <span style={percentageStyle}> ({ Math.floor(totalDonations * 100 / promiseToDisplay) }%)</span>
                    </p>
  
                    <ProgressBar className="progressbar" progression={ Math.floor(totalDonations * 100 / promiseToDisplay) }>
                      <div style={{ maxWidth: 'unset', background: '#AAB2BD', height: '.7rem', marginRight: 'unset' }} className="progressbar__line">
                        <div className="progressbar__content">
                          <span className="progressbar__dot"/>
                        </div>
                      </div>
                    </ProgressBar>
                    <span className="tooltip btn__tooltip">{ Math.floor(totalDonations * 100 / promiseToDisplay) }% de l&apos;objectif</span>
                  </div>
                  
                  <div className="panel statistics__panel" style={{ height: '18.4rem' }}>
                    <div className="statistics__panel-header">
                      <p className="statistics__title">Estimation globale</p>
                    </div>
                    <div className="statistics__select">
                      <div className="active-filter-switch">
                        <div className="active-filter-switch__dropdown">XOF</div>
                      </div>
                    </div>
                    <p className="statistics__number statistics__number--incomplete">
                      {formatNumber(objectif)}
                    </p>
                  </div>
                </section>
              </div>
              
              <div className="panel dashboard-panel visits-admin">
                <div className="dashboard-panel__header">
                  <p className="dashboard-panel__title">En attente </p>
                  <span className="dashboard-panel__btn-wrapper">
                    <Link className="dashboard-panel__link" to={`${ PATHS.donationList }?status=NOT_APPROVED`}>
                      Afficher tout
                    </Link>
                    <button onClick={undefined} type="button" className="btn btn--border btn--with-icon-only dashboard-panel__btn-plus">
                      <span className="btn__icon btn__icon--refresh btn__icon--only" />
                      <span className="tooltip btn__tooltip">Actualiser</span>
                    </button>
                    
                    <button
                      onClick={
                        user.role === "donor"
                          ? () => history.push(PATHS.makeDonation)
                          : () => history.push(PATHS.addDonation)
                      }
                      type="button" className="btn btn--border btn--with-icon-only dashboard-panel__btn-plus"
                    >
                      <span className="btn__icon btn__icon--plus2 btn__icon--only" />
                      <CustomTooltip className="tooltip btn__tooltip">
                        Ajouter une donation
                      </CustomTooltip>
                    </button>
                  </span>
                </div>
                
                <div className="dashboard-panel__content dashboard-panel__content--narrow" style={{ padding: "unset", margin: "unset" }}>
                  <div className="panel__body" style={{ padding: "5px 5px" }}>
                    {donationToValidate.length ? (
                      donationToValidate.map((d, i) => (
                        <div key={i} className="doc-templates__item" style={{
                          padding: "1rem",
                          backgroundColor: "rgba(109, 113, 249, .08)",
                          borderRadius: "5px"
                        }}>
                          <span className={`btn__icon btn__icon--${
                            d.status === "RECEIVED"
                              ? "valid"
                              : d.status === "CANCELED"
                                ? "inactif"
                                : d.status === "IN_PROGRESS"
                                  ? "inprogress"
                                  : d.status === "APPROVED"
                                    ? "valid"
                                    : ""
                          }`} />
                          <div>
                            <div style={{
                              textTransform: "uppercase",
                              fontSize: "1.5rem"
                            }}>
                              {" "}{d.transactionMethod}
                            </div>
                            <div style={{
                              textAlign: "center",
                              fontStyle: "italic"
                            }}>
                              {formatNumber(d.amount)} XOF
                            </div>
                          </div>
                          <div>
                            { dateFormat(d.createdAt, "dd.mm.yyyy  h:MM TT") }
                          </div>
                          <div className="doc-templates__item-actions">
                            <span className="doc-templates__item-link" onClick={undefined}>
                              <button
                                type="button" className="btn btn--icon btn--small"
                                onClick={() => history.push(PATHS.donationList)}
                              >
                                <span title="Voir" className="btn__icon btn__icon--eye btn__icon--only" />
                              </button>
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="dashboard-panel__content-empty-state dashboard-panel__content-empty-state--admin">
                        <span className="dashboard-panel__content-none" />
                        <p>Toutes vos donations sont validées</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : ""
      }
    </div>
  )
}
