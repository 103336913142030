import React, { useState, useEffect } from "react"
import PhoneInput from "../../components/cards/PhoneInput"
import PasswordInput from '../../components/cards/PasswordInput'
import UserService from '../../../services/user.service'
import { logoutHandler } from '../../layouts/nav/Header'

export default function PersonalInfo({ showNotification, setIsSubmitting, user }) {
  const [showUpdate, setShowUpdate] = useState(false)
  
  const [firstName, setFirstName] = useState(user ? user.firstName : "")
  const [lastName, setLastName] = useState(user ? user.lastName : "")
  const [email, setEmail] = useState(user ? user.email : "")
  const [telephone, setTelephone] = useState(user ? user.telephone.value : "")
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [repeatedNewPassword, setRepeatedNewPassword] = useState("")
  const [isChecked, /* setIsChecked */] = useState(user ? user.isActive : "")
  const [callingCode, setCallingCode] = useState("+228")
  const [password, setPassword] = useState("")
  
  const [confirmationCode, setConfirmationCode] = useState("")
  const [showPhoneConfirmationModal, setShowPhoneConfirmationModal] = useState(false)
  
  const updateMyProfil = data => {
    setIsSubmitting(true)
    UserService.updateMyProfil(data)
      .then(response => {
        if (response.status === 200) {
          localStorage.setItem("user", JSON.stringify({ data: user }))
          location.replace(`/profil`)
          setShowUpdate(false)
        }
      })
      .catch(e => {
        console.log(e)
        e.response && e.response.data && showNotification("error", e.response.data.message ? e.response.data.message : "Error")
      })
  }
  
  const changePassword = () => {
    if (oldPassword && newPassword) {
      if (newPassword === repeatedNewPassword && newPassword.length >= 6) {
        const data = {
          password: oldPassword,
          newPassword,
        }
        setIsSubmitting(true)
        UserService.updatePassword(data)
          .then(response => {
            if (response.status === 200) {
              setIsSubmitting(false)
              logoutHandler()
            }
          })
          .catch(e => {
            console.log(e)
            e.response && e.response.data && showNotification("error", e.response.data.message ? e.response.data.message : "Error")
          })
      } else {
        showNotification("error", "Mots de passe trop courts ou non conformes!")
      }
    }
  }
  
  const changePhoneNumber = () => {
    if (telephone && password) {
      const data = { telephone, password, callingCode }
      setIsSubmitting(true)
      UserService.updatePhone(data)
        .then(response => {
          if (response.status === 200) {
            showNotification("success", response.data.message)
            setShowPhoneConfirmationModal(true)
            setIsSubmitting(false)
          }
        })
        .catch(e => {
          console.log(e)
          e.response && e.response.data && showNotification("error", e.response.data.message ? e.response.data.message : "Error")
        })
    }
    else {
      showNotification("error", "Veuillez remplir tous les champs du formulaire")
    }
  }
  
  const confirmPhoneUpdate = () => {
    if (confirmationCode) {
      setIsSubmitting(true)
      UserService.confirmPhoneUpdate({ code: confirmationCode })
        .then(response => {
          if (response.status === 200) {
            showNotification("success", response.data.message)
            setShowPhoneConfirmationModal(false)
            setIsSubmitting(false)
            logoutHandler()
          }
        })
        .catch(e => {
          console.log(e)
          e.response && e.response.data && showNotification("error", e.response.data.message ? e.response.data.message : "Error")
        })
    }
    else {
      showNotification("error", "Veuillez remplir tous les champs du formulaire")
    }
  }
  
  const changeBasicsInfos = () => {
    if (firstName && lastName && email) {
      const data = { firstName, lastName, email }
      updateMyProfil(data)
    }
    else {
      showNotification("error", "Veuillez remplir tous les champs du formulaire")
    }
  }

  useEffect(() => {}, [])

  return (
    <div className="patient-info-container">
      
      {
        showPhoneConfirmationModal ? (
          <div className="fullpage ">
            <div className="modal modal--">
              <div className="panel modal__content">
                <div className="modal__header ">
                  <h2 className="modal__title">Confirmation du numero de téléphone</h2>
                  <button
                    type="button"
                    className="btn  btn--with-icon-only btn--with-icon-close"
                  >
                    <span
                      className="btn__icon btn__icon--close btn__icon--only"
                      onClick={() => setShowPhoneConfirmationModal(false)}
                    />
                  </button>
                </div>
            
                <div className="modal__content--inner">
                  <div className="modal__main">
                    <form className="form patient-form">
                      <div className="form-group form-group--1">
                        <div className="select-form-label">
                          <label className="form-group__label">Code de confirmation</label>
                        </div>
                        <input
                          type="text"
                          className="input input-- form-control"
                          value={confirmationCode}
                          onChange={ event => setConfirmationCode(event.target.value) }
                        />
                      </div>
                    </form>
                  </div>
                  <div className="modal__footer">
                    <div>
                      <button
                        type="button" className="btn btn--blue"
                        onClick={ confirmPhoneUpdate }
                      >
                        confirmer et changer le numero
                      </button>
                  
                      <p className="">Vous n&apos;avez pas recu de message ?&nbsp;
                        <span className="">Renvoyer</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : ""
      }
      
      {
        showUpdate ? (
          <>
            <div className="panel panel--multi">
              <div className="panel__header panel__header--border  panel__header--wrap">
                <h3 className="panel__title ">Informations</h3>
                <div className="buttons-container buttons-container--row">
                  <button
                    type="button" className="btn btn--lightblue"
                    onClick={ () => setShowUpdate(false) }
                  >
                    Annuler
                  </button>
                  <button
                    type="button" className="btn btn--blue"
                    onClick={ () => changeBasicsInfos() }
                  >
                    Enregistrer
                  </button>
                </div>
              </div>
              <div className="panel__body patient-edit-panel">
                <form className="form patient-form">
                  <h2 className="form__title">Données personnelles</h2>
                  <div className="form-group form-group--2">
                    <div className="select-form-label">
                      <label className="form-group__label">Nom</label>
                    </div>
                    <input
                      type="text"
                      className="input input-- form-control"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="form-group form-group--2">
                    <div className="select-form-label">
                      <label className="form-group__label">
                        Prénom
                      </label>
                    </div>
                    <input
                      type="text"
                      className="input input-- form-control"
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                    />
                  </div>
                  <div className="form-group form-group--1">
                    <div className="select-form-label">
                      <label className="form-group__label">
                        E-mail
                      </label>
                    </div>
                    <input
                      type="text"
                      className="input input-- form-control"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>
                </form>
              </div>
            </div>
            
            <div className="panel panel--multi section--red">
              <div className="panel__header panel__header--border  panel__header--wrap">
                <h3 className="panel__title ">Numéro de téléphone</h3>
                <div className="buttons-container buttons-container--row">
                  <button
                    type="button" className="btn btn--lightblue"
                    onClick={() => setShowUpdate(false)}
                  >
                    Abandonner
                  </button>
                  <button
                    type="button"
                    className="btn btn--red"
                    onClick={ () => changePhoneNumber() }
                  >
                    Changer le numéro de téléphone
                  </button>
                </div>
              </div>
              <div className="panel__body patient-edit-panel">
                <form className="form patient-form">
                  <h2 className="form__title">Changement de numéro de téléphone</h2>
                
                  <div className="form-group form-group--1 phone">
                    <PhoneInput
                      label={"Nouveau numéro de téléphone"}
                      callingCode={callingCode}
                      setCallingCode={setCallingCode}
                      value={telephone}
                      setValue={setTelephone}
                    />
                  </div>
                  
                  <div className="form-group form-group--1 form-group--password">
                    <div className="select-form-label">
                      <label className="form-group__label">Mot de passe</label>
                    </div>
                    
                    <PasswordInput
                      value={password}
                      setValue={setPassword}
                      submitHandler={undefined}
                    />
                  </div>
                </form>
              </div>
            </div>
            
            <div className="panel panel--multi section--red">
              <div className="panel__header panel__header--border  panel__header--wrap">
                <h3 className="panel__title ">Mot de passe</h3>
                <div className="buttons-container buttons-container--row">
                  <button
                    type="button" className="btn btn--lightblue"
                    onClick={() => setShowUpdate(false)}
                  >
                    Abandonner
                  </button>
                  <button
                    type="button" className="btn btn--red"
                    onClick={() => changePassword()}
                  >
                    Changer le mot de passe
                  </button>
                </div>
              </div>
              <div className="panel__body patient-edit-panel">
                <form className="form patient-form">
                  <div className="form-group form-group--1 form-group--password">
                    <div className="select-form-label">
                      <label className="form-group__label">Mot de passe actuel</label>
                    </div>
                    
                    <PasswordInput
                      value={oldPassword}
                      setValue={setOldPassword}
                      submitHandler={undefined}
                    />
                  </div>
                  
                  <div className="form-group form-group--1 form-group--password">
                    <div className="select-form-label">
                      <label className="form-group__label">Nouveau mot de passe</label>
                    </div>
                    
                    <PasswordInput
                      value={newPassword}
                      setValue={setNewPassword}
                      submitHandler={undefined}
                    />
                  </div>
                  
                  <div className="form-group form-group--1 form-group--password">
                    <div className="select-form-label">
                      <label className="form-group__label">Répéter le nouveau mot de passe</label>
                    </div>
                    
                    <PasswordInput
                      value={repeatedNewPassword}
                      setValue={setRepeatedNewPassword}
                      submitHandler={undefined}
                    />
                  </div>
                </form>
              </div>
            </div>
          </>
        ) : (
          <div className="panel panel--multi">
            <div className="panel__header panel__header--border  panel__header--wrap">
              <h3 className="panel__title">Resumé du profil</h3>
              <div className="buttons-container buttons-container--row">
                <button
                  type="button" className="btn btn--border btn--with-icon"
                  onClick={() => setShowUpdate(true)}
                >
                  <span className="btn__icon btn__icon--edit" />
                  Modifier les données
                </button>
              </div>
            </div>
            <div className="panel__body ">
              <div className="tables-container">
                <table className="table table--minimal">
                  <caption className="table__title">
                    Informations personnelles
                  </caption>
                
                  <tbody>
                  <tr className="table__row  table__row--basic ">
                    <td className="table__cell table__cell--basic ">
                                  <span className="table__cell-inner">
                                    Nom et Prénoms
                                  </span>
                    </td>
                    <td className="table__cell table__cell--basic ">
                                  <span className="table__cell-inner">
                                    {user &&
                                    `${user.firstName} ${user.lastName}`}
                                  </span>
                    </td>
                  </tr>
                
                  <tr className="table__row  table__row--basic ">
                    <td className="table__cell table__cell--basic ">
                                  <span className="table__cell-inner">
                                    E-mail
                                  </span>
                    </td>
                    <td className="table__cell table__cell--basic ">
                                  <span className="table__cell-inner">
                                    {user && user.email ? user.email : "Néant"}
                                  </span>
                    </td>
                  </tr>
                
                  <tr className="table__row  table__row--basic ">
                    <td className="table__cell table__cell--basic ">
                                  <span className="table__cell-inner">
                                    Téléphone
                                  </span>
                    </td>
                    <td className="table__cell table__cell--basic ">
                                  <span className="table__cell-inner">
                                    {user &&
                                    `${user.telephone.callingCode} ${user.telephone.value}`}
                                  </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              
                <table className="table table--minimal">
                  <caption className="table__title">Compte</caption>
                
                  <tbody>
                  <tr className="table__row  table__row--basic ">
                    <td className="table__cell table__cell--basic ">
                                  <span className="table__cell-inner">
                                    Status
                                  </span>
                    </td>
                    <td className="table__cell table__cell--basic ">
                                  <span className="table__cell-inner">
                                    {user && isChecked ? (
                                      <span style={{ color: "#14ce8c" }}>
                                        Compte Actif
                                      </span>
                                    ) : (
                                      <span style={{ color: "#ff505a" }}>
                                        Compte Inactif
                                      </span>
                                    )}
                                  </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}
