import React from "react"
import Header from "./Header"
import SideBar from './SideBar'

const Nav = () => {
  return (
    <>
      <SideBar />
      <Header />
    </>
  )
}

export default Nav
