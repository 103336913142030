import React, { useContext, useState } from 'react'
import Welcome from '../components/cards/Welcome'
import { Link, useLocation } from 'react-router-dom'
import { PATHS } from '../../bv'
import { SessionContext } from '../../App'
import AuthService from '../../services/auth.service'
import UserService from '../../services/user.service'
import PasswordInput from '../components/cards/PasswordInput'
import Loader from '../components/cards/Loader'


export default  function SignIn({ showNotification }) {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  
  const [telephone, setTelephone] = useState('')
  const [password, setPassword] = useState('')
  const [callingCode, setCallingCode] = useState('+228')
  const [showCountriesDropdown, setShowCountriesDropdown] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { countries } = useContext(SessionContext)
  
  const query = useQuery()
  const redirectURL = query.get("redirect_url")
  
  const submitHandler = () => {
    if (password && telephone) {
      setIsSubmitting(true)
      const data = { telephone: {callingCode, value: telephone}, password }
      AuthService.signin(data)
        .then(response => {
          if (response.status === 200) {
            localStorage.setItem("token", JSON.stringify(response.data))
            UserService.getMe()
              .then(response => {
                if (response.status === 200) {
                  localStorage.setItem("user", JSON.stringify(response.data))
                  location.replace(redirectURL || PATHS.home)
                }
              })
              .catch(e => {
                setIsSubmitting(false)
                const message = e.response.statusCode === 500
                  ? "Une erreur s'est produite du côté du serveur"
                  : e.response?.data?.message ?? "Erreur de réseau"
                showNotification("error", message)
              })
          }
        })
        .catch(e => {
          const message = e.response.statusCode === 500
            ? "Une erreur s'est produite du côté du serveur"
            : e.response?.data?.message ?? "Erreur de réseau"
          showNotification("error", message)
        })
        .finally(() => setIsSubmitting(false))
    }
  }
  
  const toggleCountriesDropdown = () => {
    setShowCountriesDropdown(!showCountriesDropdown)
  }
  
  return  (
    <div className="auth">
      <Welcome />
      <div className="auth__content">
        { isSubmitting ? <Loader full/> : "" }
        <div className="auth-form">
          <div className="auth-form__content">
            <div className='auth-form__mobile-logo'/>
            <form className="login-form">
              <div className="entry" style={{marginTop: 0}}>
                <div className="entry__title">
                  <h3 className="entry__header text-center">Connectez-vous pour accéder à votre tableau de bord</h3>
                </div>
              </div>
              
              <div className="login-form__inputs">
                <div className="form-group form-group--1 form-group--side ">
                  <div className="select-form-label">
                    <label className="form-group__label">Téléphone</label>
                  </div>
                  <div className='form-group form-group--1 register-form__dialing'>
                    <div className='select-container select-container--simple ' tabIndex='-1'>
                      <input
                        type='text' autoComplete='off'
                        onClick={ toggleCountriesDropdown }
                        onChange={() => {}}
                        onKeyDown={ (e) => e.type === "keydown" && !e.altKey && e.code === "Enter" && e.key === "Enter" && submitHandler() }
                        value={ callingCode }
                        className='input input--dropdown user-select-none'
                      />
                      <div style={{ display: showCountriesDropdown ? 'block' : 'none' }} className='dropdown dropdown--simple'>
                        <ul className='dropdown-list select-list '>
                          {
                            countries.map((country, i) => (
                              <li
                                onClick={ () => {
                                  setCallingCode('+' + country.callingCodes[0])
                                  toggleCountriesDropdown()
                                }}
                                key={ i } style={{width: '100px'}}
                                className='dropdown-list__item '
                              >
                                { country.alpha2Code } +{ country.callingCodes[0] }
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='form-group form-group--2 register-form__phone'>
                    <input
                      onKeyDown={ (e) => e.type === "keydown" && !e.altKey && e.code === "Enter" && e.key === "Enter" && submitHandler() }
                      type='number' className='input input-- form-control'
                      placeholder='Entrez le numéro de téléphone'
                      value={ telephone } onChange={ (e) => setTelephone(e.target.value.trim()) }
                    />
                  </div>
                </div>

                <div className="form-group form-group--1 form-group--password form-group--side ">
                  <div className="select-form-label"><label className="form-group__label">Mot de passe</label></div>
                  <PasswordInput value={ password } setValue={ setPassword } submitHandler={ submitHandler }/>
                </div>
                
                <p className="login-form__redirect login-form__redirect--margin">
                  Vous avez oublier votre mot de passe ?&nbsp;
                  <a href={ PATHS.recoverPassword }>
                    <span className="login-form__link">Se souvenir</span>
                  </a>
                </p>
              </div>
            </form>
          </div>

          <div className='auth-form__footer'>
            <p className="login-form__redirect">Vous n&apos; avez pas de compte?&nbsp;
              <Link to={ PATHS.signup }>
                <span className="login-form__link">Inscrivez-vous</span>
              </Link>
            </p>

            <button
              type="button" className="btn btn--blue btn--wider confirm-button"
              onClick={ submitHandler } disabled={ isSubmitting }
            >
              Connexion
            </button>
            <p className='mobile-desc'>© Vaillants Battisseurs + 2021</p>
          </div>
        </div>
      </div>
    </div>
  )
}
