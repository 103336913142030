import React, { useContext, useEffect, useState } from 'react'
import { filteredData, formatNumber, PATHS } from '../../../../bv'
import { useHistory } from 'react-router-dom'
import Loader from '../../../components/cards/Loader'
import Pagination from '../../../components/cards/Pagination'
import { SessionContext } from '../../../../App'
import useModal from '../../../../hooks/useModal'
import Modal from '../../cards/Modal'
import { ModalContainer } from '../../donation/DonationList'

export default function Donations({ sortBy, setSortBy, donations, donor, showNotification }) {
  const [filterText] = useState("")
  
  const [activePage, setActivePage] = useState(1)
  const [perPage, setPerPage] = useState(5)
  
  const [selectedDonation, setSelectedDonation] = useState(null)
  
  const history = useHistory()
  const { user } = useContext(SessionContext)
  
  const { modalOpen, close, open } = useModal()
  const [modalType /* setModalType */] = useState("dropIn")
  
  let filteredDonations
  if (donations)
    filteredDonations = filteredData(filterText, donations, [
      "transactionMethod",
      "amount",
      "status",
      "accountNumber",
      "date",
    ])
  
  useEffect(() => {
    setPerPage(6)
  }, [])
  
  const showDonationDetails = (donation) => {
    setSelectedDonation(donation)
    open()
  }

  return (
    <>
      <div className="panel">
        <div className="table-wrapper" style={{ minHeight: "510px" }}>
          <table className="table table--basic">
            <thead className="table__head">
            <tr className="table__row  table__row--head ">
              <th
                onClick={() =>
                  setSortBy((prevState) =>
                    prevState === "transactionMethod" ? "-transactionMethod" : "transactionMethod"
                  )
                }
                className={`table__cell table__cell--head table__cell--basic ${
                  sortBy === "transactionMethod" ? "table__cell--arrow" : sortBy === "-transactionMethod" ? "table__cell--arrow table__cell--arrow-up" : ""
                }`}
              >
                <span className="table__cell-inner">Methode de Transaction</span>
                <span className="table__cell-arrow" />
              </th>
              
              <th
                onClick={() =>
                  setSortBy((prevState) => prevState === "amount" ? "-amount" : "amount"
                  )
                }
                className={`table__cell table__cell--head table__cell--basic ${
                  sortBy === "amount" ? "table__cell--arrow" : sortBy === "-amount" ? "table__cell--arrow table__cell--arrow-up" : ""
                }`}
              >
                <span className="table__cell-inner">Montant (XOF)</span>
                <span className="table__cell-arrow" />
              </th>
              
              <th
                onClick={() => setSortBy((prevState) => prevState === "status" ? "-status" : "status")}
                className={`table__cell table__cell--head table__cell--basic ${
                  sortBy === "status"
                    ? "table__cell--arrow"
                    : sortBy === "-status"
                      ? "table__cell--arrow table__cell--arrow-up"
                      : ""
                }`}
              >
                <span className="table__cell-inner">Status</span>
                <span className="table__cell-arrow" />
              </th>
              
              <th
                onClick={() =>
                  setSortBy((prevState) => prevState === "donations.accountNumber" ? "-accountNumber" : "accountNumber")
                }
                className={`table__cell table__cell--head table__cell--basic ${
                  sortBy === "accountNumber"
                    ? "table__cell--arrow"
                    : sortBy === "-accountNumber"
                      ? "table__cell--arrow table__cell--arrow-up"
                      : ""
                }`}
              >
                <span className="table__cell-inner">
                  Numero de compte
                </span>
                <span className="table__cell-arrow"/>
              </th>
              
              <th
                onClick={() => setSortBy((prevState) => prevState === "date" ? "-date" : "date")}
                className={`table__cell table__cell--head table__cell--basic ${sortBy === "date" ? "table__cell--arrow" : sortBy === "-date" ? "table__cell--arrow table__cell--arrow-up" : ""}`}
              >
                <span className="table__cell-inner">Date de la transaction</span>
                <span className="table__cell-arrow" />
              </th>
              
              <th
                onClick={() => setSortBy((prevState) => prevState === "createdAt" ? "-createdAt" : "createdAt")
                }
                className={`table__cell table__cell--head table__cell--basic ${
                  sortBy === "createdAt" ? "table__cell--arrow" : sortBy === "-createdAt" ? "table__cell--arrow table__cell--arrow-up" : ""
                }`}
              >
                <span className="table__cell-inner">
                  Date d&apos;enrégistrement
                </span>
                <span className="table__cell-arrow" />
              </th>
              
              <th className="table__cell table__cell--head table__cell--basic ">
                <span className="table__cell-inner" />
                <span className="table__cell-arrow" />
              </th>
            </tr>
            </thead>
            
            <tbody>
            {filteredDonations ? (
              filteredDonations.length ? (
                filteredDonations
                  .slice((activePage - 1) * perPage, activePage * perPage)
                  .map((donation, i) => (
                    <tr
                      key={i} className="table__row table__row--basic"
                      style={{ backgroundColor: donation.isArchived ? "antiquewhite" : "", }}
                      onClick={ user?.role === 'admin' ? () => showDonationDetails(donation) : undefined }
                    >
                      <td className="table__cell table__cell--clickable table__cell--mobile table__cell--bold">
                        <span className="table__cell-inner">
                          <span className={`${
                              ["visa", "paypal", "masterCard", "VISAMTG", "VISAM"].includes(donation.transactionMethod)
                                ? "visaImage"
                                : ["espece", "cagnote"].includes(donation.transactionMethod)
                                  ? "dollar_coins"
                                  : ["flooz", "tmoney", "FLOOZ", "FLOOZTG", "TMONEYTG"].includes(donation.transactionMethod)
                                    ? "phone_money"
                                    : ["western", "ria", "wari", "moneyGram",].includes(donation.transactionMethod)
                                      ? "transfert_international" : ""
                            } employees__userimage`} />
                          <span style={{ textTransform: "capitalize" }}>
                            {donation.transactionMethod}
                          </span>
                        </span>
                      </td>
                      
                      <td className="table__cell table__cell--clickable table__cell--mobile table__cell--bold">
                        <span className="table__cell-inner">
                          <span style={{ textTransform: "capitalize" }}>
                            {formatNumber(donation.amount)}
                          </span>
                        </span>
                      </td>
                      
                      <td className="table__cell table__cell--mobile">
                        <span className="table__cell-inner">
                          <button
                            style={{ cursor: "auto" }}
                            type="button"
                            className={`btn btn--${
                              donation.status === "RECEIVED" ? "valid" : donation.status === "CANCELED" ? "inactif" : donation.status === "IN_PROGRESS" ? "inprogress" : donation.status === "APPROVED" ? "valid" : ""
                            } btn--with-icon patient-list-container__table-btn`}
                          >
                            <span
                              className={`btn__icon btn__icon--${
                                donation.status === "RECEIVED"
                                  ? "valid" : donation.status === "CANCELED" ? "inactif" : donation.status === "IN_PROGRESS" ? "inprogress" : donation.status === "APPROVED" ? "valid" : ""
                              }`}
                            />
                            {donation.status === "RECEIVED"
                              ? "Reçue" : donation.status === "CANCELED" ? "Rejetée" : donation.status === "IN_PROGRESS" ? "En attente" : donation.status === "APPROVED" ? "Validée" : ""}
                          </button>
                        </span>
                      </td>
                      
                      <td className="table__cell table__cell--one-line table__cell--mobile ">
                        <span className="table__cell-inner">
                          {donation.accountNumber || "Néant"}
                        </span>
                      </td>
                      
                      <td className="table__cell table__cell--mobile ">
                        <span className="table__cell-inner">
                          <span className="table__cellspan table__cellspan--bold">
                            { (new Date(donation.date)).toLocaleDateString('fr-FR', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) }
                          </span>
                        </span>
                      </td>
                      
                      <td className="table__cell table__cell--mobile ">
                        <span className="table__cell-inner">
                          <span className="table__cellspan table__cellspan--bold">
                            { (new Date(donation.createdAt)).toLocaleString('fr-FR', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }) }
                          </span>
                          <span className="table__cellspan table__cellspan--light">
                            Créé par :{" "}
                            {donation.createdBy === user._id
                              ? "Moi"
                              : donation.createdBy?.firstName + ' ' + donation.createdBy?.lastName}
                          </span>
                        </span>
                      </td>
                      
                      <td className="table__cell table__cell--mobile table__cell--align-right">
                      
                      </td>
                      
                      <div className="patient-list-container__cell-header--mobile">
                        <div className="patient-list-container__cell-wrapper">
                          <td className="table__cell table__cell--mobile ">
                            <span className="table__cell-inner">
                              {donation.transactionMethod}
                            </span>
                          </td>
                          <td className="table__cell table__cell--mobile table__cell--without-tablet ">
                            <span className="table__cell-inner">
                              { (new Date(donation.createdAt)).toLocaleString('fr-FR', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }) } &nbsp;:&nbsp;
                              { (new Date(donation.date)).toLocaleDateString('fr-FR', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) }
                            </span>
                          </td>
                        </div>
                        { formatNumber(donation.amount) }
                      </div>
                    </tr>
                  ))
              ) : (
                <tr className="table__row  table__row--basic">
                  <td
                    className="table__cell table__cell--basic "
                    colSpan="7"
                  >
                    <span className="table__cell-inner">
                      <div className="empty-state-with-action">
                        <span className="empty-state-with-action__img" />
                        <p className="empty-state-with-action__text">
                          Aucune donation enrégistrée.
                        </p>
                        <button type="button" className="btn btn--blue" onClick={
                          user.role === "donor"
                            ? () => history.push(PATHS.makeDonation)
                            : () => history.push(`${ PATHS.addDonation }?donor=${ donor._id }`)
                        }>
                          {user.role === "donor"
                            ? "Faire un don"
                            : "Ajouter une donation"}
                        </button>
                      </div>
                    </span>
                  </td>
                </tr>
              )
            ) : (
              <tr className="table__row  table__row--basic">
                <div>
                  <Loader full />
                </div>
              </tr>
            )}
            </tbody>
          </table>
        </div>
      </div>
      
      {filteredDonations?.length && (
        <div className="buttons-container buttons-container--end">
          <Pagination
            length={filteredDonations.length}
            perPage={perPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        </div>
      )}
  
      <ModalContainer>
        {modalOpen && selectedDonation && (
          <Modal
            // modalOpen={modalOpen}
            text={modalType}
            type={modalType}
            handleClose={close}
            selectedDonation={selectedDonation}
            showNotification={showNotification}
            askForConfirmation={undefined}
          />
        )}
      </ModalContainer>
    </>
  )
}
