import React, { useContext } from "react"
import { PAYMENT_MODE } from "../../../../bv"
import SelectInput from "../../cards/SelectInput"
import DayInput from "../../cards/DayInput"
import NoteInput from "../../cards/NoteInput"
import { DonationContext } from "../addDonationModal"

export default function DonationStep() {
  const {
    setCurrentStep,
    selectedDonor,
    selectedTransactionMode,
    setSelectedTransactionMode,
    accountNumber,
    setAccountNumber,
    amount,
    setAmount,
    date,
    setDate,
    description,
    setDescription,
    showNoteInput,
    setShowNoteInput,
  } = useContext(DonationContext)

  const submitHandler = () => {
    setCurrentStep(3)
  }

  return (
    <div className="step">
      <div className="step__content step__content--maxwidth">
        <div className="double-step">
          <div className="double-step__part">
            <div className="step">
              <h3 className="step__title step__title--left">
                2. Informations concernant la donation de Mr/Mme&nbsp;
                {selectedDonor && selectedDonor.firstName}{" "}
                {selectedDonor && selectedDonor.lastName}
              </h3>

              <div className="step__content">
                <div className="form-group double-step__section">
                  <label className="form-group__label">
                    Methode de transaction
                  </label>
                  <div className="form-group form-group--1 ">
                    <div
                      className="select-container select-container--simple "
                      tabIndex="-1"
                    >
                      <SelectInput
                        setValue={setSelectedTransactionMode}
                        value={selectedTransactionMode}
                        options={PAYMENT_MODE}
                      />
                    </div>
                  </div>

                  <label className="form-group__label">
                    Numéro de compte
                    <span className="form-group__label-optional">
                      (optionnel)
                    </span>
                  </label>
                  <div className="form-group form-group--1">
                    <input
                      type="text"
                      value={accountNumber}
                      onChange={(event) => setAccountNumber(event.target.value)}
                      className="input input-- form-control"
                    />
                  </div>

                  <label className="form-group__label">
                    Montant reçu (XOF)
                  </label>
                  <div className="form-group form-group--1">
                    <input
                      type="text"
                      value={amount}
                      onChange={(event) => setAmount(event.target.value)}
                      className="input input-- form-control"
                    />
                  </div>
                </div>

                <div className="form-group double-step__section">
                  <div className="form-group form-group--1 form-group-- ">
                    <label className="form-group__label">Date</label>
                    <DayInput selectedDay={date} setSelectedDay={setDate} />
                  </div>
                </div>

                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn--iconside btn--with-icon"
                    onClick={() => setShowNoteInput(!showNoteInput)}
                  >
                    <span
                      className={`btn__icon btn__icon--${
                        showNoteInput ? "minus" : "plus"
                      }`}
                    />
                    Ajouter une note
                  </button>
                </div>
                {showNoteInput ? (
                  <NoteInput
                    label="Note, description"
                    value={description}
                    setValue={setDescription}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="step__footer step__footer--relative">
        <button
          type="button"
          className="btn btn--border  step__footer-btn"
          onClick={() => setCurrentStep(1)}
        >
          Précédant
        </button>
        <button
          type="button"
          className="btn btn--blue  step__footer-btn"
          onClick={submitHandler}
        >
          Aller au resumé
        </button>
      </div>
    </div>
  )
}
