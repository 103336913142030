import React, { useState, useEffect, useContext } from 'react'
import PhoneInput from "../../../components/cards/PhoneInput"
import UserService from '../../../../services/user.service'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { SessionContext } from '../../../../App'
import { RESPONSES } from '../../../../http-common'

export default function PersonalInfo({ showNotification, setIsSubmitting, donor, setDonor }) {
  const { user } = useContext(SessionContext)
  const { params } = useRouteMatch()
  const id = params.id
  
  const [showUpdate, setShowUpdate] = useState(false)
  const [showArchiveConfirmationModal, setShowArchiveConfirmationModal] = useState(false)
  const [showPromotionConfirmationModal, setShowPromotionConfirmationModal] = useState(false)
  const [isRestoring, setIsRestoring] = useState(false)
  const [isSendingInvitation, setIsSendingInvitation] = useState(false)
  
  const [firstName, setFirstName] = useState(donor ? donor.firstName : "")
  const [lastName, setLastName] = useState(donor ? donor.lastName : "")
  const [email, setEmail] = useState(donor ? donor.email : "")
  const [telephone, setTelephone] = useState(donor ? donor.telephone.value : "")
  const [isChecked, /* setIsChecked */] = useState(donor ? donor.isActive : "")
  const [callingCode, setCallingCode] = useState("+228")
  
  const history = useHistory()
  
  const submitHandler = () => {
    const data = {
      firstName,
      lastName,
      email,
      telephone: { value: telephone, callingCode },
      isActive: isChecked,
    }
    setIsSubmitting(true)
    UserService.updateDonor(id, data)
      .then((response) => {
        setIsSubmitting(false)
        if (response.status === 200) {
          location.replace(`/donor/${id}`)
          setShowUpdate(false)
          showNotification("success", response.data.message ? response.data.message : "Success")
        }
      })
      .catch((e) => {
        console.log(e)
        setIsSubmitting(false)
        showNotification("error", e.response?.data?.message ?? RESPONSES[500].message)
      })
  }
  
  const confirmArchive = () => {
    UserService.archiveOne(donor._id)
      .then(response => {
        if (response.status === 200) {
          setShowArchiveConfirmationModal(false)
          showNotification("success", response.data.message ? response.data.message : "Success")
        }
      })
      .catch((e) => {
        console.log(e)
        showNotification("error", e.message ? e.message : "Error")
      })
  }
  
  const confirmPromotion = () => {
    UserService.promoteOne(donor._id)
      .then(response => {
        if (response.status === 200) {
          setShowPromotionConfirmationModal(false)
          showNotification("success", response.data.message ? response.data.message : "Success")
          setTimeout(() => history.push(`/receptor/${donor._id}`), 500)
        }
      })
      .catch((e) => {
        console.log(e)
        showNotification("error", e.message ? e.message : "Error")
      })
  }
  
  const restoreAccount = () => {
    UserService.restoreOne(donor._id)
      .then((response) => {
        if (response.status === 200) {
          setDonor(response.data.data)
          setIsRestoring(false)
          showNotification(
            "success",
            response.data.message ? response.data.message : "Success"
          )
        }
      })
      .catch((e) => {
        console.log(e)
        showNotification("error", e.message ? e.message : "Error")
      })
  }
  
  const sendInvitation = () => {
    setIsSendingInvitation(true)
    UserService.sendInvitation(donor._id)
      .then((response) => {
        setIsSendingInvitation(false)
        showNotification("success", response.data.message ? response.data.message : "Success")
      })
      .catch((e) => {
        console.log(e)
        setIsSendingInvitation(false)
        showNotification("error", e.response ? e.response.data.message : "Error")
      })
  }

  useEffect(() => {}, [])

  return (
    <div className="patient-info-container">
      <div className="panel panel--multi">
        {
          showUpdate ? (
            <div>
              <div className="panel__header panel__header--border  panel__header--wrap">
                <h3 className="panel__title">Données personnelles du donateur</h3>
                <div className="buttons-container buttons-container--row">
                  <button type="button" className="btn btn--lightblue" onClick={ () => setShowUpdate(false) }>
                    Annuler
                  </button>
                  
                  <button type="button" className="btn btn--blue" onClick={ () => submitHandler() }>
                    Enregistrer
                  </button>
                </div>
              </div>
              
              <div className="panel__body patient-edit-panel">
                <div className="loader-component" />
                <form className="form patient-form">
                  <h2 className="form__title">Données personnelles</h2>
                  
                  <div className="form-group form-group--2">
                    <div className="select-form-label">
                      <label className="form-group__label">Nom</label>
                    </div>
                    <input
                      type="text" className="input input-- form-control"
                      value={lastName} onChange={ e => setLastName(e.target.value) }
                    />
                  </div>
                  
                  <div className="form-group form-group--2">
                    <div className="select-form-label">
                      <label className="form-group__label">Prénom</label>
                    </div>
                    <input
                      type="text" className="input input-- form-control"
                      value={firstName} onChange={ e => setFirstName(e.target.value) }
                    />
                  </div>
                  
                  <div className="form-group form-group--2 phone">
                    <PhoneInput
                      label={"Téléphone"}
                      callingCode={callingCode}
                      setCallingCode={setCallingCode}
                      value={telephone}
                      setValue={setTelephone}
                    />
                  </div>
                  
                  <div className="form-group form-group--2">
                    <div className="select-form-label">
                      <label className="form-group__label">
                        E-mail
                        <span className="form-group__label-optional">(optionnel)</span>
                      </label>
                    </div>
                    <input
                      type="text" className="input input-- form-control"
                      value={email} onChange={ e => setEmail(e.target.value) }
                    />
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div>
              <div className="panel__header panel__header--border  panel__header--wrap">
                <h3 className="panel__title">Données des donateurs</h3>
                <div className="buttons-container buttons-container--row">
                  {
                    !donor.isActive ? (
                      <button
                        type="button" className="btn btn--border btn--with-icon"
                        onClick={() => setShowUpdate(true)}
                      >
                        <span className="btn__icon btn__icon--edit "/>
                        Modifier les données
                      </button>
                    ) : ""
                  }
                </div>
              </div>
              
              <div className="panel__body ">
                <div className="tables-container">
                  <table className="table table--minimal">
                    <caption className="table__title">Données personnelles</caption>
                    
                    <tbody>
                    <tr className="table__row  table__row--basic ">
                      <td className="table__cell table__cell--basic ">
                        <span className="table__cell-inner">
                          Nom et Prénoms
                        </span>
                      </td>
                      <td className="table__cell table__cell--basic ">
                        <span className="table__cell-inner">
                          {donor &&
                            `${donor.firstName} ${donor.lastName}`}
                        </span>
                      </td>
                    </tr>
                    
                    <tr className="table__row  table__row--basic ">
                      <td className="table__cell table__cell--basic ">
                        <span className="table__cell-inner">
                          E-mail
                        </span>
                      </td>
                      <td className="table__cell table__cell--basic ">
                        <span className="table__cell-inner">
                          {donor && donor.email ? donor.email : "Néant"}
                        </span>
                      </td>
                    </tr>
                    
                    <tr className="table__row  table__row--basic ">
                      <td className="table__cell table__cell--basic ">
                        <span className="table__cell-inner">
                          Téléphone
                        </span>
                      </td>
                      <td className="table__cell table__cell--basic ">
                        <span className="table__cell-inner">
                          {donor &&
                            `${donor.telephone.callingCode} ${donor.telephone.value}`}
                        </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  
                  <table className="table table--minimal">
                    <caption className="table__title">Compte</caption>
                    <tbody>
                    <tr className="table__row  table__row--basic">
                      <td className="table__cell table__cell--basic">
                        <span className="table__cell-inner">Status</span>
                      </td>
                      <td className="table__cell table__cell--basic">
                        <span className="table__cell-inner">
                          {donor && donor.isActive ? (
                            <span style={{ color: "#14ce8c" }}>Compte Actif</span>
                          ) : (
                            <>
                              <span style={{ color: "#ff505a", flex: '1' }}>Compte Inactif</span><br/><br/>
  
                              {
                                user.role === 'admin' ? (
                                  <button
                                    type="button" className={`btn btn--lightblue ${ isSendingInvitation ? 'btn--loader' : '' } patient-list-container__table-btn`}
                                    onClick={ !isSendingInvitation ? sendInvitation : undefined }
                                  >
                                    Envoyer une invitation
                                  </button>
                                ) : ''
                              }
                            </>
                          )}
                        </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )
        }
      </div>
  
      {
        user.role?.name === 'admin' ? (
          <div className="panel panel--multi">
            <div className="panel__header panel__header--border  panel__header--wrap">
              <h3 className="panel__title ">
                {donor.isArchived ? "Restorer" : "Archiver"} le compte du donateur
              </h3>
            </div>
    
            <div className="panel__body ">
              <div className="disable-patient-content">
                <p className="disable-patient-content__title">
                  Si vous ne voulez plus prendre en compte ce donateur,
                  vous pouvez archiver ses données.
                  <br />
                  Le compte est masqué par défaut dans le régistre des
                  donateurs, et vous n&apos;y aurez accès qu&apos;aprons
                  avoir filtré les comptes archivés.
                </p>
                <button
                  onClick={
                    donor.isArchived
                      ? () => restoreAccount()
                      : () => setShowArchiveConfirmationModal(true)
                  }
                  type="button"
                  className={`btn btn--border ${
                    donor.isArchived
                      ? "btn--lightgreen " + (isRestoring ? "btn--loader" : "")
                      : "btn--warning"
                  }`}
                >
                  { donor.isArchived ? "Restorer" : "Archiver" } le donateur
                </button>
              </div>
            </div>
          </div>
        ) : ''
      }
      
      {
        donor.isActive && donor.role.name === 'donor' ? (
          <div className="panel panel--multi">
            <div className="panel__header panel__header--border  panel__header--wrap">
              <h3 className="panel__title ">Promouvoir le compte ce donateur</h3>
            </div>
            
            <div className="panel__body ">
              <div className="disable-patient-content">
                <p className="disable-patient-content__title">
                  Ce compte donateur sera promu en un compte recepteur,
                  vous pouvez archiver ses données.
                  <br />
                  Vous ne pourrez plus retourner en arrière
                </p>
                <button
                  onClick={ () => setShowPromotionConfirmationModal(true) }
                  type="button"
                  className={`btn btn--border ${
                    donor.isArchived
                      ? "btn--lightgreen " + (isRestoring ? "btn--loader" : "")
                      : "btn--blue"
                  }`}
                >
                  Promouvoir
                </button>
              </div>
            </div>
          </div>
        ) : ''
      }
  
      {
        showArchiveConfirmationModal ? (
          <div className="fullpage">
            <div className="modal modal--">
              <div className="panel modal__content">
                <div className="modal__header">
                  <h2 className="modal__title">Confirmation</h2>
                  <button
                    onClick={() => setShowArchiveConfirmationModal(false)}
                    type="button"
                    className="btn  btn--with-icon-only  btn--with-icon-close"
                  >
                    <span className="btn__icon btn__icon--close btn__icon--only" />
                  </button>
                </div>
            
                <div className="modal__content--inner">
                  <div className="modal__main">
                    <h3>Voulez-vous vraiment archiver ce donateur ?</h3>
                    <p>Notez que toute fois vous pourrez revenir sur votre décision.</p>
                  </div>
                </div>
            
                <div className="modal__footer">
                  <button
                    type="button" className="btn btn--red"
                    onClick={ () => confirmArchive() }
                  >
                    Archiver
                  </button>
                  <button
                    type="button" className="btn btn--border"
                    onClick={ () => setShowArchiveConfirmationModal(false) }
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : ""
      }
  
      {
        showPromotionConfirmationModal ? (
          <div className="fullpage">
            <div className="modal modal--">
              <div className="panel modal__content">
                <div className="modal__header">
                  <h2 className="modal__title">Confirmation</h2>
                  <button
                    onClick={() => setShowPromotionConfirmationModal(false)}
                    type="button"
                    className="btn  btn--with-icon-only  btn--with-icon-close"
                  >
                    <span className="btn__icon btn__icon--close btn__icon--only" />
                  </button>
                </div>
            
                <div className="modal__content--inner">
                  <div className="modal__main">
                    <h3>Voulez-vous vraiment promouvoir ce donateur ?</h3>
                    <p>Notez que cette manœuvre est irréversible.</p>
                  </div>
                </div>
            
                <div className="modal__footer">
                  <button type="button" className="btn btn--warning" onClick={ () => confirmPromotion() }>
                    Promouvoir
                  </button>
                  <button
                    type="button" className="btn btn--border"
                    onClick={ () => setShowPromotionConfirmationModal(false) }
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : ""
      }
    </div>
  )
}
