import React, { useContext, useState } from 'react'
import { useHistory } from "react-router-dom"
import DonorStep from "./steps/DonorStep"
import DonationStep from "./steps/DonationStep"
import ResumeStep from "./steps/ResumeStep"
import { SessionContext } from '../../../App'
import { PATHS } from '../../../bv'

export const DonationContext = React.createContext(null)

export default function AddDonationModal({ setShowModal, showNotification }) {
  const [currentStep, setCurrentStep] = useState(1)
  const [selectedDonor, setSelectedDonor] = useState(null)
  
  const [selectedTransactionMode, setSelectedTransactionMode] = useState(null)
  const [accountNumber, setAccountNumber] = useState("")
  const [amount, setAmount] = useState("")
  const [date, setDate] = useState(new Date())
  const [description, setDescription] = useState("")
  const [showNoteInput, setShowNoteInput] = useState(false)
  
  const history = useHistory()
  const { user } = useContext(SessionContext)
  if (user.role === 'donor')
    location.replace(PATHS.makeDonation)
  
  
  return (
    <div className="fullpage fullpage--clickable multi-add-visit-modal">
      <div className="steps-modal">
        <div className="steps-modal__header">
          <div className="steps-modal__logo" />
          <div className="stepper">
            <div
              className={`stepper__step stepper__line ${
                currentStep >= 1 ? "stepper__step--finished" : ""
              }`}
            >
              <div
                className={`stepper__indicator ${
                  currentStep === 1 ? "stepper__indicator--current" : ""
                }`}
              />
              <p
                className={`stepper__name ${
                  currentStep !== 1 ? "stepper__name--disabled" : ""
                }`}
              >
                Donateur
              </p>
            </div>
            
            <div
              className={`stepper__step stepper__line ${
                currentStep >= 2 ? "stepper__step--finished" : ""
              }`}
            >
              <div
                className={`stepper__indicator ${
                  currentStep === 2 ? "stepper__indicator--current" : ""
                }`}
              />
              <p
                className={`stepper__name ${
                  currentStep !== 2 ? "stepper__name--disabled" : ""
                }`}
              >
                Donation
              </p>
            </div>
            
            <div
              className={`stepper__step stepper__line ${
                currentStep >= 3 ? "stepper__step--finished" : ""
              }`}
            >
              <div
                className={`stepper__indicator ${
                  currentStep === 3 ? "stepper__indicator--current" : ""
                }`}
              />
              <p
                className={`stepper__name ${
                  currentStep !== 3 ? "stepper__name--disabled" : ""
                }`}
              >
                Sommaire
              </p>
            </div>
          </div>
          
          <div className="steps-modal__close">
            <p className="steps-modal__mobile-description">Ajouter une donation </p>
            <button
              onClick={() => {
                setShowModal(false)
                document.body.classList.remove("with-modal")
                history.goBack()
              }}
              type="button"  className="btn btn--with-icon-only"
            >
              <span className="btn__icon btn__icon--close-grey btn__icon--only" />
            </button>
          </div>
        </div>
        
        <DonationContext.Provider
          value={{
            setCurrentStep,
            selectedDonor,
            setSelectedDonor,
            selectedTransactionMode,
            setSelectedTransactionMode,
            accountNumber,
            setAccountNumber,
            amount,
            setAmount,
            date,
            setDate,
            description,
            setDescription,
            showNoteInput,
            setShowModal,
            setShowNoteInput,
            showNotification,
          }}
        >
          <div className="steps-modal__step">
            {currentStep === 1 ? (
              <DonorStep />
            ) : currentStep === 2 ? (
              <DonationStep />
            ) : currentStep === 3 ? (
              <ResumeStep />
            ) : (
              ""
            )}
          </div>
        </DonationContext.Provider>
      </div>
    </div>
  )
}
