import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import UserService from "../../../services/user.service"
import { filteredData, requestToLogin } from '../../../bv'
import PhoneInput from "../cards/PhoneInput"
import Pagination from "../cards/Pagination"
import Loader from '../cards/Loader'
import { TimeFrame } from '../reporting/Reporting'
import DayInput from '../cards/DayInput'
import { RESPONSES } from '../../../http-common'

export default function ReceptorList({ showNotification }) {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })
  
  const [filterText, setFilterText] = useState(params.search ?? "")
  const [showModal, setShowModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showArchived, setShowArchived] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [receptors, setReceptors] = useState(JSON.parse(localStorage.getItem("receptors")) || null)
  const [activeReceptor, setActiveReceptor] = useState(null)

  const [activePage, setActivePage] = useState(1)
  const [perPage, setPerPage] = useState(5)
  const [sortBy, setSortBy] = useState("-createdAt")

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [telephone, setTelephone] = useState("")
  const [isChecked, setIsChecked] = useState(false)
  const [callingCode, setCallingCode] = useState("+228")
  
  const [startDate, setStartDate] = useState(new Date(2020, 0, 1))
  const [endDate, setEndDate] = useState(new Date())

  const history = useHistory()

  let filteredReceptors
  if (receptors)
    filteredReceptors = filteredData(filterText, receptors, ["firstName", "lastName", "telephone"])
      .filter(receptor => !receptor.isArchived || (showArchived && receptor.isArchived))
  
  filteredReceptors = filteredReceptors?.filter(item => startDate <= new Date(item.createdAt) && new Date(item.createdAt) <= endDate) ?? []

  const submitHandler = () => {
    const data = {
      firstName,
      lastName,
      email,
      telephone: {callingCode, value: telephone},
      sendInvitation: isChecked,
      role: "receptor",
    }
    setIsSubmitting(true)
    UserService.create(data)
      .then(response => {
        if (response.status === 201) {
          setShowModal(false)
          setIsSubmitting(false)
          showNotification("success", response.data.message ? response.data.message : "Success")
        }
      })
      .catch((e) => {
        console.log(e)
        setIsSubmitting(false)
        showNotification("error", e.response?.data?.message ?? RESPONSES[500].message)
      })
  }

  const askForArchiveConfirmation = (receptor) => {
    setActiveReceptor(receptor)
    setShowConfirmationModal(true)
  }

  const confirmArchive = (receptor) => {
    UserService.archiveOne(receptor._id)
      .then((response) => {
        if (response.status === 200) {
          setShowConfirmationModal(false)
          showNotification("success", response.data.message ? response.data.message : "Success")
        }
      })
      .catch((e) => {
        console.log(e)
        showNotification("error", e.message ? e.message : "Error")
      })
  }

  useEffect(() => {
    setPerPage(5)

    UserService.getReceptors(sortBy)
      .then(response => {
        if (response.status === 200) {
          setReceptors(response.data)
          localStorage.setItem("receptors", JSON.stringify(response.data))
        }
      })
      .catch((e) => {
        console.log(e)
        if (e.response.status === 401) requestToLogin(history.location.pathname)
      })
  }, [showArchived, showConfirmationModal, showModal, sortBy])
  
  useEffect(() => {
    history.replace({
      pathname: history.location.pathname,
      search: `?search=${filterText}`
    })
  }, [filterText])

  return (
    <div className="page">
      <div className="page-content">
        {
          showModal ? (
            <div className="fullpage">
              { isSubmitting ? (<Loader full={ true }/>) : "" }
              <div className="modal modal--">
                <div className="panel modal__content">
                  <div className="modal__header ">
                    <h2 className="modal__title">Ajout d&apos;un recepteur</h2>
                    <button
                      type="button"
                      className="btn  btn--with-icon-only  btn--with-icon-close"
                    >
                      <span
                        className="btn__icon btn__icon--close btn__icon--only"
                        onClick={() => setShowModal(false)}
                      />
                    </button>
                  </div>
                  <div className="modal__content--inner">
                    <div className="modal__main">
                      <form className="form patient-form">
                        <div className="form-group form-group--2">
                          <div className="select-form-label">
                            <label className="form-group__label">Nom</label>
                          </div>
                          <input
                            type="text"
                            className="input input-- form-control "
                            value={firstName}
                            onChange={(event) => setFirstName(event.target.value)}
                          />
                        </div>

                        <div className="form-group form-group--2">
                          <div className="select-form-label">
                            <label className="form-group__label">Prénoms</label>
                          </div>
                          <input
                            type="text"
                            className="input input-- form-control "
                            value={lastName}
                            onChange={event => setLastName(event.target.value)}
                          />
                        </div>

                        <div className="form-group form-group--2 phone">
                          <PhoneInput
                            label={"Téléphone"}
                            callingCode={callingCode}
                            setCallingCode={setCallingCode}
                            value={telephone}
                            setValue={setTelephone}
                          />
                        </div>

                        <div className="form-group form-group--2">
                          <div className="select-form-label">
                            <label className="form-group__label">E-mail</label>
                            <span className="form-group__label-optional">
                              (optionel)
                            </span>
                          </div>
                          <input
                            type="email"
                            className="input input-- form-control "
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                          />
                        </div>

                        <span
                          className="checkbox checkbox--11ce8c"
                          onClick={() => setIsChecked(!isChecked)}
                        >
                          <input
                            className="checkbox__input"
                            type="checkbox"
                            checked={isChecked}
                          />
                          <label className="checkbox__label ">
                            Envoyer une invitation
                          </label>
                        </span>
                      </form>
                    </div>
                    <div className="modal__footer">
                      <button
                        type="button"
                        className="btn btn--border"
                        onClick={() => setShowModal(false)}
                      >
                        Annuler
                      </button>
                      <button
                        type="button"
                        className="btn btn--blue"
                        onClick={() => submitHandler()}
                      >
                        Enrégistrer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : ""
        }
        <div className="patient-list-container">
          <div className="page-heading">
            <h2 className="page-heading__title">Liste des recepteurs</h2>
          </div>

          <div className="panel">
            <div className="patient-filters">
              <div className="patient-filters__row">
                <div
                  onClick={() => setShowFilter(!showFilter)}
                  className="patient-filters__mobile patient-filters__toggle"
                >
                  <button type="button" className="btn  btn--with-icon-only">
                    <span className="btn__icon btn__icon--filters btn__icon--only" />
                  </button>
                  {showFilter ? "Masquer " : "Afficher"} le filtre
                </div>
                
                <div className="patient-filters__desktop">
                  <div className="form-group form-group--auto">
                    <input
                      onChange={event => setFilterText(event.target.value)}
                      type="text"
                      value={filterText}
                      className="input input--search form-control"
                      placeholder="Tapez nom, prénoms, téléphone du recepteur"
                    />
                  </div>
                  <button type="button" className="btn" onClick={() => setShowFilter(!showFilter)}>
                    {showFilter ? "Masquer " : "Afficher"} le filtre
                  </button>
                </div>
                
                <button type="button" className="btn btn--blue" onClick={() => setShowModal(true)}>
                  Ajouter un recepteur
                </button>
              </div>
              {showFilter ? (
                <>
                  <div className="patient-filters__row patient-filters__inputs">
                    <div className="form-group form-group--auto patient-filters__mobile ">
                      <input
                        onChange={(event) => setFilterText(event.target.value)}
                        type="text"
                        value={filterText}
                        className="input input--search form-control"
                        placeholder="Tapez nom, prénoms, téléphone du recepteur"
                      />
                    </div>
                    <div
                      className="select-container select-container--simple"
                      tabIndex="-1"
                    >
                      <input
                        onChange={undefined}
                        type="text"
                        value=""
                        className="input input--dropdown"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="patient-filters__row patient-filters__is-archived">
                    <span
                      className="checkbox"
                      onClick={() => setShowArchived(!showArchived)}
                    >
                      <input
                        className="checkbox__input"
                        type="checkbox"
                        checked={showArchived}
                      />
                      <label className="checkbox__label ">
                        Afficher archivés
                      </label>
                    </span>
                  </div>
                </>
              ) : null}
            </div>

            <div className="table-wrapper" style={{ minHeight: "510px" }}>
              <div className="d-flex" style={{justifyContent: 'space-between', alignItems: 'center', margin: "0 0 1rem 0", padding: "0 2rem 0 2rem"}}>
                <TimeFrame>
                  <button type="button" className="btn btn--blue btn--small" onClick={() => {
                    setStartDate(new Date(2020, 0, 1))
                    setEndDate(new Date())
                  }}>
                    Effacer le filtre
                  </button>
      
                  <div className="from">
                    <span>De: &nbsp;</span>
                    <DayInput
                      selectedDay={startDate}
                      setSelectedDay={ (date) => setStartDate(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)) }
                    />
                  </div>
      
                  <div className="to">
                    <span style={{textTransform: 'capitalize'}}>à: &nbsp;</span>
                    <DayInput selectedDay={endDate} setSelectedDay={ (date) => setEndDate(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)) } />
                  </div>
                </TimeFrame>
  
                <span className="pagination__indicator">
                  { ((activePage - 1) * perPage) + 1 } -
                    { activePage*perPage > filteredReceptors.length ? filteredReceptors.length : activePage*perPage } sur {' '}
                    { filteredReceptors.length }
                </span>
              </div>
              
              <table className="table table--basic">
                <thead className="table__head">
                <tr className="table__row  table__row--head ">
                  <th
                    onClick={ () => setSortBy((prevState) =>
                      prevState === "firstName lastName" ? "-firstName -lastName" : "firstName lastName")}
                    className={`table__cell table__cell--head table__cell--basic ${
                      sortBy === "firstName lastName" ?
                        "table__cell--arrow" : sortBy === "-firstName -lastName" ?
                        "table__cell--arrow table__cell--arrow-up" : ""
                    }`}
                  >
                    <span className="table__cell-inner">Nom & Prénoms</span>
                    <span className="table__cell-arrow" />
                  </th>
                  
                  <th
                    onClick={ () => setSortBy((prevState) =>
                      prevState === "telephone" ? "-telephone" : "telephone")
                    }
                    className={`table__cell table__cell--head table__cell--basic ${
                      sortBy === "telephone" ?
                        "table__cell--arrow" : sortBy === "-telephone" ?
                        "table__cell--arrow table__cell--arrow-up" : ""
                    }`}
                  >
                    <span className="table__cell-inner">Téléphone</span>
                    <span className="table__cell-arrow" />
                  </th>
                  
                  <th
                    onClick={ () => setSortBy((prevState) =>
                      prevState === "status" ? "-status" : "status")
                    }
                    className={`table__cell table__cell--head table__cell--basic ${
                      sortBy === "status" ?
                        "table__cell--arrow" : sortBy === "-status" ?
                        "table__cell--arrow table__cell--arrow-up" : ""
                    }`}
                  >
                    <span className="table__cell-inner">Status</span>
                    <span className="table__cell-arrow" />
                  </th>
                  
                  <th
                    onClick={ () => setSortBy((prevState) =>
                      prevState === "createdAt" ? "-createdAt" : "createdAt")
                    }
                    className={`table__cell table__cell--head table__cell--basic ${
                      sortBy === "createdAt" ?
                        "table__cell--arrow" : sortBy === "-createdAt" ?
                        "table__cell--arrow table__cell--arrow-up" : ""
                    }`}
                  >
                    <span className="table__cell-inner">
                      Date de création
                    </span>
                    <span className="table__cell-arrow" />
                  </th>
                  
                  <th className="table__cell table__cell--head table__cell--basic ">
                    <span className="table__cell-inner" />
                    <span className="table__cell-arrow" />
                  </th>
                  
                  <th className="table__cell table__cell--head table__cell--basic ">
                    <span className="table__cell-inner" />
                    <span className="table__cell-arrow" />
                  </th>
                  
                  <th className="table__cell table__cell--head table__cell--basic ">
                    <span className="table__cell-inner" />
                    <span className="table__cell-arrow" />
                  </th>

                </tr>
                </thead>

                <tbody>
                {filteredReceptors ? (
                  filteredReceptors.length ? (
                    filteredReceptors
                      .slice((activePage - 1) * perPage, activePage * perPage)
                      .map((receptor, i) => (
                        <tr
                          key={i} className="table__row  table__row--basic"
                          style={{ backgroundColor: receptor.isArchived ? "antiquewhite" : "" }}
                        >
                          <td className="table__cell table__cell--clickable table__cell--mobile table__cell--bold" onClick={() => history.push(`/receptor/${receptor._id}`)}>
                            <span className="table__cell-inner">
                              <span className="userimage  employees__userimage" />
                              <span
                                style={{
                                  textTransform: 'capitalize',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '200px',
                                  overflow: 'hidden'
                                }}
                                title={receptor.firstName + receptor.lastName}
                              >
                                <span style={{ textTransform: "capitalize" }}>{receptor.lastName}</span>&nbsp;
                                <span style={{ textTransform: "uppercase" }}>{receptor.firstName}</span>
                              </span>
                              
                            </span>
                          </td>

                          <td className="table__cell table__cell--one-line table__cell--mobile ">
                            <span className="table__cell-inner">
                              { receptor.telephone.callingCode } { receptor.telephone.value }
                            </span>
                          </td>

                          <td className="table__cell table__cell--mobile">
                            <span className="table__cell-inner">
                              <button
                                style={{ cursor: "auto" }}
                                type="button"
                                className={`btn btn--${
                                  receptor.isActive ? "valid" : "inactif"
                                } btn--with-icon patient-list-container__table-btn`}
                              >
                                <span className={`btn__icon btn__icon--${receptor.isActive ? "valid" : "inactif"}`} />
                                {receptor.isActive ? "Actif" : "Inactif"}
                              </button>
                            </span>
                          </td>

                          <td className="table__cell table__cell--mobile ">
                            <span className="table__cell-inner">
                              <span className="table__cellspan table__cellspan--bold">
                                { (new Date(receptor.createdAt)).toLocaleDateString('fr-FR', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) }
                              </span>
                              <span className="table__cellspan table__cellspan--light" style={{
                                textTransform: 'capitalize',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                maxWidth: '150px',
                                overflow: 'hidden'
                              }}>
                                Créé par : { receptor.createdBy ? receptor.createdBy.firstName + ' ' + receptor.createdBy.lastName : '' }
                              </span>
                            </span>
                          </td>

                          <td className="table__cell table__cell--center ">
                            {
                              !receptor.isArchived ? (
                                <span className="table__cell-inner">
                                <button
                                  type="button"
                                  className="btn  btn--with-icon-only"
                                  onClick={() =>
                                    askForArchiveConfirmation(receptor)
                                  }
                                >
                                  <span className="btn__icon btn__icon--delete btn__icon--only" />
                                  <span className="tooltip btn__tooltip">
                                    Archiver
                                  </span>
                                </button>
                              </span>
                              ) : ""
                            }
                          </td>

                          <td className="table__cell table__cell--buttons-container table__cell--one-line table__cell--hovered table__cell--mobile">
                            <span className="table__cell-inner" />
                          </td>

                          <td className="table__cell table__cell--button table__cell--mobile table__cell--align-right ">
                            <span className="table__cell-inner">
                              <button
                                onClick={() =>
                                  history.push(`/receptor/${receptor._id}`)
                                }
                                type="button"
                                className="btn btn--lightblue  patient-list-container__table-btn"
                              >
                                Détails
                              </button>
                            </span>
                          </td>

                          <div className="patient-list-container__cell-header--mobile">
                            <div className="patient-list-container__cell-wrapper">
                              <td className="table__cell table__cell--mobile ">
                                <span className="table__cell-inner">
                                  <span style={{ textTransform: "capitalize" }}>
                                    {receptor.lastName}
                                  </span>
                                  &nbsp;
                                  <span style={{ textTransform: "uppercase" }}>
                                    {receptor.firstName}
                                  </span>
                                </span>
                              </td>
                              <td className="table__cell table__cell--mobile table__cell--without-tablet ">
                                <span className="table__cell-inner">
                                  { receptor.telephone.callingCode } { receptor.telephone.value }
                                </span>
                              </td>
                            </div>
                            <button
                              type="button"
                              className="btn  btn--with-icon-only"
                            >
                              <span className="btn__icon btn__icon--collapse-blue-close btn__icon--only" />
                            </button>
                          </div>
                        </tr>
                      ))
                  ) : (
                    <tr className="table__row  table__row--basic">
                      <td
                        className="table__cell table__cell--basic "
                        colSpan="7"
                      >
                        <span className="table__cell-inner">
                          <div className="empty-state-with-action">
                            <span className="empty-state-with-action__img" />
                            <p className="empty-state-with-action__text">
                              Aucun recepteur enrégistré.
                            </p>
                            <button
                              type="button"
                              className="btn btn--blue"
                              onClick={() => setShowModal(true)}
                            >
                              Ajouter un recepteur
                            </button>
                          </div>
                        </span>
                      </td>
                    </tr>
                  )
                ) : (
                  <tr className="table__row  table__row--basic">
                    <div>
                      <Loader full={ true }/>
                    </div>
                  </tr>
                )}
                </tbody>
              </table>
            </div>
          </div>

          {showConfirmationModal && activeReceptor ? (
            <div className="fullpage">
              <div className="modal modal--">
                <div className="panel modal__content">
                  <div className="modal__header">
                    <h2 className="modal__title">Confirmation</h2>
                    <button
                      onClick={() => setShowConfirmationModal(false)}
                      type="button"
                      className="btn  btn--with-icon-only  btn--with-icon-close"
                    >
                      <span className="btn__icon btn__icon--close btn__icon--only" />
                    </button>
                  </div>

                  <div className="modal__content--inner">
                    <div className="modal__main">
                      <h3>
                        Voulez-vous vraiment archiver ce recepteur (
                        {activeReceptor.firstName} {activeReceptor.lastName}) ?
                      </h3>
                      <p>
                        Notez que toute fois vous pourrez revenir sur votre
                        décision.
                      </p>
                    </div>
                  </div>

                  <div className="modal__footer">
                    <button
                      type="button"
                      className="btn btn--red"
                      onClick={() => confirmArchive(activeReceptor)}
                    >
                      Archiver
                    </button>
                    <button
                      type="button"
                      className="btn btn--border"
                      onClick={() => setShowConfirmationModal(false)}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {filteredReceptors && filteredReceptors.length && (
            <div className="buttons-container buttons-container--end">
              <Pagination
                length={filteredReceptors.length}
                perPage={perPage}
                activePage={activePage}
                setActivePage={setActivePage}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
