import React, { useContext } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  withRouter,
} from "react-router-dom"
import Nav from "./layouts/nav"
import SignUp from "./pages/SignUp"
import SignIn from "./pages/SignIn"
import RecoverPassword from "./pages/RecoverPassword"
import { PATHS } from "../bv"
import ScrollToTop from "./components/cards/ScrollToTop"
import { SessionContext } from "../App"
import ErrorBoundary from "./components/cards/ErrorBoundary"
import Dashboard from "./pages/Dashboard"
import Profil from "./pages/profile"
import ReceptorList from "./components/receptor/ReceptorList"
import ReceptorProfil from "./components/receptor/ReceptorProfil"
import DonorList from "./components/donor/DonorList"
import DonorProfil from "./components/donor/profile"
import DonationList from "./components/donation/DonationList"
import Reporting from "./components/reporting/Reporting"
import MakeDonation from './components/donation/MakeDonation'
import Maintenance from './components/cards/Maintenance'

function Markup() {
  const { user, currentPath } = useContext(SessionContext)

  const routes = [
    { url: PATHS.home, component: Dashboard },
    { url: PATHS.dashboard, component: Dashboard },
    // { url: PATHS.recoverPassword, component: RecoverPassword },

    { url: PATHS.reporting, component: Reporting },

    { url: PATHS.donationList, component: DonationList },

    { url: PATHS.addDonation, component: DonationList },

    { url: PATHS.makeDonation, component: MakeDonation },

    { url: PATHS.receptorList, component: ReceptorList },

    { url: PATHS.receptorProfil, component: ReceptorProfil },

    { url: PATHS.donorList, component: DonorList },
    { url: PATHS.donorProfil, component: DonorProfil },

    { url: PATHS.profil, component: Profil },
  ]

  const pagePaths = [PATHS.signin, PATHS.signup, PATHS.acceptInvite, PATHS.recoverPassword]
  const isPage = pagePaths.includes(currentPath)
  const maintenance = false;

  return (
    <Router basename={"/"}>
      { maintenance ? (
        <Maintenance />
      ) : (
        <div className="app">
          {!isPage && <Nav />}
          <>
            <ScrollToTop />
            <Switch>
              <Route path={PATHS.signup}>
                { React.createElement(ErrorBoundary, {}, SignUp) }
              </Route>
              <Route path={PATHS.signin}>
                { React.createElement(ErrorBoundary, {}, SignIn) }
              </Route>
              <Route path={PATHS.recoverPassword}>
                { React.createElement(ErrorBoundary, {}, RecoverPassword) }
              </Route>
              <Route path={PATHS.acceptInvite}>
                { React.createElement(ErrorBoundary, {}, SignUp) }
              </Route>
              <Route path={PATHS.makeDonation}>
                { React.createElement(ErrorBoundary, { user }, MakeDonation) }
              </Route>
              
              {routes.map((data, i) => (
                <Route key={i} exact path={data.url}>
                  {!user ? (
                    <Redirect to={PATHS.signin} />
                  ) : (
                    withRouter((props) =>
                      React.createElement(ErrorBoundary, { ...props }, data.component)
                    )
                  )}
                </Route>
              ))}
            </Switch>
          </>
        </div>
      ) }
    </Router>
  )
}

export default Markup
