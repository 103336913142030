import React from "react"

export default function Welcome() {
  const year = new Date().getFullYear()
  return (
    <div className="auth__image">
      <div className="auth__logo-wrapper">
        <div className="auth__logo_" />
      </div>
      <div className="auth__title">
        <h3>Bienvenu(e)</h3>
      </div>
      <div className="auth__desc">
        <p>
          Faites vos dons et promesses, <br /> suivez l&apos; évolution de votre
          compte !
        </p>
      </div>
      <div className="auth__footer">
        <p>© Vaillants Batisseurs+ { year }</p>
      </div>
    </div>
  )
}
