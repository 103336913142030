import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import React, { useState, useEffect } from "react"
import UserService from "../../../services/user.service"
import PhoneInput from "../cards/PhoneInput"
import { PATHS, requestToLogin } from '../../../bv'
import Loader from "../cards/Loader"
import { RESPONSES } from '../../../http-common'

export default function ReceptorProfil({ showNotification }) {
  const { params } = useRouteMatch()
  const id = params.id

  const buttonList = [
    "Données des récepteurs",
    "Promesse",
    "Donations",
    "Plaintes",
  ]

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [isRestoring, setIsRestoring] = useState(false)
  const [isSendingInvitation, setIsSendingInvitation] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)
  const [btnSelected, setBtnSelected] = useState(buttonList[0])

  const [receptor, setReceptor] = useState(null)
  // const [telephoneIsSelected, setTelephoneIsSelected] = useState(false)

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [telephone, setTelephone] = useState("")
  const [isChecked, setIsChecked] = useState("")
  const [callingCode, setCallingCode] = useState("+228")
  
  const history = useHistory()

  const submitHandler = () => {
    const data = {
      firstName,
      lastName,
      email,
      telephone: { value: telephone, callingCode },
      isActive: isChecked,
    }

    // setIsSubmitting(true)
    UserService.updateReceptor(id, data)
      .then((response) => {
        // setIsSubmitting(false)
        if (response.status === 200) {
          location.replace(`/receptor/${id}`)
          setShowUpdate(false)
        }
      })
      .catch((e) => {
        // setIsSubmitting(false)
        console.log(e)
        showNotification("error", e.response?.data?.message ?? RESPONSES[500].message)
      })
  }

  const confirmArchive = () => {
    UserService.archiveOne(receptor._id)
      .then((response) => {
        if (response.status === 200) {
          setShowConfirmationModal(false)
          showNotification(
            "success",
            response.data.message ? response.data.message : "Success"
          )
        }
      })
      .catch((e) => {
        console.log(e)
        showNotification("error", e.message ? e.message : "Error")
      })
  }

  const restoreAccount = () => {
    UserService.restoreOne(receptor._id)
      .then((response) => {
        if (response.status === 200) {
          setReceptor(response.data.data)
          setIsRestoring(false)
          showNotification(
            "success",
            response.data.message ? response.data.message : "Success"
          )
        }
      })
      .catch((e) => {
        console.log(e)
        showNotification("error", e.message ? e.message : "Error")
      })
  }
  
  const sendInvitation = () => {
    setIsSendingInvitation(true)
    UserService.sendInvitation(receptor._id)
      .then((response) => {
        setIsSendingInvitation(false)
        showNotification("success", response.data.message ? response.data.message : "Success")
      })
      .catch((e) => {
        console.log(e)
        setIsSendingInvitation(false)
        showNotification("error", e.response ? e.response.data.message : "Error")
      })
  }

  useEffect(() => {
    UserService.getOneReceptor(id)
      .then((response) => {
        if (response.status === 200) {
          setReceptor(response.data)
          setFirstName(response.data.firstName)
          setLastName(response.data.lastName)
          setEmail(response.data.email)
          setTelephone(response.data.telephone)
          setIsChecked(response.data.isActive)
        }
      })
      .catch((e) => {
        console.log(e)
        if (e.response.status === 401) requestToLogin(history.location.pathname)
      })
  }, [])

  return (
    <div className="page">
      <div className="page-content">
        {receptor ? (
          <div className="patient-view-container">
            <div className="patient-view-container__side">
              <a className="navlink-btn">
                <Link to={PATHS.receptorList}>
                  <button
                    type="button"
                    className="btn btn--back btn--with-icon"
                  >
                    <span className="btn__icon btn__icon--back " />
                    Liste des récepteurs
                  </button>
                </Link>
              </a>

              <div className="visit-info">
                <div className="page-heading">
                  <h2 className="page-heading__title">Profil du récepteur</h2>
                </div>
                <div className="visit-info__items-wrapper">
                  <div className="icon-text visit-info__icon-text">
                    <div className="icon-text__icon icon-text__icon--patient" />
                    <div className="icon-text__title-wrapper">
                      <p className="icon-text__title">
                        {receptor && `${receptor.firstName} ${receptor.lastName}`}
                      </p>
                      <p className="icon-text__subtitle">
                        Tel &nbsp;:&nbsp;{receptor && receptor.telephone.value}
                      </p>
                    </div>
                  </div>
                  {/*
                  <button type="button" className="btn btn--blue btn--inline">
                    Ajouter une donation
                  </button> */}
                </div>
              </div>
            </div>

            <div className="patient-view-container__main">
              <div className="panel panel--multi">
                <div className="tabs">
                  {buttonList.map((button, index) => (
                    <div className="tabs__tab" key={index}>
                      <button
                        type="button"
                        className={`btn ${
                          btnSelected === button ? "btn--lightblue" : ""
                        }`}
                        onClick={() => setBtnSelected(buttonList[index])}
                      >
                        {button}
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="patient-info-container">
                <div className="panel panel--multi">
                  {showUpdate ? (
                    <div>
                      <div className="panel__header panel__header--border  panel__header--wrap">
                        <h3 className="panel__title ">Données personnelles du récepteur</h3>
                        <div className="buttons-container buttons-container--row">
                          <button
                            type="button" className="btn btn--lightblue"
                            onClick={() => setShowUpdate(false)}
                          >
                            Annuler
                          </button>
                          <button
                            type="button" className="btn btn--blue"
                            onClick={() => submitHandler()}
                          >
                            Enregistrer
                          </button>
                        </div>
                      </div>

                      <div className="panel__body patient-edit-panel">
                        <div className="loader-component" />
                        <form className="form patient-form">
                          <h2 className="form__title">Données personnelles</h2>
                          <div className="form-group form-group--2">
                            <div className="select-form-label">
                              <label className="form-group__label">Nom</label>
                            </div>
                            <input
                              type="text"
                              className="input input-- form-control"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </div>
                          <div className="form-group form-group--2">
                            <div className="select-form-label">
                              <label className="form-group__label">
                                Prénom
                              </label>
                            </div>
                            <input
                              type="text"
                              className="input input-- form-control"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </div>
                          <div className="form-group form-group--2 phone">
                            <PhoneInput
                              label={"Téléphone"}
                              callingCode={callingCode}
                              setCallingCode={setCallingCode}
                              value={telephone}
                              setValue={setTelephone}
                            />
                          </div>

                          <div className="form-group form-group--2">
                            <div className="select-form-label">
                              <label className="form-group__label">
                                E-mail
                                <span className="form-group__label-optional">
                                  (optionnel)
                                </span>
                              </label>
                            </div>
                            <input
                              type="text"
                              className="input input-- form-control"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </form>
                        <form className="form patient-form">
                          <h2 className="form__title">Compte</h2>
                          <span
                            className="checkbox checkbox--undefined undefined"
                            onClick={() => setIsChecked(!isChecked)}
                          >
                            <input
                              className="checkbox__input"
                              type="checkbox"
                              checked={isChecked}
                            />
                            <label className="checkbox__label">Activé</label>
                          </span>
                        </form>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="panel__header panel__header--border  panel__header--wrap">
                        <h3 className="panel__title">Données des récepteurs</h3>
                        <div className="buttons-container buttons-container--row">
                          {!receptor.isActive ? (
                            <button
                              type="button"
                              className="btn btn--border btn--with-icon"
                              onClick={() => setShowUpdate(true)}
                            >
                              <span className="btn__icon btn__icon--edit " />
                              Modifier les données
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="panel__body ">
                        <div className="tables-container">
                          <table className="table table--minimal">
                            <caption className="table__title">
                              Données personnelles
                            </caption>

                            <tbody>
                            <tr className="table__row  table__row--basic ">
                              <td className="table__cell table__cell--basic ">
                                <span className="table__cell-inner">
                                  Nom et Prénoms
                                </span>
                              </td>
                              <td className="table__cell table__cell--basic ">
                                <span className="table__cell-inner">
                                  {receptor &&
                                    `${receptor.firstName} ${receptor.lastName}`}
                                </span>
                              </td>
                            </tr>

                            <tr className="table__row  table__row--basic ">
                              <td className="table__cell table__cell--basic ">
                                <span className="table__cell-inner">
                                  E-mail
                                </span>
                              </td>
                              <td className="table__cell table__cell--basic ">
                                <span className="table__cell-inner">
                                  {receptor && receptor.email
                                    ? receptor.email
                                    : "Néant"}
                                </span>
                              </td>
                            </tr>

                            <tr className="table__row  table__row--basic ">
                              <td className="table__cell table__cell--basic ">
                                <span className="table__cell-inner">
                                  Téléphone
                                </span>
                              </td>
                              <td className="table__cell table__cell--basic ">
                                <span className="table__cell-inner">
                                  {receptor && receptor.telephone.value}
                                </span>
                              </td>
                            </tr>
                            </tbody>
                          </table>

                          <table className="table table--minimal">
                            <caption className="table__title">Compte</caption>

                            <tbody>
                            <tr className="table__row  table__row--basic ">
                              <td className="table__cell table__cell--basic">
                                <span className="table__cell-inner">Status</span>
                              </td>
                              <td className="table__cell table__cell--basic">
                                <span className="table__cell-inner">
                                  {receptor && receptor.isActive ? (
                                    <span style={{ color: "#14ce8c" }}>Compte Actif</span>
                                  ) : (
                                    <>
                                      <span style={{ color: "#ff505a", flex: '1' }}>Compte Inactif</span><br/><br/>
                                      <button
                                        type="button" className={`btn btn--lightblue ${ isSendingInvitation ? 'btn--loader' : '' } patient-list-container__table-btn`}
                                        onClick={ !isSendingInvitation ? sendInvitation : undefined }
                                      >
                                        Envoyer une invitation
                                      </button>
                                    </>
                                  )}
                                </span>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="panel panel--multi">
                  <div className="panel__header panel__header--border  panel__header--wrap">
                    <h3 className="panel__title ">
                      {receptor.isArchived ? "Restorer" : "Archiver"} le compte
                      du récepteur
                    </h3>
                  </div>

                  <div className="panel__body ">
                    <div className="disable-patient-content">
                      <p className="disable-patient-content__title">
                        Si vous ne voulez plus prendre en compte ce récepteur,
                        vous pouvez archiver ses données.
                        <br />
                        Le compte est masqué par défaut dans le régistre des
                        récepteurs, et vous n&apos;y aurez accès qu&apos;aprons
                        avoir filtré les comptes archivés.
                      </p>
                      <button
                        onClick={
                          receptor.isArchived
                            ? () => restoreAccount()
                            : () => setShowConfirmationModal(true)
                        }
                        type="button"
                        className={`btn btn--border ${
                          receptor.isArchived
                            ? "btn--lightgreen " +
                              (isRestoring ? "btn--loader" : "")
                            : "btn--warning"
                        }`}
                      >
                        {receptor.isArchived ? "Restorer" : "Archiver"} le
                        récepteur
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {showConfirmationModal ? (
              <div className="fullpage">
                <div className="modal modal--">
                  <div className="panel modal__content">
                    <div className="modal__header">
                      <h2 className="modal__title">Confirmation</h2>
                      <button
                        onClick={() => setShowConfirmationModal(false)}
                        type="button"
                        className="btn  btn--with-icon-only  btn--with-icon-close"
                      >
                        <span className="btn__icon btn__icon--close btn__icon--only" />
                      </button>
                    </div>

                    <div className="modal__content--inner">
                      <div className="modal__main">
                        <h3>Voulez-vous vraiment archiver ce récepteur ?</h3>
                        <p>
                          Notez que toute fois vous pourrez revenir sur votre
                          décision.
                        </p>
                      </div>
                    </div>

                    <div className="modal__footer">
                      <button
                        type="button"
                        className="btn btn--red"
                        onClick={() => confirmArchive()}
                      >
                        Archiver
                      </button>
                      <button
                        type="button"
                        className="btn btn--border"
                        onClick={() => setShowConfirmationModal(false)}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  )
}
