import React, { useContext, useEffect, useState } from 'react'
import Welcome from '../components/cards/Welcome'
import Loader from '../components/cards/Loader'
import AuthService from '../../services/auth.service'
import UserService from '../../services/user.service'
import { SessionContext } from '../../App'
import { useHistory, useLocation } from 'react-router-dom'
import { PATHS } from '../../bv'
import PasswordInput from '../components/cards/PasswordInput'


export default  function RecoverPassword({ showNotification }) {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  
  const [telephone, setTelephone] = useState('')
  const [callingCode, setCallingCode] = useState('+228')
  const [showCountriesDropdown, setShowCountriesDropdown] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  
  const [showPasswordForm, setShowPasswordForm] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [tokenExpired, setTokenExpired] = useState(false)
  
  const { countries } = useContext(SessionContext)
  
  const query = useQuery()
  const history = useHistory()
  
  const toggleCountriesDropdown = () => {
    setShowCountriesDropdown(!showCountriesDropdown)
  }
  
  const submitHandler = () => {
    if (telephone) {
      setIsSubmitting(true)
      const data = { telephone, callingCode }
      AuthService.recoverPassword(data)
        .then(response => {
          if (response.status === 200) {
            showNotification("dark", "Un lien de réinitialisation de mot de passe vous a été envoyé sur le numéro de téléphone " + telephone)
            // history.push(PATHS.signin)
            setIsSubmitting(false)
          }
        })
        .catch(e => {
          setIsSubmitting(false)
          console.log(e)
          showNotification("error", e?.response?.data?.message ?? "Error")
        })
    }
    else {
      showNotification("error", "Veuillez renseigner un numéro de téléphone")
    }
  }
  
  const resetPassword = () => {
    if (password && confirmPassword && password === confirmPassword) {
      setIsSubmitting(true)
      UserService.resetPassword({password})
        .then(response => {
          setIsSubmitting(false)
          if (response.status === 200) {
            showNotification("success", "Mot de passe réinitialisé avec success")
            history.push(PATHS.signin)
          }
        })
        .catch(e => {
          setIsSubmitting(false)
          console.log(e)
          showNotification("error", e.response.data.message ? e.response.data.message : "Error")
        })
    }
    else {
      showNotification("warning", "Les mots de passe doivent être conforme.")
    }
  }
  
  useEffect(() => {
    const key = query.get("key")
    
    if (key) {
      // const params = new URLSearchParams(key)
      const telephone = query.get("telephone")
      const callingCode = query.get("callingCode")
  
      if (telephone && callingCode) {
        setIsSubmitting(true)
        AuthService.verifyRecoverPasswordKey({telephone, callingCode, key: key.split('&')[0]})
          .then(response => {
            setIsSubmitting(false)
            localStorage.setItem("token", JSON.stringify(response.data))
            setShowPasswordForm(true)
          })
          .catch(e => {
            setIsSubmitting(false)
            setTokenExpired(true)
            console.log(e)
            showNotification("warning", e.response.data.message ? e.response.data.message : "Error")
          })
      }
    }
  }, [])
  
  return  (
    <div className="auth">
      <Welcome />
      
      <div className="auth__content">
        { isSubmitting ? <Loader full/> : "" }
        {
          tokenExpired ? (
            <a className="auth-form" href={ PATHS.recoverPassword }>
              <span className="login-form__link">Réclamer un nouveau lien</span>
            </a>
          ) : (
            <div className="auth-form">
              <div className="auth-form__content">
                <div className='auth-form__mobile-logo'/>
                <form onSubmit={ event => event.preventDefault() } className="login-form" autoComplete="off">
                  <div className="entry" style={{marginTop: 0}}>
                    <div className="entry__title">
                      <h3 className="entry__header text-center">
                        { showPasswordForm
                          ? 'Définisez un nouveau mot de passe'
                          : "Saisissez votre numéro de téléphone et nous vous enverrons un lien pour réinitialiser votre mot de passe." }
                      </h3>
                    </div>
                  </div>
                  
                  <div className="login-form__inputs">
                    {
                      showPasswordForm ? (
                        <>
                          <div className="form-group form-group--1 form-group--password form-group--side ">
                            <div className="select-form-label"><label className="form-group__label">Mot de passe</label></div>
                            <PasswordInput value={ password } setValue={ setPassword } submitHandler={ undefined }/>
                          </div>
                          <div className="form-group form-group--1 form-group--password form-group--side ">
                            <div className="select-form-label"><label className="form-group__label">Confirmez le mot de passe</label></div>
                            <PasswordInput value={ confirmPassword } setValue={ setConfirmPassword } submitHandler={ undefined }/>
                          </div>
                        </>
                      ) : (
                        <div className="form-group form-group--1 form-group--side">
                          <div className='form-group form-group--1 register-form__dialing'>
                            <div className='select-container select-container--simple ' tabIndex='-1'>
                              <input
                                type='text' autoComplete='off' onChange={() => {}}
                                onClick={ toggleCountriesDropdown }
                                onKeyDown={ event => event.type === "keydown" && !event.altKey && event.code === "Enter" && event.key === "Enter" && submitHandler() }
                                value={ callingCode } className='input input--dropdown user-select-none'
                              />
                              <div style={{ display: showCountriesDropdown ? 'block' : 'none' }} className='dropdown dropdown--simple'>
                                <ul className='dropdown-list select-list '>
                                  {
                                    countries.map((country, i) => (
                                      <li
                                        onClick={ () => {
                                          setCallingCode('+' + country.callingCodes[0])
                                          toggleCountriesDropdown()
                                        }}
                                        key={ i } style={{width: '100px'}}
                                        className='dropdown-list__item '
                                      >
                                        { country.alpha2Code } +{ country.callingCodes[0] }
                                      </li>
                                    ))
                                  }
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className='form-group form-group--2 register-form__phone'>
                            <input
                              onKeyDown={ (e) => e.type === "keydown" && !e.altKey && e.code === "Enter" && e.key === "Enter" && submitHandler() }
                              type='number' className='input input-- form-control' placeholder='Entrez le numéro de téléphone'
                              value={ telephone } onChange={ event => setTelephone(event.target.value.trim()) }
                            />
                          </div>
                        </div>
                      )
                    }
                  </div>
                </form>
              </div>
              
              <div className='auth-form__footer' style={{justifyContent: 'center'}}>
                <button
                  type="button" className="btn btn--blue btn--wider confirm-button"
                  onClick={ showPasswordForm ? resetPassword : submitHandler } disabled={ isSubmitting }
                >
                  Réinitialiser le mot de passe
                </button>
                <p className='mobile-desc'>© Vaillants Battisseurs + 2021</p>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}
