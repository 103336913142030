import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import UserService from "../../../services/user.service"
import Loader from "../../../jsx/components/cards/Loader"
import { requestToLogin } from '../../../bv'
import PersonalInfo from './PersonalInfo'
import PromiseStats from './PromiseStats'
import Donations from './Donations'

export default function Profile({ showNotification }) {
  const buttonList = [
    { id: 1, component: PersonalInfo, text: 'Information personelles' },
    { id: 2, component: PromiseStats, text: 'Promesse' },
    { id: 3, component: Donations, text: 'Donations' },
    { id: 4, component: () => {
        return <p style={{textAlign: 'center', fontSize: '2rem', marginTop: '50px'}}>
          Vous avez des réclamations ou suggestions ? laissez-nous un email sur cette adresse: {' '}<br/>
          <a href="mailto:relcamations@vbfatad.org">relcamations@vbfatad.org</a>
        </p>
      }, text: 'Plaintes' }
  ]
  
  const queryParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })

  const [btnSelected, setBtnSelected] = useState(buttonList.find(item => item.id.toString() === (queryParams.tab ?? '1')))
  const [user, setUser] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [sortBy, setSortBy] = useState("-createdAt")
  const [donations, setDonations] = useState(JSON.parse(localStorage.getItem("my_donations")) || null)

  const history = useHistory()

  useEffect(() => {
    UserService.getMe()
      .then(response => {
        if (response.status === 200) {
          setUser(response.data.data)
          localStorage.setItem('user', JSON.stringify(response.data))
        }
      })
      .catch(e => {
        console.log(e)
        if (e.response && e.response.status === 401) requestToLogin(history.location.pathname)
      })
    
    UserService.getMyDonations(sortBy)
      .then((response) => {
        setDonations(response.data)
        localStorage.setItem("my_donations", JSON.stringify(response.data))
      })
      .catch((e) => {
        console.log(e)
        if (e.response.status === 401) requestToLogin(history.location.pathname)
      })
  }, [sortBy])
  
  useEffect(() => {
    history.replace({
      pathname: history.location.pathname,
      search: `?tab=${btnSelected.id}`
    })
  }, [btnSelected.id])

  return (
    <div className="page">
      { isSubmitting ? <Loader full /> : "" }
      
      <div className="page-content">
        {
          user ? (
            <div className="patient-view-container">
              <div className="patient-view-container__side">
                <div className="visit-info">
                  <div className="page-heading">
                    <h2 className="page-heading__title">Votre profil</h2>
                  </div>
                  <div className="visit-info__items-wrapper">
                    <div className="icon-text visit-info__icon-text">
                      <div className="icon-text__icon icon-text__icon--patient" />
                      <div className="icon-text__title-wrapper">
                        <p className="icon-text__title">
                          {user && `${user.firstName} ${user.lastName}`}
                        </p>
                        <p className="icon-text__subtitle">
                          Tel &nbsp;:&nbsp;{user.telephone.callingCode}{" "}
                          {user.telephone.value}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="patient-view-container__main">
                <div className="panel panel--multi">
                  <div className="tabs">
                    {
                      buttonList.map((button, index) => (
                        <div className="tabs__tab" key={index}>
                          <button
                            type="button" className={`btn ${btnSelected.id === button.id ? "btn--lightblue" : ""}`}
                            onClick={ () => setBtnSelected(button) }
                          >
                            { button.text }
                          </button>
                        </div>
                      ))
                    }
                  </div>
                </div>
                
                { React.createElement(btnSelected.component, {setIsSubmitting, showNotification, user, sortBy, setSortBy, donations}) }
                
              </div>
            </div>
          ) : (
            <Loader />
          )
        }
      </div>
    </div>
  )
}
