import React, { useState, useEffect } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import UserService from "../../../../services/user.service"
import Loader from "../../../../jsx/components/cards/Loader"
import { PATHS, requestToLogin } from '../../../../bv'
import PersonalInfo from './PersonalInfo'
import PromiseStats from './PromiseStats'
import Donations from './Donations'
// import { SessionContext } from '../../../../App'

export default function Profile({ showNotification }) {
  // const { user } = useContext(SessionContext)
  const { params } = useRouteMatch()
  const id = params.id
  
  const buttonList = /* user.role === 'admin' ? */ [
    { id: 1, component: PersonalInfo, text: 'Information personelles' },
    { id: 2, component: PromiseStats, text: 'Promesse' },
    { id: 3, component: Donations, text: 'Donations' },
  ] /*: [
    { id: 1, component: PersonalInfo, text: 'Information personelles' },
    { id: 3, component: Donations, text: 'Donations' },
  ] */
  
  const queryParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })
  
  const [btnSelected, setBtnSelected] = useState(buttonList.find(item => item.id.toString() === (queryParams.tab ?? '1')))
  const [donor, setDonor] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [sortBy, setSortBy] = useState("-createdAt")
  const [donations, setDonations] = useState(null)

  const history = useHistory()
  
  useEffect(() => {
    UserService.getOneDonor(id)
      .then((response) => {
        if (response.status === 200) {
          setDonor(response.data)
          /* const donations = JSON.parse(localStorage.getItem("donations"))
          if (donations && donations.length)
            setDonations(donations.filter(item => item.donor?._id === response.data._id)) */
        }
      })
      .catch((e) => {
        if (e.response?.status === 401) requestToLogin(history.location.pathname)
      })
  }, [])
  
  useEffect(() => {
    UserService.getDonations(id)
      .then((response) => {
        if (response.status === 200) {
          setDonations(response.data)
        }
      })
      .catch((e) => {
        if (e.response?.status === 401) requestToLogin(history.location.pathname)
      })
  }, [])
  
  useEffect(() => {
    history.replace({
      pathname: history.location.pathname,
      search: `?tab=${btnSelected.id}`
    })
  }, [btnSelected.id])

  return (
    <div className="page">
      { isSubmitting ? <Loader full /> : "" }
      
      <div className="page-content">
        {
          donor ? (
            <div className="patient-view-container">
              <div className="patient-view-container__side">
                <a className="navlink-btn">
                  <Link to={PATHS.donorList}>
                    <button type="button" className="btn btn--back btn--with-icon">
                      <span className="btn__icon btn__icon--back " />
                      Liste des donateurs
                    </button>
                  </Link>
                </a>
                
                <div className="visit-info">
                  <div className="page-heading">
                    <h2 className="page-heading__title">Profil du donateur</h2>
                  </div>
                  <div className="visit-info__items-wrapper">
                    <div className="icon-text visit-info__icon-text">
                      <div className="icon-text__icon icon-text__icon--patient" />
                      <div className="icon-text__title-wrapper">
                        <p className="icon-text__title">
                          { donor && `${ donor.firstName } ${ donor.lastName }` }
                        </p>
                        <p className="icon-text__subtitle">
                          Tel &nbsp;:&nbsp;{ donor && donor.telephone.value }
                        </p>
                      </div>
                    </div>
                    
                    <button
                      type="button" className="btn btn--blue btn--inline"
                      onClick={() => history.push(`${ PATHS.addDonation }?donor=${ donor._id }`)}
                    >
                      Ajouter une donation
                    </button>
                  </div>
                </div>
              </div>
              
              <div className="patient-view-container__main">
                <div className="panel panel--multi">
                  <div className="tabs">
                    {
                      buttonList.map((button, index) => (
                        <div className="tabs__tab" key={index}>
                          <button
                            type="button" className={`btn ${btnSelected.id === button.id ? "btn--lightblue" : ""}`}
                            onClick={ () => setBtnSelected(button) }
                          >
                            { button.text }
                          </button>
                        </div>
                      ))
                    }
                  </div>
                </div>
                
                { React.createElement(btnSelected.component, {setIsSubmitting, showNotification, donor, setDonor, sortBy, setSortBy, donations}) }
                
              </div>
            </div>
          ) : (
            <Loader />
          )
        }
      </div>
    </div>
  )
}
