import React, { useContext, useEffect, useState } from 'react'
import { motion } from "framer-motion"
import dateFormat from "dateformat"
import { stateLogger } from "../../../stateLogger"
import { formatNumber, PAYMENT_MODE } from '../../../bv'
import UserService from '../../../services/user.service'
import { SessionContext } from '../../../App'
import SelectInput from './SelectInput'
import DayInput from './DayInput'
import DonationService from '../../../services/donation.service'
import { Link } from 'react-router-dom'

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
}

const flip = {
  hidden: {
    transform: "scale(0) rotateX(-360deg)",
    opacity: 0,
    transition: {
      delay: 0.3,
    },
  },
  visible: {
    transform: " scale(1) rotateX(0deg)",
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    transform: "scale(0) rotateX(360deg)",
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
}

const newspaper = {
  hidden: {
    transform: "scale(0) rotate(720deg)",
    opacity: 0,
    transition: {
      delay: 0.3
    },
  },
  visible: {
    transform: " scale(1) rotate(0deg)",
    opacity: 1,
    transition: {
      duration: 0.5
    }
  },
  exit: {
    transform: "scale(0) rotate(-720deg)",
    opacity: 0,
    transition: {
      duration: 0.3
    }
  }
}

const badSuspension = {
  hidden: {
    y: "-100vh",
    opacity: 0,
    transform: "scale(0) rotateX(-360deg)"
  },
  visible: {
    y: "-25vh",
    opacity: 1,
    transition: {
      duration: 0.2,
      type: "spring",
      damping: 15,
      stiffness: 500
    }
  },
  exit: {
    y: "-100vh",
    opacity: 0
  }
}

const Modal = ({ handleClose, text, type, selectedDonation: donation, showNotification, askForConfirmation }) => {
  const [isPending, setIsPending] = useState(false)
  const [donor, setDonor] = useState(null)
  const [showUpdateForm, setShowUpdateForm] = useState(false)
  
  const [selectedTransactionMode, setSelectedTransactionMode] = useState(PAYMENT_MODE.find(item => item.text === donation.transactionMethod))
  const [accountNumber, setAccountNumber] = useState(donation.accountNumber)
  const [amount, setAmount] = useState(donation.amount)
  const [date, setDate] = useState(new Date(donation.date))
  
  const { user } = useContext(SessionContext)
  
  useEffect(() => {
    stateLogger("Modal", true)

    if (user.role === 'donor')
      setDonor(user)
    else if (donation.donor)
      UserService.getOneDonor(donation.donor._id)
        .then(response => setDonor(response.data))
        .catch(e => console.log(e))

    return () => stateLogger("Modal", false)
  }, [])
  
  const updateDonation = () => {
    const data = {
      donor: donor._id,
      transactionMethod: selectedTransactionMode.value,
      amount,
      accountNumber,
      date,
    }
    setIsPending(true)
    DonationService.updateOne(donation._id, data)
      .then((response) => {
        if (response.status === 200) {
          setShowUpdateForm(false)
          showNotification("success", response.data.message ? response.data.message : "Bravo")
        }
      })
      .catch((e) => {
        console.log(e)
        showNotification("error", e.message || "Error")
      })
      .finally(() => setIsPending(false))
  }
  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fullpage"
      onClick={ handleClose }
    >
      <motion.div
        onClick={ (e) => e.stopPropagation() }
        className="modal"
        variants={ type === "dropIn" ? dropIn : type === "flip" ? flip : type === "" ? newspaper : type === "badSuspension" ? badSuspension : '' }
        initial="hidden"
        animate="visible"
        exit="exit"
      >

        <div className="panel modal__content">
          <div className="modal__header">
            <h2 className="modal__title">Details de la donation</h2>
            <button
              onClick={ handleClose }
              type="button" className="btn btn--with-icon-only btn--with-icon-close"
            >
              <span className="btn__icon btn__icon--close btn__icon--only"/>
            </button>
          </div>

          <div className="modal__content--inner" style={{paddingBottom: '2rem'}}>
            <div className="modal__main">
              {
                !showUpdateForm ? (
                  <>
                    <div className="doc-templates__item">
                      <Link className="doc-templates__item-title person__icon cursor-pointer link" to={ `/donor/${ donor ? donor._id : undefined }` }>
                        { donor ? donor.firstName : '' } - &nbsp;
                        { donor ? donor.lastName : '' }
                      </Link>
                      {
                        donation.date ? (
                          <span>Le { dateFormat(donation.date, "dd/mm/yyyy") }</span>
                        ) : ''
                      }
                    </div>

                    <div className="doc-templates__item">
                      <h3 className="doc-templates__item-title money__icon">
                        { donation.amount ? formatNumber(donation.amount) : '' } XOF - &nbsp;
                        { donation.transactionMethod ? donation.transactionMethod : '' }
                      </h3>
                    </div>

                    <div className="doc-templates__item">
                      <h3 className="doc-templates__item-title calendar__data-icon">
                        { donation.createdAt ? dateFormat(donation.createdAt, "dd/mm/yyyy") : '' }
                      </h3>
                    </div>
                  </>
                ) : (
                  <div>
                    <div className="form-group double-step__section">
                      <label className="form-group__label">
                        Methode de transaction
                      </label>
                      <div className="form-group form-group--1 ">
                        <div className="select-container select-container--simple " tabIndex="-1">
                          <SelectInput
                            setValue={setSelectedTransactionMode}
                            value={selectedTransactionMode}
                            options={PAYMENT_MODE}
                          />
                        </div>
                      </div>
                      
                      <label className="form-group__label">
                        Numéro de compte
                        <span className="form-group__label-optional">
                          (optionnel)
                        </span>
                      </label>
                      <div className="form-group form-group--1">
                        <input
                          type="text"
                          value={accountNumber}
                          onChange={(event) => setAccountNumber(event.target.value)}
                          className="input input-- form-control"
                        />
                      </div>
                      
                      <label className="form-group__label">
                        Montant reçu (XOF)
                      </label>
                      <div className="form-group form-group--1">
                        <input
                          type="text"
                          value={amount}
                          onChange={(event) => setAmount(event.target.value)}
                          className="input input-- form-control"
                        />
                      </div>
                    </div>
                    
                    <div className="form-group double-step__section">
                      <div className="form-group form-group--1 form-group-- ">
                        <label className="form-group__label">Date</label>
                        <DayInput selectedDay={date} setSelectedDay={setDate} />
                      </div>
                    </div>
                  </div>
                )
              }
            </div>

            <div style={{display: 'flex', justifyContent: 'center'}}>
              {
                !showUpdateForm ? (
                  <>
                    {
                      ["IN_PROGRESS", "CANCELED"].includes(donation.status) ? (
                        <button
                          type="button"
                          className="btn btn--blue"
                          onClick={ () => askForConfirmation && askForConfirmation(donation, "accept") }
                          style={{margin: '0 4rem 0 0'}}
                        >
                          Valider
                        </button>
                      ) : ''
                    }
                    <button type="button" className="btn btn--lightblue" onClick={ () => setShowUpdateForm(true) }>
                      Modifier
                    </button>
                  </>
                ) : (
                  <>
                    <button type="button" className="btn btn--red" onClick={ () => setShowUpdateForm(false) } style={{marginRight: '1rem'}}>
                      Annuler
                    </button>
                    <button type="button" className={`btn btn--blue ${ isPending ? 'btn--loader' : '' }`} onClick={ () => updateDonation() }>
                      Enregistrer
                    </button>
                  </>
                )
              }
            </div>
          </div>
        </div>

      </motion.div>
    </motion.div>
  )
}

export default Modal;
