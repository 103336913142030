import http from "../http-common"
import { getOptions } from "../bv"

class ReportingService {
  getAll() {
    return http.get("/api/reporting", getOptions())
  }
}

export default new ReportingService()
