import React, { useContext, useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { SessionContext } from '../../../App'
import { useRouteMatch } from 'react-router-dom'
import { framerLogger } from '../../../stateLogger'
import Notification from './Notification'
import { add } from '../../../arr-utils'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null, errorInfo: null }
  }
  
  static getDerivedStateFromError = () => {
    return { hasError: true }
  }
  
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {/* {this.state.errorInfo.componentStack} */}
          </details>
        </div>
      )
    }

    return <Wrapper props={ this.props }>{ this.props.children }</Wrapper>
  }
}

const Wrapper = ({ children, props }) => {
  const [notifications, setNotifications] = useState([])
  
  const { setCurrentPath } = useContext(SessionContext)
  const { path } = useRouteMatch()
  
  const showNotification = (style, text) => setNotifications(add(notifications, text, style))
  
  useEffect(() => setCurrentPath(path))
  
  return (
    <>
      { React.createElement(children, { showNotification, ...props }, null) }
      
      <NotificationContainer>
        {
          notifications && notifications.map((notification) => (
            <Notification
              key={notification.id}
              notification={notification}
              notifications={notifications}
              setNotifications={setNotifications}
            />
          ))
        }
      </NotificationContainer>
    </>
  )
}


const NotificationContainer = ({ children }) => {
  return (
    <AnimatePresence
      initial={false}
      onExitComplete={ () => framerLogger("Notifications container") }
    >
      {children}
    </AnimatePresence>
  )
}

export default ErrorBoundary
