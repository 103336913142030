import React, { useContext, useState } from "react"
import { DonationContext } from "../addDonationModal"
import DonationService from "../../../../services/donation.service"
import { PATHS } from '../../../../bv'
import { useHistory } from 'react-router-dom'

export default function ResumeStep() {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    setCurrentStep,
    selectedDonor,
    selectedTransactionMode,
    amount,
    date,
    accountNumber,
    setShowModal,
    showNotification,
  } = useContext(DonationContext)
  const history = useHistory()

  const submitHandler = () => {
    if (selectedDonor && selectedTransactionMode && amount && date) {
      setIsSubmitting(true)
      const data = {
        donor: selectedDonor._id,
        transactionMethod: selectedTransactionMode.value,
        amount,
        accountNumber,
        date,
      }
      DonationService.createOne(data)
        .then((response) => {
          if (response.status === 201) {
            showNotification("success", response.data?.message ?? "Donation sauvegardée avec success")
            setTimeout(() => {
              showNotification()
              setShowModal(false)
              history.replace(PATHS.donationList)
            }, 500)
          }
        })
        .catch((e) => {
          const message = e.response.statusCode === 500
            ? "Une erreur s'est produite du côté du serveur"
            : e.response?.data?.message ?? "Erreur de réseau"
          showNotification("error", message)
        })
        .finally(() => setIsSubmitting(false))
    }
  }

  return (
    <>
      {
        isSubmitting ? (
          <div className="loader-component" style={{ position: "absolute", width: "100%", height: "100%" }}>
            <div
              className="loader-component__container "
              style={{ width: "100%", height: "100%" }}
            >
              <svg
                className="loader"
                width="57"
                height="57"
                viewBox="0 0 57 57"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#fff"
              >
                <g fill="none" fillRule="evenodd">
                  <g transform="translate(1 1)" strokeWidth="2">
                    <circle className="loader__circle" cx="5" cy="50" r="5">
                      <animate
                        attributeName="cy"
                        begin="0s"
                        dur="2.2s"
                        values="50;5;50;50"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="cx"
                        begin="0s"
                        dur="2.2s"
                        values="5;27;49;5"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </circle>
                    <circle className="loader__circle" cx="27" cy="5" r="5">
                      <animate
                        attributeName="cy"
                        begin="0s"
                        dur="2.2s"
                        from="5"
                        to="5"
                        values="5;50;50;5"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="cx"
                        begin="0s"
                        dur="2.2s"
                        from="27"
                        to="27"
                        values="27;49;5;27"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </circle>
                    <circle className="loader__circle" cx="49" cy="50" r="5">
                      <animate
                        attributeName="cy"
                        begin="0s"
                        dur="2.2s"
                        values="50;50;5;50"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="cx"
                        from="49"
                        to="49"
                        begin="0s"
                        dur="2.2s"
                        values="49;5;27;49"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </circle>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        ) : ""
      }
      <div className="step">
        <div className="step__content step__content--maxwidth" style={{ marginBottom: "5rem" }}>
          <div className="double-step">
            <div className="double-step__part" style={{ maxWidth: "unset" }}>
              <div className="">
                <h3 className="step__title step__title--left text-center">
                  2. Sommaire
                </h3>

                <div className="step__content" style={{ width: "unset" }}>
                  <div className="panel panel--multi">
                    <div className="panel__body doc-templates">
                      <div className="doc-templates__item">
                        <h3 className="doc-templates__item-title person__icon">
                          {selectedDonor && selectedDonor.firstName}{" "}
                          {selectedDonor && selectedDonor.lastName}-
                        </h3>
                        <div className="doc-templates__item-actions">
                          <span className="doc-templates__item-link" onClick={() => setCurrentStep(1)}>
                            <button type="button" className="btn btn--icon btn--border btn--with-icon">
                              <span className="btn__icon btn__icon--edit" />
                              Modifier
                            </button>
                            <button type="button" className="btn btn--icon btn--big btn--with-icon-only">
                              <span className="btn__icon btn__icon--download btn__icon--only" />
                            </button>
                          </span>
                        </div>
                      </div>

                      <div className="doc-templates__item">
                        <h3 className="doc-templates__item-title">
                          {selectedTransactionMode && selectedTransactionMode.text}{" "}
                          --&nbsp;
                          {amount} XOF --&nbsp;
                          {`${
                            date.getDate().toString().length < 2
                              ? `0${date.getDate()}`
                              : date.getDate()
                          }.${
                            (date.getMonth() + 1).toString().length < 2
                              ? `0${date.getMonth() + 1}`
                              : date.getMonth() + 1
                          }.${date.getFullYear()}`}{" "}
                          --&nbsp;
                          {accountNumber}
                        </h3>
                        <div className="doc-templates__item-actions">
                          <span
                            className="doc-templates__item-link"
                            onClick={() => setCurrentStep(2)}
                          >
                            <button
                              type="button"
                              className="btn btn--icon btn--border btn--with-icon"
                            >
                              <span className="btn__icon btn__icon--edit" />
                              Modifier
                            </button>
                            <button
                              type="button"
                              className="btn btn--icon btn--big btn--with-icon-only"
                            >
                              <span className="btn__icon btn__icon--download btn__icon--only" />
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="step__footer step__footer--relative">
          <button
            type="button"
            className="btn btn--border  step__footer-btn"
            onClick={() => setCurrentStep(2)}
          >
            Retour
          </button>
          <button
            type="button"
            className="btn btn--blue  step__footer-btn"
            onClick={submitHandler}
          >
            Confirmer et Enregistrer
          </button>
        </div>
      </div>
    </>
  )
}
