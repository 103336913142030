import axios from "axios"

// export const BASE_URL = process.env.API_URL || "http://localhost:7898"
export const BASE_URL = process.env.API_URL || "https://vbfatad.org:8000"

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
  },
})

export const RESPONSES = {
  500: {
    message: "😥 Oups! Une erreur est survenue lors de l'opération. Veuillez ressayer plus tard ou contacter un administrateur."
  }
}

export default instance
