import React, { useState } from 'react'
import { AMOUNTS, formatNumber, PATHS } from '../../../bv'
import { useHistory } from 'react-router-dom'
import { ModalContainer } from '../../components/donation/DonationList'
import { motion } from 'framer-motion'
import userService from '../../../services/user.service'

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
}

export default function PromiseStats({ user, donations, showNotification }) {
  console.log(user)
  const totalDonations = donations.reduce((prev, current) => prev + current.amount, 0)
  const promise = user && user.promise ? user.promise.amount : 0
  
  const history = useHistory()
  
  
  const [showModal, setShowModal] = useState(false)
  const [showOtherAmount, setShowOtherAmount] = useState(false)
  const [otherAmountValue, setOtherAmountValue] = useState("")
  const [selectedAmount, setSelectedAmount] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  
  const toggleOtherAmount = () => {
    setShowOtherAmount(!showOtherAmount)
    setSelectedAmount({})
  }
  
  const updatePromise = () => {
    const amount = selectedAmount.value || otherAmountValue
    
    if (amount) {
      setIsSubmitting(true)
  
      userService.setPromise(user._id, {amount})
        .then(() => window.location.reload())
        .catch(error => {
          console.log(error)
          showNotification("error", error.response?.data?.message ?? "Error")
        })
        .finally(() => setIsSubmitting(false))
    }
  }

  return (
    <div className="patient-info-container">
      <section className="statistics" style={{justifyContent: 'center'}}>
        <div className="panel statistics__panel" style={{maxWidth: 'unset'}}>
          <div className="statistics__panel-header">
            <p className="statistics__title">Promesse</p>
          </div>
          <div className="statistics__select">
            <div className="active-filter-switch">
              <div className="active-filter-switch__dropdown">XOF</div>
            </div>
          </div>
          <p className="statistics__number" style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            { formatNumber(promise) }
            <button
              type="button" className={`btn btn--small btn--lightblue patient-list-container__table-btn`}
              onClick={ () => setShowModal(true) }
            >
              Modifier
            </button>
          </p>
        </div>
        
        <div className="panel statistics__panel" style={{maxWidth: 'unset'}}>
          <div className="statistics__panel-header">
            <p className="statistics__title">Total de mes donations</p>
          </div>
          <div className="statistics__select">
            <div className="active-filter-switch">
              <div className="active-filter-switch__dropdown">XOF</div>
            </div>
          </div>
          <p className="statistics__number">
            { formatNumber(totalDonations) }
          </p>
        </div>
        
        <div className="panel statistics__panel" style={{maxWidth: 'unset'}}>
          <div className="statistics__panel-header">
            <p className="statistics__title">Restant</p>
          </div>
          <div className="statistics__select">
            <div className="active-filter-switch">
              <div className="active-filter-switch__dropdown">XOF</div>
            </div>
          </div>
          <p className="statistics__number statistics__number--incomplete">
            { formatNumber(promise <= totalDonations ? 0 : promise - totalDonations) }
          </p>
        </div>
      </section>
      
      <button
        type="button" className="btn btn--blue" style={{display: 'block', margin: 'auto'}}
        onClick={ () => history.push(PATHS.makeDonation) }
      >
        Faire un don
      </button>
  
  
  
      {
        showModal ? (
          <ModalContainer>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fullpage"
              onClick={ () => setShowModal(false) }
            >
              <motion.div
                onClick={ (e) => e.stopPropagation() }
                className="modal"
                variants={ dropIn }
                initial="hidden"
                animate="visible"
                exit="exit"
              >
            
                <div className="panel modal__content">
                  <div className="modal__header">
                    <h2 className="modal__title">Promesse</h2>
                    <button
                      onClick={ () => setShowModal(false) }
                      type="button" className="btn btn--with-icon-only btn--with-icon-close"
                    >
                      <span className="btn__icon btn__icon--close btn__icon--only"/>
                    </button>
                  </div>
              
                  <div className="modal__content--inner  auth-form" style={{paddingBottom: '2rem', overflowY: 'scroll'}}>
                
                    <div className="step-layout">
                      <div className="step-layout__title">
                        <h3 className="step-layout__header">
                          Selectionnez le montant de votre promesse
                        </h3>
                        {/* <p className="step-layout__desc">Vous pouvez définir un autre montant</p> */}
                      </div>
                  
                      <div className="step-layout__content">
                        <div className="d-flex">
                          {AMOUNTS.map((amount, i) => (
                            <div
                              key={i}
                              style={{ width: "unset" }}
                              className="account-selector margin-x-10 hover-border-0aceda"
                              onClick={() => {
                                setSelectedAmount(amount)
                                setShowOtherAmount(false)
                              }}
                            >
                              <span className="checkbox checkbox--bold checkbox--472fc6">
                                <input
                                  checked={
                                    selectedAmount.value === amount.value
                                  }
                                  className="checkbox__input"
                                  type="checkbox"
                                />
                                <label className="checkbox__label ">
                                  {amount.text} F CFA
                                </label>
                              </span>
                            </div>
                          ))}
                        </div>
                    
                        <div>
                          <div className="form-group inactive">
                            <button
                              type="button"
                              className="btn btn--iconside btn--with-icon"
                              onClick={() => toggleOtherAmount()}
                            >
                              <span
                                className={`btn__icon btn__icon--${
                                  showOtherAmount ? "minus" : "plus"
                                }`}
                              />
                              <font>Définir un autre montant</font>
                            </button>
                          </div>
                      
                          {showOtherAmount ? (
                            <div
                              className="d-flex"
                              style={{ maxWidth: "350px", margin: "auto" }}
                            >
                              <div
                                className="account-selector margin-x-10 border-black"
                                style={{ cursor: "default" }}
                              >
                                <span className="checkbox checkbox--bold checkbox--472fc6">
                                  <input
                                    value={true}
                                    checked={true}
                                    className="checkbox__input"
                                    type="checkbox"
                                  />
                                  <label className="checkbox__label" />
                                  <input
                                    id="custom-amount-input"
                                    type="number"
                                    className="input input--side form-control"
                                    placeholder="ex: 5000"
                                    value={otherAmountValue}
                                    onChange={event =>
                                      setOtherAmountValue(event.target.value)
                                    }
                                  />
                                </span>
                              </div>
                            </div>
                          ) : ''}
                        </div>
                      </div>
                    </div>
                
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <button type="button" disabled={ isSubmitting === true } className={`btn btn--blue ${ isSubmitting ? 'btn--loader' : '' }`} onClick={ updatePromise }>
                        Enregistrer
                      </button>
                    </div>
              
                  </div>
                </div>
          
              </motion.div>
            </motion.div>
          </ModalContainer>
        ) : ''
      }
    </div>
  )
}
