import React, { useContext, useEffect, useState } from 'react'
import PhoneInput from '../../cards/PhoneInput'
import UserService from '../../../../services/user.service'
import PromiseService from '../../../../services/promise.service'
import SelectInput from '../../cards/SelectInput'
import { AMOUNTS } from '../../../../bv'
import { DonationContext } from '../addDonationModal'
import { useHistory } from 'react-router-dom'

export default function DonorStep() {
  const { setCurrentStep, selectedDonor, setSelectedDonor, showNotification } = useContext(DonationContext)

  const [activeTab, setActiveTab] = useState(2)
  const [donors, setDonors] = useState(null)
  const [showDonorDropDown, setShowDonorDropDown] = useState(false)

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [telephone, setTelephone] = useState("")
  const [callingCode, setCallingCode] = useState("+228")
  const [selectedPromise, setSelectedPromise] = useState(null)

  const [filterText, setFilterText] = useState("")
  
  const history = useHistory()
  const params = new Proxy(new URLSearchParams(history.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })

  const submitHandler = () => {
    if (activeTab === 1) {
      const data = {
        firstName,
        lastName,
        email,
        telephone: { value: telephone, callingCode },
        role: "donor",
      }
      UserService.create(data)
        .then((response) => {
          if (response.status === 201) {
  
            if (selectedPromise) {
              PromiseService.createOne({
                donor: response.data._id,
                amount: selectedPromise.value,
                date: Date.now(),
              })
                .then(() => {
                  if (response.status === 201) {
                    setSelectedDonor(response.data)
                    setCurrentStep(2)
                  }
                })
                .catch((e) => {
                  console.log(e)
                  showNotification("error", e.message ? e.message : "Error")
                })
            }
          }
        })
        .catch((e) => {
          console.log(e)
          showNotification("error", e.message ? e.message : "Error")
        })
    } else if (activeTab === 2 && selectedDonor) {
      setCurrentStep(2)
    }
  }

  useEffect(() => {
    document.body.addEventListener(
      "click",
      () => {
        if (showDonorDropDown) {
          setShowDonorDropDown(false)
        }
      },
      false
    )

    if (activeTab === 2 && !donors) {
      UserService.getDonors()
        .then((response) => {
          if (response.status === 200) {
            setDonors(response.data)
          }
        })
        .catch((e) => {
          console.log(e)
          // showNotification('error', e.message)
        })
    }
    
    if (params.donor && donors && !selectedDonor) {
      const donor = donors.find(item => item._id === params.donor)
      if (donor) {
        setSelectedDonor(donor)
        setFilterText(`${donor.firstName} ${donor.lastName}`)
      }
    }
  }, [activeTab, selectedDonor, donors])

  return (
    <div className="step">
      <h3 className="step__title">1. Choix du compte du donateur</h3>
      <div className="step__content ">
        <div className="toggle-buttons">
          <button
            onClick={() => setActiveTab(1)}
            type="button"
            className={`btn btn--double btn--border ${
              activeTab === 1 ? "btn--lightblue" : ""
            }`}
          >
            Nouveau compte
          </button>
          <button
            onClick={() => setActiveTab(2)}
            type="button"
            className={`btn btn--double btn--border ${
              activeTab === 2 ? "btn--lightblue" : ""
            }`}
          >
            Compte existant
          </button>
        </div>
        {activeTab === 1 ? (
          <form className="form patient-form">
            <div className="form-group form-group--2  ">
              <div className="select-form-label">
                <label className="form-group__label">Nom</label>
              </div>
              <input
                onChange={(event) => setFirstName(event.target.value)}
                type="text"
                className="input input-- form-control"
                value={firstName}
              />
            </div>

            <div className="form-group form-group--2  ">
              <div className="select-form-label">
                <label className="form-group__label">Prénoms</label>
              </div>
              <input
                onChange={(event) => setLastName(event.target.value)}
                type="text"
                className="input input-- form-control "
                value={lastName}
              />
            </div>

            <div className="form-group form-group--2 phone">
              <PhoneInput
                label={"Numéro de téléphone"}
                callingCode={callingCode}
                setCallingCode={setCallingCode}
                value={telephone}
                setValue={setTelephone}
              />
            </div>

            <div className="form-group form-group--2 ">
              <div className="select-form-label">
                <label className="form-group__label">
                  E-mail
                  <span className="form-group__label-optional">(optionnel)</span>
                </label>
              </div>
              <input
                onChange={(event) => setEmail(event.target.value)}
                type="text"
                className="input input-- form-control"
                value={email}
              />
            </div>

            <div className="form-group form-group--1">
              <div className="select-form-label">
                <label className="form-group__label">Promesse</label>
                <span className="form-group__label-optional">(optionnel)</span>
              </div>

              <SelectInput
                setValue={setSelectedPromise}
                value={selectedPromise}
                options={AMOUNTS}
              />
            </div>

            {/*     <div className="form-group form-group--1">
              <span
                onClick={() => setIsChecked(!isChecked)}
                className="checkbox checkbox--11ce8c"
              >
                <input
                  className="checkbox__input"
                  type="checkbox"
                  checked={isChecked}
                />
                <label className="checkbox__label ">
                  Activer le compte du donateur
                </label>
              </span>
            </div> */}
          </form>
        ) : activeTab === 2 ? (
          <>
            <div className="form-group form-group--1 ">
              <div
                className="select-container select-container--simple "
                tabIndex="-1"
              >
                <input
                  onChange={(event) => setFilterText(event.target.value)}
                  onClick={(event) => {
                    console.log(event.target.value.length)
                    event.target.setSelectionRange(0, event.target.value.length)
                    setShowDonorDropDown(true)
                    event.stopPropagation()
                  }}
                  // onBlur={ () => setShowDonorDropDown(false) }
                  onKeyDown={(e) =>
                    e.type === "keydown" &&
                    !e.altKey &&
                    e.code === "Escape" &&
                    e.key === "Escape" &&
                    setShowDonorDropDown(false)
                  }
                  type="text"
                  autoComplete="off__1630447592801"
                  className="input input--search"
                  placeholder="Nom et prénom du donateur"
                  value={filterText}
                />
                {showDonorDropDown && (
                  <div
                    className="dropdown dropdown--simple"
                    onClick={(event) => event.stopPropagation()}
                  >
                    <ul className="dropdown-list select-list">
                      {donors && donors.length
                        ? donors
                            .filter((d) =>
                              `${d.firstName} ${d.lastName}`
                                .toLowerCase()
                                .includes(filterText.toLowerCase())
                            )
                            .map((donor, i) => (
                              <li
                                onClick={() => {
                                  setSelectedDonor(donor)
                                  setFilterText(
                                    `${donor.firstName} ${donor.lastName}`
                                  )
                                  setShowDonorDropDown(false)
                                }}
                                key={i}
                                className="dropdown-list__item dropdown-list__item--highlighted"
                              >
                                {donor.firstName} {donor.lastName}
                              </li>
                            ))
                        : "Aucun resultat trouvé"}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>

      <div className="step__footer step__footer--relative">
        <button
          type="button"
          className="btn btn--border  step__footer-btn"
          disabled={true}
        >
          Précédant
        </button>
        <button
          type="button"
          className="btn btn--blue  step__footer-btn"
          onClick={submitHandler}
        >
          Suivant
        </button>
      </div>
    </div>
  )
}
