import React, { useEffect, useState } from "react"
import "./App.css"
import "./style.css"
import Markup from "./jsx"
import { PATHS } from "./bv"
import UserService from "../src/services/user.service"

export const SessionContext = React.createContext(null)

const getLoggedUser = () => {
  const loggedInUser = localStorage.getItem("user")
  if (loggedInUser) return JSON.parse(loggedInUser).data
  return null
}

function App() {
  const [user, setUser] = useState(getLoggedUser())
  const [countries, setCountries] = useState([])
  const [HTTPError, setHTTPError] = useState(null)
  const [currentPath, setCurrentPath] = useState(PATHS.home)

  useEffect(() => {
    UserService.getCountries()
      .then(response => setCountries(response.data))
      .catch(e => console.log(e))

    // UserService.getMe()
    //   .then(response => setUser(response.data.data))
    //   .catch(e => console.log(e))
  }, [])

  return (
    <SessionContext.Provider
      value={{
        user,
        setUser,
        HTTPError,
        setHTTPError,
        countries,
        currentPath,
        setCurrentPath
      }}
    >
      <Markup />
    </SessionContext.Provider>
  )
}

export default App
