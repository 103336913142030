import React from "react"

export default function NoteInput({ value, setValue, label }) {
  return (
    <div>
      <div className="form-group">
        <label className="form-group__label">{label}</label>
        <textarea
          value={value}
          onChange={(event) => setValue(event.target.value)}
          className="textarea form-control form-control--textarea"
          rows="3"
          placeholder=""
          spellCheck="false"
        />
      </div>
    </div>
  )
}
