import React, { useState } from "react"

export default function PasswordInput({ value, setValue, submitHandler }) {
  const [hide, setHide] = useState(true)

  return (
    <div className="input-wrapper">
      <input
        type={hide ? "password" : "text"}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) =>
          e.type === "keydown" &&
          !e.altKey &&
          e.code === "Enter" &&
          e.key === "Enter" &&
          submitHandler && submitHandler()
        }
        className="input input-- input input-- form-control"
        placeholder="Entrez votre mot de passe"
      />
      <div className="password-icons" onClick={() => setHide(!hide)}>
        <div
          className={`password-icons__icon ${
            hide ? "password-icons__icon--reveal" : "password-icons__icon--hide"
          }`}
        />
      </div>
    </div>
  )
}
