import React, { useEffect, useState } from "react"
import ExcelJS from 'exceljs'

import { AgGridColumn, AgGridReact } from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-balham.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"

import ReportingService from "../../../services/reporting.service"
import { BarCharts, BarChart2 } from "../charts/Charts"
import Loader from '../cards/Loader'
import DayInput from '../cards/DayInput'
import { formatNumber } from '../../../bv'
import styled from 'styled-components'

function downloadBlob(blob, name = 'file.txt') {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);
  
  // Create a link element
  const link = document.createElement("a");
  
  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;
  
  // Append link to the body
  document.body.appendChild(link);
  
  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  );
  
  // Remove link from body
  document.body.removeChild(link);
}


export const TimeFrame = styled.div`
  display: flex;
  
  & .from, & .to {
    display: flex;
    align-items: center;
  }
  & .from {
    margin-right: 3rem;
  }
  & .DayPickerInput {
    position: relative;
  }
  & .DayPicker.DayPickerInput-OverlayWrapper {
    position: absolute;
    z-index: 1;
    background: white;
    box-shadow: 13px 17px 36px 0 rgba(133, 135, 222, .16), -6px -6px 22px 0 #fafbff;
  }
`

export default function Reporting() {
  const reporting = JSON.parse(localStorage.getItem("reporting"))
  const [barData, setBarData] = useState(reporting ? reporting.bar : [])
  const [rowData, setRowData] = useState(reporting ? reporting.promises : [])
  
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [gridApi, setGridApi] = useState(null)
  // const [/* gridColumnApi, */ setGridColumnApi] = useState(null)
  
  const [startDate, setStartDate] = useState(new Date(2020, 0, 1))
  const [endDate, setEndDate] = useState(new Date())

  const onGridReady = params => {
    setGridApi(params.api)
    // setGridColumnApi(params.columnApi)
  }

  const onBtnExport = async () => {
    const data = gridApi.getDataAsCsv()
    const workbook = new ExcelJS.Workbook()
    workbook.creator = 'Me'
    workbook.lastModifiedBy = 'Her'
    workbook.created = new Date(1985, 8, 30)
    workbook.modified = new Date()
    workbook.lastPrinted = new Date(2016, 9, 27)
    workbook.properties.date1904 = true
    workbook.calcProperties.fullCalcOnLoad = true
    workbook.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 1,
        visibility: 'visible'
      }
    ]
    const sheet = workbook.addWorksheet('Promesse')
    sheet.columns = [
      { header: 'No', key: 'id', width: 10 },
      { header: 'Nom & Prénoms', key: 'fullname', width: 32 },
      { header: 'Téléphone', key: 'phone', width: 32 },
      { header: 'Promesse', key: 'promise', width: 10, outlineLevel: 1 },
      { header: 'Réalisation', key: 'donation', width: 10, outlineLevel: 1 },
      // { header: 'Pourcentage', key: 'percentage', width: 10, outlineLevel: 1 }
    ]
    data.split("\n").forEach((row, index) => {
      const data = row.split(',').map(item => item.trim())
      sheet.addRow({
        id: index + 1,
        fullname: data[1].slice(1, data[1].length-1),
        phone: data[2].slice(1, data[2].length-1),
        promise: data[4].slice(1, data[4].length-1),
        donation: data[5].slice(1, data[5].length-1),
      })
    })
    const buffer = await workbook.xlsx.writeBuffer()
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const blob= new Blob([buffer], {type: fileType})
    downloadBlob(blob, 'test.xlsx')
  }

  /*  const columnDefs = [
    { headerName: "Nom et prenoms", field: "name" },
    { headerName: "Telephone", field: "telephone" },
    { headerName: "Promesse", field: "promesse" },
    // { headerName: "Number", field: "numero" },
  ] */

  useEffect(() => {
    const donations = JSON.parse(localStorage.getItem("donations"))
    ReportingService.getAll()
      .then(response => {
        if (response.status === 200) {
          console.log(response.data.promises)
          const tmp = response.data.promises.map((report, index) => ({
            numero: index + 1,
            name: report.donor ? report.donor.firstName + " " + report.donor.lastName : "--",
            telephone: report.donor ? report.donor.telephone.callingCode + report.donor.telephone.value : "__",
            createdAt: report.createdAt,
            date: (new Date(report.createdAt)).toLocaleDateString('fr-FR', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }),
            promesse: formatNumber(report.amount),
            realisation: formatNumber(donations && report.donor
              ? donations
                .filter(item => item.donor?._id === report.donor._id)
                .reduce((prev, current) => prev + current.amount, 0)
              : 0),
            pourcentage: (report.amount ? Math.round((donations && report.donor
              ? donations
                .filter(item => item.donor?._id === report.donor._id)
                .reduce((prev, current) => prev + current.amount, 0)
              : 0) * 100 / report.amount) : 100) + '%'
          }))
          setBarData(response.data.bar)
          setRowData(tmp)
          localStorage.setItem("reporting", JSON.stringify({...response.data, promises: tmp}))
        }
      })
      .catch(e => {
        setIsSubmitting(false)
        console.log(e)
      })
  }, [])
  
  const dataToDisplay = rowData.filter(item => startDate <= new Date(item.createdAt) && new Date(item.createdAt) <= endDate)

  return (
    <div className="page">
      { isSubmitting && <Loader full={true} /> }
      
      <div className="page-content">
        <div className="patient-list-container">
          <div className="page-heading">
            <h2 className="page-heading__title">Reporting</h2>
          </div>

          <div className="panel">
            <div className="patient-filters">
              <div className="patient-filters__row">
                {/*      <div
                  // onClick={ }
                  className="patient-filters__mobile patient-filters__toggle"
                >
                  <button type="button" className="btn  btn--with-icon-only">
                    <span className="btn__icon btn__icon--filters btn__icon--only" />
                    Afficher le graphique
                  </button>
                </div> */}

                <button type="button" className="btn  btn--lightblue" onClick={() => onBtnExport()}>
                  Exporter en EXCEL
                </button>
  
                <button type="button" className="btn  btn--blue" onClick={() => {
                  setStartDate(new Date(2020, 0, 1))
                  setEndDate(new Date())
                }}>
                  Effacer le filtre
                </button>
                
                <TimeFrame>
                  <div className="from">
                    <span>De: &nbsp;</span>
                    <DayInput selectedDay={startDate} setSelectedDay={ (date) => setStartDate(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)) } />
                  </div>
                  
                  <div className="to">
                    <span style={{textTransform: 'capitalize'}}>à: &nbsp;</span>
                    <DayInput selectedDay={endDate} setSelectedDay={ (date) => setEndDate(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)) } />
                  </div>
                </TimeFrame>
              </div>
            </div>
          </div>

          <div className="">
            <div style={{ flex: "1 1 0", position: "relative" }}>
              <div id="gridContainer">
                <div
                  className="ag-theme-alpine"
                  style={{
                    height: "500px",
                    width: "auto"
                  }}
                >
                  <AgGridReact
                    defaultColDef={{
                      editable: true,
                      resizable: true,
                      minWidth: 100,
                      flex: 1
                    }}
                    // columnDefs={columnDefs}
                    rowData={dataToDisplay}
                    onGridReady={onGridReady}
                  >
                    <AgGridColumn field="numero" sortable={true} />
                    <AgGridColumn
                      field="name"
                      filter="agTextColumnFilter"
                      sortable={true}
                    />
                    <AgGridColumn field="telephone" />
                    <AgGridColumn
                      field="date"
                      filter="agDateColumnFilter"
                      sortable={true}
                    />
                    <AgGridColumn
                      field="promesse"
                      filter="agNumberColumnFilter"
                      sortable={true}
                    />
                    <AgGridColumn
                      field="realisation"
                      filter="agNumberColumnFilter"
                      sortable={true}
                    />
                    
                    <AgGridColumn
                      field="pourcentage"
                      filter="agNumberColumnFilter"
                      sortable={true}
                    />
                  </AgGridReact>
                </div>
              </div>
            </div>

            <div style={{ display: "grid", margin: "9rem 0 0 0" }}>
              <BarCharts data_={barData} />
              <BarChart2 data_={barData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
