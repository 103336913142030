import React, { useState } from "react"

export default function SelectInput({ value, setValue, options }) {
  const [showOptions, setShowOptions] = useState(false)

  const toggleOptions = () => {
    setShowOptions(!showOptions)
  }

  return (
    <>
      <div className="select-container select-container--simple" tabIndex="-1">
        <input
          onKeyDown={(e) =>
            e.type === "keydown" &&
            !e.altKey &&
            e.code === "Escape" &&
            e.key === "Escape" &&
            setShowOptions(false)
          }
          type="text"
          readOnly={true}
          onClick={toggleOptions}
          onChange={undefined}
          className="input input--dropdown user-select-none"
          value={value ? value.text : ""}
        />
        {showOptions ? (
          <div className="dropdown dropdown--simple">
            <ul className="dropdown-list select-list">
              {options && options.length
                ? options.map((opt, i) => (
                    <li
                      onClick={() => {
                        setValue && setValue(opt)
                        toggleOptions()
                      }}
                      key={i}
                      className="dropdown-list__item "
                    >
                      {opt.text}
                    </li>
                  ))
                : ""}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  )
}
