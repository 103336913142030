import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { PATHS } from "../../../bv"
import { SessionContext } from "../../../App"

export const logoutHandler = () => {
  localStorage.removeItem("token")
  localStorage.removeItem("user")
  location.replace(PATHS.signin)
}

const Header = () => {
  const [showAccountTooltip, setShowAccountTooltip] = useState(false)
  const { user } = useContext(SessionContext)
  const path = location.pathname
  
  const dashboard = [PATHS.dashboard, PATHS.home]
  const receptors = [PATHS.receptorList]
  const donors = [PATHS.donorList]
  const donations = [PATHS.donationList]
  const profil = [PATHS.profil]
  const reporting = [PATHS.reporting]
  
  return (
    <div className="navbar">
      <a aria-current="page" className="logo_ logo--menu active" href="/" />
      
      <ul className="menu">
        {
          <li className="menu-item">
            <Link
              className={`menu-item__link menu-item__link--dashboard ${
                dashboard.includes(path) ? "active" : ""
              }`}
              to={PATHS.home}
              aria-current="page"
            >
              <div className="menu-item__tooltip">Tableau de bord</div>
            </Link>
          </li>
        }
        
        {user && user.role === "admin" && (
          <li className="menu-item">
            <Link
              className={`menu-item__link menu-item__link--receptor ${
                receptors.includes(path) ? "active" : ""
              }`}
              to={PATHS.receptorList}
            >
              <div className="menu-item__tooltip">Récepteurs</div>
            </Link>
          </li>
        )}
        
        {user && user.role !== "donor" && (
          <li className="menu-item">
            <Link
              className={`menu-item__link menu-item__link--person ${
                donors.includes(path) ? "active" : ""
              }`}
              to={PATHS.donorList}
            >
              <div className="menu-item__tooltip">Donateurs</div>
            </Link>
          </li>
        )}
        
        {user && user.role === "admin" && (
          <li className="menu-item">
            <Link
              className={`menu-item__link menu-item__link--reporting ${
                reporting.includes(path) ? "active" : ""
              }`}
              to={PATHS.reporting}
            >
              <div className="menu-item__tooltip">Reporting</div>
            </Link>
          </li>
        )}
        
        <li className="menu-item">
          <Link
            className={`menu-item__link menu-item__link--visit ${
              donations.includes(path) ? "active" : ""
            }`}
            to={PATHS.donationList}
          >
            <div className="menu-item__tooltip">Donations</div>
          </Link>
        </li>
        
        <li className="menu-item">
          <Link
            className={`menu-item__link menu-item__link--settings  ${
              profil.includes(path) ? "active" : ""
            }`}
            to={PATHS.profil}
          >
            <div className="menu-item__tooltip">Mon Profil</div>
          </Link>
        </li>
      </ul>
      
      <div className="menu">
        <div className="menu-item__item-wrapper">
          <div
            onClick={() => setShowAccountTooltip(!showAccountTooltip)}
            className="menu-item menu-item__account"
            id="tooltip-trigger"
          />
          
          <div
            className="menu-item__account-tooltip account-tooltip"
            style={{ display: showAccountTooltip ? "block" : "none" }}
          >
            <div className="account-tooltip__header">
              <p className="account-tooltip__title">
                {user && user.lastName} {user && user.firstName}
              </p>
            </div>
            
            <ul className="account-tooltip__item-list">
              <li className="account-tooltip__item ">
                <span className="account-tooltip__item-content">
                  <div
                    className="menu-item menu-item__account"
                    id="tooltip-trigger"
                  />
                  <span className="account-tooltip__item-description">
                    <p className="account-tooltip__item-text text-uppercase text-bold">
                      {user && user.role}
                    </p>
                  </span>
                </span>
              </li>
            </ul>
            
            <div className="account-tooltip__footer">
              <span className="account-tooltip__logout">
                <Link className="account-tooltip__logout-link" to="#">
                  <button
                    type="button"
                    className="btn btn--with-icon-only"
                    onClick={logoutHandler}
                  >
                    <span className="btn__icon btn__icon--logout-blue btn__icon--only" />
                    <span className="tooltip btn__tooltip">Se déconnecter</span>
                  </button>
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar__toggle" />
    </div>
  )
}

export default Header
