import React from "react"

const SideBar = () => {
  const platforms = [
    {
      logo: "https://img.icons8.com/ios/50/000000/android-os.png",
      text: "Google play store",
      link: "https://onedrive.live.com/download?cid=5F2B502B38174BDB&resid=5F2B502B38174BDB%21107&authkey=AMfttS5leEZ32Ow",
    },
    {
      logo: "https://img.icons8.com/ios-filled/50/000000/mac-os.png",
      text: "Apple app store",
      link: null,
    }
  ]
  
  const platformClickHandler = (platform) => {
    console.log("going to the store....")
    if (platform.link)
      window.open(platform.link, "_blank").focus()
  }
  
  return <div className="d-flex" style={{
    justifyContent: 'end',
    position: 'absolute',
    left: '90%'
  }}>
    {
      platforms.map((item, index) => (
        <img
          className="ping" alt="Google play store" key={index}
          src={item.logo}
          style={{
            borderRadius: '50%',
            cursor: 'pointer'
          }}
          onClick={ () => platformClickHandler(item) }
        />
      ))
    }
  </div>
}

export default SideBar
