export const remove = (arr, item) => {
  const newArr = [...arr]
  const index = newArr.findIndex((i) => i === item)
  newArr.splice(index, 1)
  return index >= 0 ? newArr : arr
}

let newIndex = 0
export const add = (arr, text, style) => {
  newIndex = newIndex + 1
  return [...arr, { id: newIndex, text: text, style: style }]
}
