import React, { useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import Welcome from "../cards/Welcome"
import PaymentService from "../../../services/payment.service"
import Loader from "../cards/Loader"
import PhoneInput from "../cards/PhoneInput"
import { SessionContext } from "../../../App"

const PAYMENT_MODE = [
  {
    id: 1,
    value: "MOBILE_MONEY",
    name: "MOBILE MONEY"
  },
  {
    id: 2,
    value: "CREDIT_CARD",
    name: "CARTE DE CREDIT"
  }
]

export default function MakeDonation({ showNotification, user }) {
  const { countries } = useContext(SessionContext)

  const [amount, setAmount] = useState("")
  const [callingCode, setCallingCode] = useState(user ? user.telephone.callingCode : "+228")
  const [telephone, setTelephone] = useState(user ? user.telephone.value : null)
  const [fullname, setFullname] = useState(user ? user.firstName + user.lastName : null)
  const [email, setEmail] = useState(user && user.email ? user.email :null)
  const [address, setAddress] = useState("Agoe")
  const [city, setCity] = useState("Lome")
  const [country, setCountry] = useState(countries ? countries[226] : null)
  const [state, setState] = useState(null)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedPaymentMode, setSelectedPaymentMode] = useState(PAYMENT_MODE[0])

  const [showCountriesDropdown, setShowCountriesDropdown] = useState(false)

  const toggleCountriesDropdown = () => setShowCountriesDropdown(!showCountriesDropdown)

  const history = useHistory()

  const initPayment = () => {
    // console.log(Number(amount))
    if (
      amount && Number(amount) >= 100 &&
      (
        (selectedPaymentMode.id === PAYMENT_MODE[1].id && callingCode && telephone && email && address && city && country) ||
        (selectedPaymentMode.id === PAYMENT_MODE[0].id && callingCode && telephone && fullname)
      )
    ) {
      const data = {
        amount: Number(amount),
        channels: selectedPaymentMode.value,
        fullname,
        phone_number: callingCode + telephone,
        email,
        address,
        city,
        country: country.alpha2Code,
        state
      }
      setIsSubmitting(true)
      PaymentService.init(data)
        .then(response => {
          setIsSubmitting(false)
          if (response.status === 200 || response.data.status === 201) {
            // window.open(response.data.data.payment_url, "_blank").focus()
            // window.open(response.data.data.payment_url).focus()
            window.location.href = response.data.data.payment_url
          }
        })
        .catch(e => {
          console.log(e)
          setIsSubmitting(false)
          if (e.response) showNotification("error", e.response.data.description)
        })
    } else {
      showNotification("warning", "Veuillez renseigner les champs obligatoires s'il vous plait (Montant minimum = 100 XOF)")
    }
  }

  const PersonInfoForm = () => (
    <>
      <div className="form-group double-step__section">
        <label className="form-group__label">Nom complet</label>
        <div className="form-group form-group--1">
          <input
            type="text"
            value={fullname}
            onChange={event => setFullname(event.target.value)}
            className="input input-- form-control"
          />
        </div>
      </div>

      <div className="form-group form-group--2 phone">
        <PhoneInput
          label={"Téléphone"}
          callingCode={callingCode}
          setCallingCode={setCallingCode}
          setCountry={setCountry}
          value={telephone}
          setValue={setTelephone}
        />
      </div>
    </>
  )

  return (
    <div className="fullpage fullpage--clickable multi-add-visit-modal">
      {isSubmitting && <Loader full />}
      {!user && <Welcome />}

      <div className="steps-modal">
        <div className="steps-modal__header">
          <div className="steps-modal__logo" />
          <div className="stepper">
            <div
              className={`stepper__step stepper__line stepper__step--finished`}
            >
              <div
                className={`stepper__indicator stepper__indicator--current`}
              />
              <p className={`stepper__name`}>Donation</p>
            </div>
          </div>

          <div className="steps-modal__close">
            <p className="steps-modal__mobile-description">
              Ajouter une donation
            </p>
            <button
              onClick={() => history.goBack()}
              type="button"
              className="btn btn--with-icon-only"
            >
              <span className="btn__icon btn__icon--close-grey btn__icon--only" />
            </button>
          </div>
        </div>

        <div className="steps-modal__step">
          <div className="step">
            <div
              style={{ marginBottom: "unset" }}
              className="step__content step__content--maxwidth"
            >
              <div className="double-step">
                <div className="double-step__part">
                  <div className="step">
                    <div className="step__content">
                      <div className="form-group form-group--1 form-group--equal">
                        {PAYMENT_MODE.map((mode, i) => (
                          <button
                            key={i}
                            type="button"
                            onClick={() => setSelectedPaymentMode(mode)}
                            className={`btn btn--double btn--border ${
                              selectedPaymentMode.id === mode.id
                                ? "btn--lightblue"
                                : ""
                            }`}
                          >
                            { mode.name }
                          </button>
                        ))}
                      </div>

                      <div className="form-group double-step__section">
                        <label className="form-group__label">Montant (XOF)</label>
                        <div className="form-group form-group--1">
                          <input
                            style={{ fontSize: "2.5rem", textAlign: "center" }}
                            type="number"
                            value={amount}
                            onChange={event => setAmount(event.target.value)}
                            className="input input-- form-control"
                          />
                        </div>
                      </div>

                      {!user && <PersonInfoForm />}

                      {
                        selectedPaymentMode.id === 2 ? (
                          <>
                            <div className="form-group double-step__section">
                              <label className="form-group__label">Email</label>
                              <div className="form-group form-group--1">
                                <input
                                  type="text"
                                  value={email}
                                  onChange={event => setEmail(event.target.value)}
                                  className="input form-control"
                                />
                              </div>
                            </div>
                            
                            <div className="form-group double-step__section">
                              <div className="form-group form-group--2">
                                <input
                                  type="text"
                                  placeholder="Adresse"
                                  value={address}
                                  onChange={event =>
                                    setAddress(event.target.value)
                                  }
                                  className="input input-- form-control"
                                />
                              </div>
        
                              <div className="form-group form-group--2">
                                <input
                                  type="text"
                                  placeholder="Ville"
                                  value={city}
                                  onChange={event => setCity(event.target.value)}
                                  className="input input-- form-control"
                                />
                              </div>
                            </div>
                            
                            <div className="form-group double-step__section">
                              <div className="form-group form-group--2">
                                <div
                                  className="select-container select-container--simple"
                                  tabIndex="-1"
                                >
                                  <input
                                    type="text"
                                    readOnly={true}
                                    onClick={toggleCountriesDropdown}
                                    autoComplete="off"
                                    className="input input--dropdown user-select-none"
                                    value={
                                      country
                                        ? `${country.name} +${country.callingCodes[0]}`
                                        : "Choisissez un pays"
                                    }
                                  />
                                  <div
                                    style={{
                                      display: showCountriesDropdown
                                        ? "block"
                                        : "none"
                                    }}
                                    className="dropdown dropdown--simple"
                                  >
                                    <ul className="dropdown-list select-list ">
                                      {countries.map((country, i) => (
                                        <li
                                          onClick={() => {
                                            setCountry(country)
                                            toggleCountriesDropdown()
                                          }}
                                          key={i}
                                          style={{ width: "100%" }}
                                          className="dropdown-list__item "
                                        >
                                          {country.name} +
                                          {country.callingCodes[0]}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
        
                              <div className="form-group form-group--2">
                                <input
                                  type="text"
                                  value={state}
                                  placeholder="Region"
                                  onChange={event => setState(event.target.value)}
                                  className="input input-- form-control"
                                />
                              </div>
                            </div>
                          </>
                        ) : ""
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="step__footer step__footer--relative">
              <button
                type="button"
                onClick={initPayment}
                className="btn btn--blue step__footer-btn"
              >
                Procéder au don
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
