import React, { useContext, useState } from "react"
import { SessionContext } from "../../../App"

export default function PhoneInput({
  label,
  callingCode,
  setCallingCode,
  setCountry,
  value,
  setValue,
}) {
  const [showCountriesDropdown, setShowCountriesDropdown] = useState(false)
  const { countries } = useContext(SessionContext)

  const toggleCountriesDropdown = () => {
    setShowCountriesDropdown(!showCountriesDropdown)
  }

  return (
    <>
      <div className="select-form-label">
        <label className="form-group__label">{label}</label>
      </div>

      <div className="form-group form-group--2 phone__dialing-code">
        <div className="select-container select-container--simple" tabIndex="-1">
          <input
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            type="text"
            readOnly={true}
            onClick={toggleCountriesDropdown}
            autoComplete="off__1629591878510"
            className="input input--dropdown user-select-none"
            value={callingCode}
          />
          <div
            style={{ display: showCountriesDropdown ? "block" : "none" }}
            className="dropdown dropdown--simple"
          >
            <ul className="dropdown-list select-list ">
              {countries.map((country, i) => (
                <li
                  onClick={() => {
                    setCallingCode("+" + country.callingCodes[0])
                    if (setCountry) setCountry(country)
                    toggleCountriesDropdown()
                  }}
                  key={i}
                  style={{ width: "100px" }}
                  className="dropdown-list__item "
                >
                  {country.alpha2Code} +{country.callingCodes[0]}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="form-group form-group--2 phone__main-number">
        <input
          type="number"
          className="input input-- form-control "
          placeholder="Entrez le numéro de téléphone"
          autoComplete="off"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </>
  )
}
